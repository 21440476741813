import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#2f4d84",
    },
    secondary: {
      main: "#ff6600",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "poppins",
    fontSize: 12,
  },
});

export default theme;
