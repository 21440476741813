import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  activeOrdersAction,
  allVehiclesDataAction,
  completedOrdersAction,
  counterAcceptedByVendorRequestsAction,
  counterByUserRequestsAction,
  counterByVendorRequestsAction,
  getContactPersonsAction,
  loadingOptionsDataAction,
  logoutAction,
  myPendingRequestsAction,
  myRejectedRequestsAction,
  myWalletAction,
  offersReceivedAction,
  proApplicationDataAction,
  unloadingOptionsDataAction,
  waitingForAdminApprovalRequestsAction,
} from "../sliceReducers/userSlice";
import {
  useGetActiveRequestsQuery,
  useGetAllVehiclesQuery,
  useGetCompletedOrderQuery,
  useGetContactPersonsQuery,
  useGetCounterAcceptedByVendorRequestsQuery,
  useGetCounterByUserRequestsQuery,
  useGetCounterByVendorRequestsQuery,
  useGetLoadingOptionsQuery,
  useGetNotificationsQuery,
  useGetOfferReceivedRequestsQuery,
  useGetPendingRequestsQuery,
  useGetRejectedRequestsQuery,
  useGetUnLoadingOptionsQuery,
  useGetUpgradeToProApplicationQuery,
  useGetWaitingForAdminApprovalRequestsQuery,
  useGetWalletQuery,
} from "../services/userService";
import APIhandler from "./APIhandler";
import { notifyError } from "./notifyMessage";
import {
  notificationsDataAction,
  prefetchingAction,
} from "../sliceReducers/utilsSlice";
import { useState } from "react";

function PreFetch() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userData);
  const [skipPrefetch, setSkipPrefetch] = useState(true);
  console.error(token);

  useEffect(() => {
    dispatch(prefetchingAction(true));
  }, []);

  useEffect(() => {
    if (token) {
      setSkipPrefetch(false);
    }
  }, [token]);

  const {
    data: allVehiclesResponse,
    error: allVehiclesError,
    isFetching: allVehiclesFetching,
  } = useGetAllVehiclesQuery("", { skip: skipPrefetch });

  const {
    data: loadingOptionsResponse,
    error: loadingOptionsError,
    isFetching: loadingOptionsFetching,
  } = useGetLoadingOptionsQuery("", { skip: skipPrefetch });

  const {
    data: unloadingOptionsResponse,
    error: unloadingOptionsError,
    isFetching: unloadingOptionsFetching,
  } = useGetUnLoadingOptionsQuery("", { skip: skipPrefetch });

  const {
    data: proApplicationResponse,
    error: proApplicationError,
    isFetching: proApplicationFetching,
  } = useGetUpgradeToProApplicationQuery(token, { skip: skipPrefetch });

  const {
    data: walletResponse,
    error: walletError,
    isFetching: walletFetching,
  } = useGetWalletQuery(token, { skip: skipPrefetch });

  const {
    data: notificationsResponse,
    error: notificationsError,
    isFetching: notificationsFetching,
  } = useGetNotificationsQuery(token, { skip: skipPrefetch });

  const {
    data: myPendingRequestsResponse,
    error: myPendingRequestsError,
    isFetching: myPendingRequestsFetching,
  } = useGetPendingRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: offersReceivedResponse,
    error: offersReceivedError,
    isFetching: offersReceivedFetching,
  } = useGetOfferReceivedRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: counterByUserRequestsResponse,
    error: counterByUserRequestsError,
    isFetching: counterByUserRequestsFetching,
  } = useGetCounterByUserRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: counterByVendorRequestsResponse,
    error: counterByVendorRequestsError,
    isFetching: counterByVendorRequestsFetching,
  } = useGetCounterByVendorRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: counterAcceptedByVendorRequestsResponse,
    error: counterAcceptedByVendorRequestsError,
    isFetching: counterAcceptedByVendorRequestsFetching,
  } = useGetCounterAcceptedByVendorRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: myRejectedRequestsResponse,
    error: myRejectedRequestsError,
    isFetching: myRejectedRequestsFetching,
  } = useGetRejectedRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: waitingForAdminApprovalRequestsResponse,
    error: waitingForAdminApprovalRequestsError,
    isFetching: waitingForAdminApprovalRequestsFetching,
  } = useGetWaitingForAdminApprovalRequestsQuery(token, { skip: skipPrefetch });

  const {
    data: completedOrdersResponse,
    error: completedOrdersError,
    isFetching: completedOrdersFetching,
  } = useGetCompletedOrderQuery(token, { skip: skipPrefetch });

  const {
    data: activeRequestsResponse,
    error: activeRequestsError,
    isFetching: activeRequestsFetching,
  } = useGetActiveRequestsQuery(token, { skip: skipPrefetch }); // Change

  const {
    data: getContactPersonsResponse,
    isFetching: getContactPersonsFetching,
    error: getContactPersonsError,
  } = useGetContactPersonsQuery(token, { skip: skipPrefetch });

  return (
    <>
      <APIhandler
        responseData={allVehiclesResponse}
        serverError={allVehiclesError}
        isFetching={allVehiclesFetching}
        onStatusTrue={() => {
          dispatch(allVehiclesDataAction(allVehiclesResponse.data));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch vehicles");
        }}
      />

      <APIhandler
        responseData={loadingOptionsResponse}
        serverError={loadingOptionsError}
        isFetching={loadingOptionsFetching}
        onStatusTrue={() => {
          dispatch(loadingOptionsDataAction(loadingOptionsResponse.data));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch loading options");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={unloadingOptionsResponse}
        serverError={unloadingOptionsError}
        isFetching={unloadingOptionsFetching}
        onStatusTrue={() => {
          dispatch(unloadingOptionsDataAction(unloadingOptionsResponse.data));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch loading options");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={proApplicationResponse}
        serverError={proApplicationError}
        isFetching={proApplicationFetching}
        onStatusTrue={() => {
          dispatch(proApplicationDataAction(proApplicationResponse));
          dispatch(logoutAction(true));
          console.error(proApplicationResponse);
        }}
        onStatusFalse={() => {
          dispatch(proApplicationDataAction({}));
          dispatch(logoutAction(true));
        }}
        onServerError={() => {
          dispatch(logoutAction(true));
        }}
      />

      <APIhandler
        responseData={walletResponse}
        serverError={walletError}
        isFetching={walletFetching}
        onStatusTrue={() => {
          dispatch(myWalletAction(walletResponse));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch wallt");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={notificationsResponse}
        serverError={notificationsError}
        isFetching={notificationsFetching}
        onStatusTrue={() => {
          dispatch(notificationsDataAction(notificationsResponse.data));
        }}
        onStatusFalse={() => {
          dispatch(notificationsDataAction(notificationsResponse.data));
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={myPendingRequestsResponse}
        serverError={myPendingRequestsError}
        isFetching={myPendingRequestsFetching}
        onStatusTrue={() => {
          dispatch(myPendingRequestsAction(myPendingRequestsResponse.data));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch my pending requests");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={offersReceivedResponse}
        serverError={offersReceivedError}
        isFetching={offersReceivedFetching}
        onStatusTrue={() => {
          dispatch(offersReceivedAction(offersReceivedResponse.pending));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch offer received");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={counterByUserRequestsResponse}
        serverError={counterByUserRequestsError}
        isFetching={counterByUserRequestsFetching}
        onStatusTrue={() => {
          dispatch(
            counterByUserRequestsAction(counterByUserRequestsResponse.data)
          );
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch counter by user");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={counterByVendorRequestsResponse}
        serverError={counterByVendorRequestsError}
        isFetching={counterByVendorRequestsFetching}
        onStatusTrue={() => {
          dispatch(
            counterByVendorRequestsAction(
              counterByVendorRequestsResponse.pending
            )
          );
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch counter by vendor");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={counterAcceptedByVendorRequestsResponse}
        serverError={counterAcceptedByVendorRequestsError}
        isFetching={counterAcceptedByVendorRequestsFetching}
        onStatusTrue={() => {
          dispatch(
            counterAcceptedByVendorRequestsAction(
              counterAcceptedByVendorRequestsResponse.accepted
            )
          );
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch counter accepted by vendor");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={myRejectedRequestsResponse}
        serverError={myRejectedRequestsError}
        isFetching={myRejectedRequestsFetching}
        onStatusTrue={() => {
          dispatch(
            myRejectedRequestsAction(myRejectedRequestsResponse.transit.data)
          );
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch rejected requests");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={waitingForAdminApprovalRequestsResponse}
        serverError={waitingForAdminApprovalRequestsError}
        isFetching={waitingForAdminApprovalRequestsFetching}
        onStatusTrue={() => {
          dispatch(
            waitingForAdminApprovalRequestsAction(
              waitingForAdminApprovalRequestsResponse.data
            )
          );
          dispatch(prefetchingAction(false));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch waiting for admin approval requests");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={completedOrdersResponse}
        serverError={completedOrdersError}
        isFetching={completedOrdersFetching}
        onStatusTrue={() => {
          dispatch(completedOrdersAction(completedOrdersResponse.transit.data)); // Change
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch completed orders");
        }}
        onServerError={() => {}}
      />
      <APIhandler
        responseData={activeRequestsResponse}
        serverError={activeRequestsError}
        isFetching={activeRequestsFetching}
        onStatusTrue={() => {
          dispatch(activeOrdersAction(activeRequestsResponse.transit.data)); // Change
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch completed orders");
        }}
        onServerError={() => {}}
      />

      <APIhandler
        responseData={getContactPersonsResponse}
        isFetching={getContactPersonsFetching}
        serverError={getContactPersonsError}
        onStatusTrue={() => {
          dispatch(getContactPersonsAction(getContactPersonsResponse.data));
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch contact persons");
        }}
        onServerError={() => {}}
      />
    </>
  );
}

export default PreFetch;
