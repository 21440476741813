import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  userApi,
  useUserAcceptVenderCounterQouteQuery,
  useUserCancelOrderQuery,
  useUserCounterVenderQouteQuery,
  useUserRejectVenderCounterQouteQuery,
  useUserRejectVenderQouteQuery,
} from "../../services/userService";
import { notifyError, notifySuccess } from "../../utils/notifyMessage";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import useDidMountEffect from "../../utils/useDidMountEffect";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { offerAcceptedDocumentsAction } from "../../sliceReducers/userSlice";
import { useNavigate } from "react-router-dom";

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OfferReceivedDecisionDialogBox = ({
  offerReceivedDecisionDialog,
  setOfferReceivedDecisionDialog,
  offerReceivedDecision,
  statusText,
  myRequest,
  statusColor,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [counterValue, setCounterValue] = useState("");
  const [userCounterOffer, setUserCounterOffer] = useState({});
  const [userRejectQoute, setUserRejectQoute] = useState({});
  const [userAcceptQoute, setUserAcceptQoute] = useState({});
  const [userRejectVendorCounterQoute, setUserRejectVendorCounterQoute] =
    useState({});
  const [userAcceptVendorCounterQoute, setUserAcceptVendorCounterQoute] =
    useState({});
  const [userCancelOrder, setUserCancelOrder] = useState({});

  const [skipUserCounter, setSkipUserCounter] = useState(true);
  const [skipUserRejectQoute, setSkipUserRejectQoute] = useState(true);
  const [skipUserAcceptQoute, setSkipUserAcceptQoute] = useState(true);
  const [
    skipUserRejectVendorCounterQoute,
    setSkipUserRejectVendorCounterQoute,
  ] = useState(true);
  const [
    skipUserAcceptVendorCounterQoute,
    setSkipUserAcceptVendorCounterQoute,
  ] = useState(true);
  const [skipUserCancelOrder, setSkipUserCancelOrder] = useState(true);

  const { user, token, myWalletData, creditRemaining } = useSelector(
    (state) => state.userData
  );

  const handleOfferReceivedDecision = (myRequest) => {
    dispatch(isFetchingSomethingAction(true));
    if (offerReceivedDecision === 0) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      setUserRejectQoute({
        token: token,
        orderNo: myRequest.orderNo,
        vendor_phone: myRequest.phone,
      });
      setSkipUserRejectQoute(false);
    } else if (offerReceivedDecision === 1) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      setUserCounterOffer({
        token: token,
        orderNo: myRequest.orderNo,
        amount: counterValue,
        vendor_phone: myRequest.phone,
      });
      setSkipUserCounter(false);
    } else if (offerReceivedDecision === 2) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      setUserAcceptQoute({
        token: token,
        orderNo: myRequest.orderNo,
        vendor_phone: myRequest.phone,
      });
      setSkipUserAcceptQoute(false);
    } else if (offerReceivedDecision === 3) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      setUserRejectVendorCounterQoute({
        token: token,
        orderNo: myRequest.orderNo,
        vendor_phone: myRequest.vendor_phone,
      });
      setSkipUserRejectVendorCounterQoute(false);
    } else if (offerReceivedDecision === 4) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      setUserAcceptVendorCounterQoute({
        token: token,
        orderNo: myRequest.orderNo,
        vendor_phone: myRequest.vendor_phone,
      });
      setSkipUserRejectVendorCounterQoute(false);
    } else if (offerReceivedDecision === 5) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      setUserCancelOrder({
        token: token,
        orderNo: myRequest.orderNo,
      });
      setSkipUserCancelOrder(false);
    } else if (offerReceivedDecision === 6) {
      console.log("offerReceivedDecision -> ", offerReceivedDecision);
      // setUserAcceptVendorCounterQoute({
      //   token: token,
      //   orderNo: myRequest.orderNo,
      //   vendor_phone: myRequest.vendor_phone,
      // });
      // setSkipUserRejectVendorCounterQoute(false);
    }
  };

  /******************    USER COUNTER QOUTE API *******************/
  const {
    data: userCounterOfferQouteResponse,
    error: userCounterOfferQouteError,
    isFetching: userCounterOfferQouteFetching,
  } = useUserCounterVenderQouteQuery(userCounterOffer, {
    skip: skipUserCounter,
  });

  useDidMountEffect(() => {
    if (
      userCounterOfferQouteFetching === false &&
      userCounterOfferQouteResponse &&
      !userCounterOfferQouteError
    ) {
      notifySuccess("Qoute has been successfully countered");
      setSkipUserCounter(true);
      dispatch(userApi.endpoints.getOfferReceivedRequests.initiate(token));
      dispatch(userApi.endpoints.getCounterByUserRequests.initiate(token));
      // setSkipOfferReceivedRequests(false);
      // setSkipCounterByUserRequests(false);
      dispatch(isFetchingSomethingAction(false));
      setOfferReceivedDecisionDialog(false);
    }

    if (userCounterOfferQouteFetching === false && userCounterOfferQouteError) {
      setSkipUserCounter(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [userCounterOfferQouteFetching]);

  /******************    USER REJECT QOUTE API *******************/
  const {
    data: userRejectQouteResponse,
    error: userRejectQouteError,
    isFetching: userRejectQouteFetching,
  } = useUserRejectVenderQouteQuery(userRejectQoute, {
    skip: skipUserRejectQoute,
  });

  useDidMountEffect(() => {
    if (
      userRejectQouteFetching === false &&
      userRejectQouteResponse &&
      !userRejectQouteError
    ) {
      notifySuccess("Qoute has been successfully rejected");
      setSkipUserRejectQoute(true);
      // setSkipOfferReceivedRequests(false);
      dispatch(userApi.endpoints.getOfferReceivedRequests.initiate(token));
      dispatch(userApi.endpoints.getRejectedRequests.initiate(token));
      // setSkipRejectedRequests(false);
      dispatch(isFetchingSomethingAction(false));
      setOfferReceivedDecisionDialog(false);
    }

    if (userRejectQouteFetching === false && userRejectQouteError) {
      setSkipUserRejectQoute(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [userRejectQouteFetching]);

  /******************    USER ACCEPT QOUTE API *******************/
  const {
    data: userAcceptQouteResponse,
    error: userAcceptQouteError,
    isFetching: userAcceptQouteFetching,
  } = useUserRejectVenderQouteQuery(userAcceptQoute, {
    skip: skipUserAcceptQoute,
  });

  useDidMountEffect(() => {
    if (
      userAcceptQouteFetching === false &&
      userAcceptQouteResponse &&
      !userAcceptQouteError
    ) {
      notifySuccess("Qoute has been successfully rejected");
      setSkipUserRejectQoute(true);
      // setSkipOfferReceivedRequests(false);
      dispatch(userApi.endpoints.getOfferReceivedRequests.initiate(token));
      dispatch(userApi.endpoints.getRejectedRequests.initiate(token));
      // setSkipRejectedRequests(false);
      dispatch(isFetchingSomethingAction(false));
      setOfferReceivedDecisionDialog(false);
    }

    if (userAcceptQouteFetching === false && userAcceptQouteError) {
      setSkipUserRejectQoute(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [userAcceptQouteFetching]);

  /******************    USER REJECT VENDOR COUNTER QOUTE API *******************/
  const {
    data: userRejectVendorCounterQouteResponse,
    error: userRejectVendorCounterQouteError,
    isFetching: userRejectVendorCounterQouteFetching,
  } = useUserRejectVenderCounterQouteQuery(userRejectVendorCounterQoute, {
    skip: skipUserRejectVendorCounterQoute,
  });

  useDidMountEffect(() => {
    if (
      userRejectVendorCounterQouteFetching === false &&
      userRejectVendorCounterQouteResponse &&
      !userRejectVendorCounterQouteError
    ) {
      notifySuccess("Offer has been successfully rejected");
      setSkipUserRejectVendorCounterQoute(true);

      // setSkipCounterByVendorRequests(false);

      dispatch(isFetchingSomethingAction(false));
      setOfferReceivedDecisionDialog(false);
    }

    if (
      userRejectVendorCounterQouteFetching === false &&
      userRejectVendorCounterQouteError
    ) {
      setSkipUserRejectVendorCounterQoute(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [userRejectVendorCounterQouteFetching]);

  // /******************    USER ACCEPT VENDOR COUNTER QOUTE API *******************/
  const {
    data: userAcceptVendorCounterQouteResponse,
    error: userAcceptVendorCounterQouteError,
    isFetching: userAcceptVendorCounterQouteFetching,
  } = useUserAcceptVenderCounterQouteQuery(userAcceptVendorCounterQoute, {
    skip: skipUserAcceptVendorCounterQoute,
  });

  useDidMountEffect(() => {
    if (
      userAcceptVendorCounterQouteFetching === false &&
      userAcceptVendorCounterQouteResponse &&
      !userAcceptVendorCounterQouteError
    ) {
      notifySuccess("Qoute has been successfully accepted");
      setSkipUserAcceptVendorCounterQoute(true);
      // setSkipOfferReceivedRequests(false);
      // setSkipRejectedRequests(false);
      dispatch(isFetchingSomethingAction(false));
      setOfferReceivedDecisionDialog(false);
    }

    if (
      userAcceptVendorCounterQouteFetching === false &&
      userAcceptVendorCounterQouteError
    ) {
      setSkipUserAcceptVendorCounterQoute(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [userAcceptVendorCounterQouteFetching]);

  // /******************    USER CANCEL ORDER API *******************/
  const {
    data: userCancelOrderResponse,
    error: userCancelOrderError,
    isFetching: userCancelOrderFetching,
  } = useUserCancelOrderQuery(userCancelOrder, {
    skip: skipUserCancelOrder,
  });

  useDidMountEffect(() => {
    if (
      userCancelOrderFetching === false &&
      userCancelOrderResponse &&
      !userCancelOrderError
    ) {
      notifySuccess("Qoute has been successfully cancelled");
      setSkipUserCancelOrder(true);
      // setSkipCounterAcceptedByVendor(false);
      dispatch(isFetchingSomethingAction(false));
      setOfferReceivedDecisionDialog(false);
    }

    if (userCancelOrderFetching === false && userCancelOrderError) {
      setSkipUserCancelOrder(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [userCancelOrderFetching]);

  return (
    <Dialog
      maxWidth={"xl"}
      open={offerReceivedDecisionDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOfferReceivedDecisionDialog(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        alignSelf={"center"}
        style={{ font: "600 1.2ren Poppins !important" }}
      >
        {offerReceivedDecision === 0
          ? "Are you sure you want to Reject bilty?"
          : offerReceivedDecision === 1
          ? "Make Counter Offer"
          : offerReceivedDecision === 2
          ? (user.user_type === "user" ||
              creditRemaining >= myRequest.qoute_amount) &&
            "Offer Accepted"
          : offerReceivedDecision === 3
          ? "Are you sure you want to Cancel?"
          : offerReceivedDecision === 4
          ? (user.user_type === "user" ||
              creditRemaining >= myRequest.qoute_amount) &&
            "Offer Accepted !"
          : offerReceivedDecision === 5
          ? "Are you sure you want to Cancel?"
          : offerReceivedDecision === 6 && "Offer Accepted !"}
      </DialogTitle>

      {offerReceivedDecision === 1 && (
        <DialogContent
          className="myCounterDialogBox"
          style={{ paddingBottom: "0" }}
        >
          <TextField
            id="outlined-basic"
            label="Counter Amount"
            variant="outlined"
            value={counterValue}
            onChange={(e) => {
              setCounterValue(e.target.value);
            }}
            style={{ margin: "0.5rem" }}
          />
        </DialogContent>
      )}

      {(offerReceivedDecision === 3 ||
        offerReceivedDecision === 5 ||
        offerReceivedDecision === 0) && (
        <DialogContent
          className="myCounterDialogBox"
          style={{ paddingBottom: "0" }}
        >
          <CancelIcon style={{ fontSize: "5rem", color: "var(--BrightRed)" }} />
        </DialogContent>
      )}

      {(offerReceivedDecision === 6 ||
        offerReceivedDecision === 4 ||
        offerReceivedDecision === 2) && (
        <>
          <>
            <DialogContent
              className="myCounterDialogBox"
              style={{ paddingBottom: "0" }}
            >
              <CheckCircleIcon
                style={{ fontSize: "5rem", color: "var(--Success)" }}
              />
            </DialogContent>
            <DialogContent
              className="myCounterDialogBox"
              style={{ paddingBottom: "0" }}
            >
              <Typography style={{ color: "var(--Grey)" }}>
                You have accepted the offer
              </Typography>
            </DialogContent>
          </>

          {creditRemaining < myRequest.qoute_amount &&
            user.user_type === "pro" && (
              <>
                <DialogContent
                  className="myCounterDialogBox"
                  style={{ paddingBottom: "0" }}
                >
                  <CancelIcon
                    style={{ fontSize: "5rem", color: "var(--BrightRed2)" }}
                  />
                </DialogContent>
                <DialogContent
                  className="myCounterDialogBox"
                  style={{ paddingBottom: "0" }}
                >
                  <Typography style={{ color: "var(--Grey)" }}>
                    Your credit limit is lower then offer
                  </Typography>
                </DialogContent>
              </>
            )}
        </>
      )}

      <DialogActions className="myCounterDialogBox">
        {offerReceivedDecision !== 6 &&
          offerReceivedDecision !== 4 &&
          offerReceivedDecision !== 2 && (
            <>
              <Button
                className="myRejectAcceptDialogBoxYes"
                onClick={() => {
                  handleOfferReceivedDecision(myRequest);
                }}
              >
                {offerReceivedDecision === 1 ? "Submit" : "Yes"}
              </Button>

              <Button
                className="myRejectAcceptDialogBoxNo"
                onClick={() => setOfferReceivedDecisionDialog(false)}
              >
                {offerReceivedDecision === 1 ? "Back" : "No"}
              </Button>
            </>
          )}

        {(offerReceivedDecision === 6 ||
          offerReceivedDecision === 4 ||
          offerReceivedDecision === 2) && (
          <>
            <Button
              className="myOfferAcceptedDialogBoxButton"
              onClick={() => {
                dispatch(
                  offerAcceptedDocumentsAction({
                    ...myRequest,
                    statusText,
                    statusColor,
                  })
                );
                navigate("/offerAccepted");
              }}
            >
              Continue
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default OfferReceivedDecisionDialogBox;
