import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useDispatch, useSelector } from "react-redux";

import "./OfferAccepted.css";
import { notifyInfo } from "../../utils/notifyMessage";
import MyRequestCard from "./MyRequestCard";
import { useNavigate } from "react-router-dom";
import useDidMountEffect from "../../utils/useDidMountEffect";
import LogoBird from "../../images/logoBird.png";
import FileImage from "../../images/fileImage.jpg";
import { useUploadTransitCargoDocumentsQuery } from "../../services/userService";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import Loader from "../../utils/Loader";

const OfferAccepted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [billOfLanding, setBillOfLanding] = useState();
  const [billOfLandingPreview, setBillOfLandingPreview] = useState("");

  const [invoice, setInvoice] = useState();
  const [invoicePreview, setInvoicePreview] = useState("");

  const [gd, setGd] = useState();
  const [gdPreview, setGdPreview] = useState("");

  const [demandLetter, setDemandLetter] = useState();
  const [demandLetterPreview, setDemandLetterPreview] = useState("");

  const [packingList, setPackingList] = useState();
  const [packingListPreview, setPackingListPreview] = useState("");

  const { offerAcceptedDocuments, token } = useSelector(
    (state) => state.userData
  );

  const { isFetchingSomething } = useSelector((state) => state.utils);

  const [formData, setFormData] = useState();

  const [skipTransitCargoDocument, setSkipTransitCargoDocument] =
    useState(true);

  const documentUploadHandleChange = (e, setDocument, setDocumentPreview) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setDocumentPreview(reader.result);
        setDocument(e.target.files[0]);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const documentsArray = [
    {
      name: "Bill of landing",
      preview: billOfLandingPreview,
      set: setBillOfLanding,
      setPreview: setBillOfLandingPreview,
    },
    {
      name: "Invoice",
      preview: invoicePreview,
      set: setInvoice,
      setPreview: setInvoicePreview,
    },
    {
      name: "GD",
      preview: gdPreview,
      set: setGd,
      setPreview: setGdPreview,
    },
    {
      name: "Demand letter",
      preview: demandLetterPreview,
      set: setDemandLetter,
      setPreview: setDemandLetterPreview,
    },
    {
      name: "Packing list",
      preview: packingListPreview,
      set: setPackingList,
      setPreview: setPackingListPreview,
    },
  ];

  const offerAcceptedProceedHandler = () => {
    if (billOfLanding || invoice || gd || demandLetter || packingList) {
      let docLength = 0;
      const uploadTransitDocumentsForm = new FormData();
      uploadTransitDocumentsForm.set("token", token);
      uploadTransitDocumentsForm.set("orderNo", offerAcceptedDocuments.orderNo);
      uploadTransitDocumentsForm.set(
        "vendor_phone",
        offerAcceptedDocuments.vendor_phone
      );
      uploadTransitDocumentsForm.set(
        "vendor_name",
        offerAcceptedDocuments.vendor_name
      );
      if (billOfLanding) {
        docLength += 1;
        uploadTransitDocumentsForm.set("bill_of_landing", billOfLanding);
      }
      if (invoice) {
        docLength += 1;
        uploadTransitDocumentsForm.set("invoice", invoice);
      }
      if (gd) {
        docLength += 1;
        uploadTransitDocumentsForm.set("gd", gd);
      }
      if (demandLetter) {
        docLength += 1;
        uploadTransitDocumentsForm.set("demand_letter", demandLetter);
      }
      if (packingList) {
        docLength += 1;
        uploadTransitDocumentsForm.set("packaging_list", packingList);
      }

      uploadTransitDocumentsForm.set("docLength", docLength);

      setFormData(uploadTransitDocumentsForm);
      setSkipTransitCargoDocument(false);
      dispatch(isFetchingSomethingAction(true));
    } else {
      notifyInfo("Upload atleast one document");
    }
  };

  useEffect(() => {
    if (Object.keys(offerAcceptedDocuments).length === 0) {
      navigate("/home");
    }
  }, []);

  const defaultImage = (fileType, setPreview) => {
    if (fileType) {
      let temp = fileType.name;
      temp = temp.substring(temp.indexOf(".") + 1);
      if (temp === "png" || temp === "jpg" || temp === "jfif") {
        return;
      } else {
        setPreview(FileImage);
      }
    }
  };

  useDidMountEffect(() => {
    defaultImage(billOfLanding, setBillOfLandingPreview);
    defaultImage(invoice, setInvoicePreview);
    defaultImage(gd, setGdPreview);
    defaultImage(demandLetter, setDemandLetterPreview);
    defaultImage(packingList, setPackingListPreview);
  }, [billOfLanding, invoice, gd, demandLetter, packingList]);

  const {
    data: uploadTransitCargoDocumentsRespoonse,
    error: uploadTransitCargoDocumentsError,
    isFetching: uploadTransitCargoDocumentsFetching,
  } = useUploadTransitCargoDocumentsQuery(formData, {
    skip: skipTransitCargoDocument,
  });

  useDidMountEffect(() => {
    if (
      uploadTransitCargoDocumentsFetching === false &&
      uploadTransitCargoDocumentsRespoonse &&
      !uploadTransitCargoDocumentsError
    ) {
      dispatch(isFetchingSomethingAction(false));
      setSkipTransitCargoDocument(true);
      navigate("/addressInfo");
    }

    if (
      uploadTransitCargoDocumentsFetching === false &&
      uploadTransitCargoDocumentsError
    ) {
    }
  }, [uploadTransitCargoDocumentsFetching]);

  return (
    <>
      {isFetchingSomething && <Loader />}
      {Object.keys(offerAcceptedDocuments).length > 0 && (
        <div className="offerAcceptedPage">
          <MyRequestCard
            myRequest={offerAcceptedDocuments}
            // index={index}
            statusText={offerAcceptedDocuments.statusText}
            statusColor={offerAcceptedDocuments.statusColor}
            // offerAcceptedByVendor
          />

          <Typography variant="h4">Upload Documents</Typography>
          <div className="offerAcceptedContainer">
            {documentsArray.map((document, index) => (
              <div className="offerAcceptedCard" key={index}>
                <Typography className="offerAcceptedCardHeading">
                  {document.name}
                </Typography>
                {document.preview ? (
                  <img src={document.preview} alt="" />
                ) : (
                  <FileCopyIcon />
                )}
                {!document.preview && (
                  <Button
                    variant="contained"
                    component="label"
                    className="offAcceptedUploadButtons"
                  >
                    Upload
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      onChange={(e) =>
                        documentUploadHandleChange(
                          e,
                          document.set,
                          document.setPreview
                        )
                      }
                    />
                  </Button>
                )}

                {document.preview && (
                  <Button
                    variant="contained"
                    component="label"
                    className="offAcceptedRemoveButtons"
                    onClick={() => {
                      document.setPreview("");
                      document.set("");
                    }}
                  >
                    Remove
                  </Button>
                )}
              </div>
            ))}
          </div>
          <div className="offerAcceptedNextPreviousButtonContainer">
            <Button
              size="large"
              variant="contained"
              //   style={{
              //     backgroundColor:
              //       activeStep === 0 ? "var(--Grey)" : "var(--primary)",
              //   }}
              //   disabled={activeStep === 0}
              onClick={offerAcceptedProceedHandler}
            >
              Proceed
            </Button>

            {/* {activeStep < 3 && ( */}
            <Button
              size="large"
              // style={{
              //   backgroundColor:
              //     activeStep === 3 ? "var(--Grey)" : "var(--primary)",
              // }}
              // disabled={activeStep === 3}
              onClick={() => navigate("/addressInfo")}
            >
              Upload later
            </Button>
            {/* )} */}

            {/* {activeStep === 3 && (
          <Button
            size="large"
            variant="contained"
            style={{
              backgroundColor: "var(--Success1)",
              marginBottom: "3rem",
            }}
            onClick={() => {
              orderRequestProceedHandler();
            }}
          >
            Proceed
          </Button>
        )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default OfferAccepted;
