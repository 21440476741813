import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import LogoImage from "./Logo2.png";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 18,
    // textAlign: "center",
    color: "black",
  },
  image: {
    width: 100,
  },
  text: {
    margin: 4,
    fontSize: 12,
  },
  boldText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  viewer: {
    flexDirection: "row",
  },

  gridViewerHeader: {
    backgroundColor: "#2f4d84",
    color: "white",
    flexDirection: "row",
    marginTop: 16,
    padding: "3 2",
  },

  gridViewerValue: {
    backgroundColor: "#ececec",
    flexDirection: "row",
    padding: "3 2",
  },

  gridViewerText: {
    width: "33%",
    fontSize: 12,
  },
});

const BiltyInvoice = ({ invoice }) => {
  return (
    <>
      <Document key={1}>
        <Page size="A4" style={styles.body}>
          <Image style={styles.image} src={LogoImage} />
          <Text style={styles.title}>
            Bilty Number: {invoice?.biltyInvoice?.biltyNo}
          </Text>

          <View style={styles.viewer}>
            <Text style={styles.text}>Customer ID:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["Customer ID"]}
            </Text>
            {/* <Text style={styles.text}>{invoice}</Text> */}
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>Customer name:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["Sender Name"]}
            </Text>
            {/* <Text style={styles.text}>{invoice}</Text> */}
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>Customer address:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["Sender Address"]}
            </Text>
            {/* <Text style={styles.text}>{invoice}</Text> */}
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>Receiver name:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["Receiver Name"]}
            </Text>
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>Receiver address:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["Receiver Address"]}
            </Text>
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>From (Origin):</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["From (Origin)"]}
            </Text>
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>To (Destination):</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["To: (Destination)"]}
            </Text>
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>Order number:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.["Order No"]}
            </Text>
          </View>

          <View style={styles.viewer}>
            <Text style={styles.text}>Date:</Text>
            <Text style={styles.text}>{invoice?.orderInvoice?.createdAt}</Text>
          </View>

          <View style={styles.gridViewerHeader}>
            <Text style={styles.gridViewerText}>Description of goods</Text>
            <Text style={styles.gridViewerText}>Weight (Kg/Ton)</Text>
            <Text style={styles.gridViewerText}>Vehicle type:</Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>
              {invoice?.biltyInvoice?.material}
            </Text>
            <Text style={styles.gridViewerText}>
              {invoice?.biltyInvoice?.weight} Tons
            </Text>
            <Text style={styles.gridViewerText}>
              {invoice?.biltyInvoice?.name}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default BiltyInvoice;
