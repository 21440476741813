import { Pagination, Stack, styled, Typography } from "@mui/material";

const CustomRequestContainer = styled(Stack)({
  // padding: "1rem",
  // margin: "1rem 0",
  marginBottom: "2rem",
});

function SingleRequestContainer({
  children,
  icon,
  headingText,
  headingColor,
  sx,
}) {
  return (
    <CustomRequestContainer sx={sx}>
      <Stack
        direction="row"
        sx={{
          color: headingColor,
          justifyContent: "center",
          alignItems: "center",

          // padding: "1rem 0",
          paddingBottom: "1rem",
          ">p": {
            fontWeight: "600",
            fontSize: "2rem",
          },
          svg: {
            fontSize: "3rem",
            marginRight: "1rem",
          },
        }}
      >
        {icon}
        <Typography>{headingText}</Typography>
      </Stack>
      {children}
    </CustomRequestContainer>
  );
}

export default SingleRequestContainer;
