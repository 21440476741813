import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const WebDrawer = ({
  Drawer,
  DrawerHeader,
  sidebarItems,
  handleDrawerAction,
  activeNavbar,
}) => {
  const { open } = useSelector((state) => state.utils);
  const { user, token, proApplicationData, myWalletData } = useSelector(
    (state) => state.userData
  );

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{ display: { xs: "none", md: "inherit" } }}
    >
      <DrawerHeader />

      <List>
        {sidebarItems.map((text) => (
          <Box key={text.name}>
            {((text.name === "Upgrade To Pro" &&
              proApplicationData?.proApplication?.status === "pending") ||
              !myWalletData?.data?.credit_amount ||
              text.name !== "Upgrade To Pro") && (
              <ListItem
                // disablePadding
                sx={{
                  display: "block",
                  padding: "0.25rem 0.5rem",
                  justifyContent: "center",
                  marginTop: text.name === "Logout" && "1rem",
                }}
                onClick={() => {
                  handleDrawerAction(text);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: "4rem",
                    justifyContent: open
                      ? "initial"
                      : { sm: "initial", md: "center" },
                    px: 2.5,
                    bgcolor: activeNavbar === text.name && "var(--primary)",
                    borderRadius: "5px",
                    ":hover": {
                      backgroundColor: "var(--primary)",
                      color: "white",
                      ".listItemButton": { color: "white" },
                    },
                  }}
                >
                  <ListItemIcon
                    className="listItemButton"
                    sx={{
                      minWidth: 0,
                      mr: open && 3,
                      color:
                        activeNavbar === text.name
                          ? "white"
                          : "var(--darkGrey)",
                    }}
                  >
                    <Stack
                      alignItems={"center"}
                      style={{
                        width: "50%",
                        margin: "auto",
                      }}
                    >
                      {text.icon}
                      {!open && (
                        <Typography
                          sx={{
                            whiteSpace: "initial",
                            textAlign: "center",
                            fontSize: "0.8rem",
                            fontWeight: "500",
                            fontFamily: "Roboto",
                          }}
                        >
                          {text.name}
                        </Typography>
                      )}
                    </Stack>
                  </ListItemIcon>

                  {open && (
                    <ListItemText
                      primary={text.name}
                      primaryTypographyProps={{
                        sx: { fontWeight: "500", fontFamily: "Roboto" },
                      }}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: activeNavbar === text.name && "white",
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            )}
            {text.name === "Settings" && <Divider />}
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default WebDrawer;
