import { CircularProgress } from "@mui/material";
import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <>
      <div className="loaderPage">
        <CircularProgress style={{ color: "white" }} />
      </div>
    </>
  );
};

export default Loader;
