import { Button, Stack, styled, Typography } from "@mui/material";
import React from "react";

const CustomTypography = styled(Typography)({
  b: {
    fontWeight: "600",
  },
});

const VehicleCard = ({ vehicle, sxdetails, sxImage, showButton }) => {
  return (
    <>
      <Stack sx={sxImage}>
        <img
          src={vehicle.image}
          alt="Order image"
          style={{
            objectFit: "contain",
          }}
        />
        <CustomTypography>
          <b> {vehicle.biltyNo} </b>
        </CustomTypography>

        {showButton && (
          <Button
            variant="contained"
            size="small"
            sx={{
              alignSelf: "flex-start",
              // fontWeight: "600",
              display: { xs: "inherit", lg: "none" },
            }}
            onClick={() => {
              // setSingleBilty(vehicle);
              // setSingleBiltyOpen(true);
            }}
          >
            Details
          </Button>
        )}
      </Stack>

      <Stack sx={sxdetails}>
        <CustomTypography>
          <b>Name: </b>
          {vehicle.name}
        </CustomTypography>
        <CustomTypography
          style={{
            color: !vehicle.driver_name
              ? "var(--BrightRed2)"
              : "var(--SuccessDark)",
          }}
        >
          <b
            style={{
              color: "var(--Black)",
            }}
          >
            Driver:{" "}
          </b>
          {vehicle.driver_name ? vehicle.driver_name : "Pending"}
        </CustomTypography>
        {vehicle.driver_phone && (
          <CustomTypography sx={{ color: "var(--SuccessDark)" }}>
            <b
              style={{
                color: "var(--Black)",
              }}
            >
              Driver Phone:{" "}
            </b>
            {vehicle.driver_phone}
          </CustomTypography>
        )}
        <CustomTypography
          style={{
            color: !vehicle.vehicle_number
              ? "var(--BrightRed2)"
              : "var(--SuccessDark)",
          }}
        >
          <b
            style={{
              color: "var(--Black)",
            }}
          >
            Vehicle:{" "}
          </b>
          {vehicle.vehicle_number ? vehicle.vehicle_number : "Pending"}
        </CustomTypography>

        <CustomTypography>
          <b>Weight: </b>
          {vehicle.weight} Tons
        </CustomTypography>
      </Stack>
    </>
  );
};

export default VehicleCard;
