import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import Art from "../../images/Art.png";
import { useInviteFriendQuery } from "../../services/userService";
import APIhandler from "../../utils/APIhandler";
import Loader from "../../utils/Loader";
import { notifyError, notifySuccess } from "../../utils/notifyMessage";
import "../MyTeam/MyTeam.css";

const InviteFriends = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [skipInviteFriends, setSkipInviteFriends] = useState(true);
  const { token } = useSelector((state) => state.userData);

  let inviteFriendQuery = {
    token,
    phone,
    name,
    email,
  };

  const {
    data: inviteFriendResponse,
    isFetching: inviteFriendFetching,
    error: inviteFriendError,
  } = useInviteFriendQuery(inviteFriendQuery, { skip: skipInviteFriends });

  return (
    <>
      {inviteFriendFetching && <Loader />}
      <APIhandler
        responseData={inviteFriendResponse}
        isFetching={inviteFriendFetching}
        serverError={inviteFriendError}
        onStatusTrue={() => {
          setSkipInviteFriends(true);
          notifySuccess("Invite friend successfully");
        }}
        onStatusFalse={() => {
          setSkipInviteFriends(true);
        }}
        onServerError={() => {
          setSkipInviteFriends(true);
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: { md: "2rem" },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: { md: "10px" },
            boxShadow: { md: "var(--boxShadow)" },
            borderBottom: { xs: "1px solid var(--borderGrey)", md: "0" },
            width: { md: "initial", xs: "100%" },
            img: {
              width: { xs: "100%", md: "20rem" },
              backgroundColor: "var(--primary)",
              borderRadius: { md: "5px 5px 0 0" },
            },
          }}
        >
          <img src={Art} alt="image" />
          <Stack spacing={2} sx={{ width: "92%", paddingBottom: "1rem" }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Friend's name"
            />
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Friend's email"
            />
            <Typography sx={{ color: "var(--primary)", textAlign: "center" }}>
              Enter number to invite friend
            </Typography>
            <PhoneInput
              className="addTeam"
              placeholder="Enter phone number"
              country={"pk"}
              value={phone}
              countryCodeEditable={false}
              onChange={(e) => setPhone("+" + e)}
            />

            <Button
              variant="contained"
              onClick={() => {
                if (!phone || !name || !email) {
                  notifyError("Fill all fields");
                } else {
                  setSkipInviteFriends(false);
                }
              }}
              sx={{ marginTop: "2rem !important" }}
            >
              Invite Friend
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default InviteFriends;
