// export const BASE_URL = "http://localhost:5000";
// export const BASE_URL = "https://staging-api.meribilty.com";
export const BASE_URL = "https://api.meribilty.com";

export const REGISTER_API = "/auth/user/send_register_otp";

export const REGISTER_API_OTP = "/auth/user/register_after_otp";

// export const LOGIN_API = "/api/user_login_2";

export const LOGIN_API = "/api/user_login_2";

export const ADVANCE_DEPOSIT_API = "/ppl/advanceDeposit";

export const PRO_USER_LESS_CREDITS_API = "/api/pro_user_less_credits";

export const CHANGE_USER_PASSWORD_API = "/ppl/changeUserPassword";

export const GET_WALLET = "/api/get_wallet";

export const CHAT_JOIN_API = "/chat/join";

export const FCM_TOKEN_API = "/fcm/save_token";

export const GET_MY_REQUESTS_API = "/ppl/get_user_orders";

export const GET_NOTIFICATIONS_API = "/api/get_notifications";

export const ORDER_INVOICE_API = "/api/get_invoice_for_order";

export const BILTY_INVOICE_API = "/api/get_invoice_for_bilty";

export const INVITE_FRIENDS_API = "/api/invite_friends";

export const SEARCH_ORDER_API = "/ppl/singleOrder";

export const READ_NOTIFICATIONS_API = "/api/read_unread_notification";

export const GET_CURRENT_CREDIT_DAYS_API =
  "/api/get_credit_days_options_for_user";

// export const UPDATE_USER_PROFILE_API = "/api/update_user_profile";
export const UPDATE_USER_PROFILE_API = "/api/update_user_profile";
export const GET_USER_PROFILE_API = "/api/get_user_profile";

export const GET_ALL_VEHICLES_API = "/api/get_vehicle_types";
export const GET_LOADING_OPTIONS_API = "/api/get_loading_options";
export const GET_UNLOADING_OPTIONS_API = "/api/get_unloading_options";

export const CREATE_REQUEST_API = "/ppl/create_request";

export const CALCULATE_INSURANCE_API = "/ppl/calculate_insurance";

export const GET_SINGLE_BILTY_API = "/api/get_single_bilty_transit";

export const GET_USER_PENDING_REQUEST_API = "/api/get_user_pending_orders";
export const GET_OFFER_RECEIVED_API = "/api/user_get_vendor_qoutes";
export const GET_SINGLE_OFFER_RECEIVED_API = "/api/user_get_vendor_qoute";
export const GET_COUNTER_BY_USER_API = "/api/user_get_counter_offers";
export const GET_COUNTER_BY_VENDOR_API = "/api/user_get_partner_counter_offers";
export const GET_COUNTER_ACCEPTED_BY_VENDOR_API =
  "/api/user_get_counter_offers";
export const GET_REJECTED_REQUESTS_API = "/api/get_user_rejected_orders";
export const GET_WAITING_FOR_ADMIN_APPROVAL_API =
  "/api/get_user_payment_approval_awaiting_orders";

export const USER_REJECT_VENDOR_QOUTE_API = "/ppl/user_reject_vendor_qoute";
export const USER_COUNTER_VENDOR_QOUTE_API = "/ppl/user_counters_vendor_qoute";
export const USER_ACCEPT_VENDOR_QOUTE_API = "/ppl/user_accept_vendor_qoute";

export const ORDER_ACCEPT_API = "/ppl/order_accept";

export const USER_REJECT_VENDOR_COUNTER_QOUTE_API =
  "/ppl/user_reject_vendor_counter_offer";
export const USER_ACCEPT_VENDOR_COUNTER_QOUTE_API =
  "/ppl/user_accept_vendor_counter_offer";

export const USER_CANCEL_ORDER_API = "/ppl/order_reject_2";

export const GET_CREDIT_DAYS_API = "/ppl/credit_days";

export const GET_MATERIALS_API = "/api/get_materials";

export const GET_USER_ACTIVE_REQUEST_API = "/api/get_user_active_orders";
export const GET_USER_COMPLETED_ORDERS_API = "/api/get_user_completed_orders";

export const GET_PROVINCES_API = "/ppl/get-provinces";
export const ADDRESS_INFO_API = "/ppl/add_more_address_info";
export const USER_ADD_PAYMENT_METHOD_API = "/ppl/user_add_payment_method";

export const USER_ADD_CONTACT_PERSON_TO_REQUEST_API =
  "/ppl/user_add_contact_person_to_request";

export const UPGRADE_TO_PRO_API = "/auth/pro/send_application";
export const GET_PRO_APPLICATION_API = "/auth/pro/get_application";

export const USER_GIVE_RATING_API = "/api/user_give_rating";

export const GET_CONTACT_PERSONS_API = "/ppl/get_contact_persons";
export const ADD_CONTACT_PERSON_API = "/ppl/user_invites_a_user";
export const EDIT_CONTACT_PERSON_API = "/ppl/user_edit_contact_person";
export const REMOVE_CONTACT_PERSON_API = "/ppl/user_remove_contact_person";

export const UPLOAD_TRANSIT_CARGO_DOCUMENTS_API =
  "/ppl/user_upload_transit_cargo_documents";
export const UPLOAD_UPCOUNTRY_CARGO_DOCUMENTS_API =
  "/ppl/user_upload_upcountry_documents";

export const GOOGLE_MAP_API_KEY = "AIzaSyBtmA2MsfljS60NA3c_ljiVXC5gvv8TIFg";
export const VAPID_KEY =
  "BFKgtS3GYgun4ORwJfzQZdaylzSs0QyG3VifrNaicf8_DlAfrzOg2WdLdGeSvteNizW3gEXUCRi4VNuVk3uyB2A";

export const LARGESCREEN = 1024;
export const MEDIUMSCREEN = 1023;
export const GET_STREAM_API_KEY = "r4vjktd8gbcr";

{
  /******************   DUMMY *******************/
}
// "start": "react-scripts --max_old_space_size=8192 start",

// const {
//   data: getContactPersonsResponse,
//   isFetching: getContactPersonsFetching,
//   error: getContactPersonsError,
// } = useGetContactPersonsQuery(token);
// <APIhandler
//       responseData={getContactPersonsResponse}
//       isFetching={getContactPersonsFetching}
//       serverError={getContactPersonsError}
//       onStatusTrue={() => {}}
//       onStatusFalse={() => {}}
//       onServerError={() => {}}
//     />
