import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingSomething: false,
  isLoaded: false,
  materials: [],
  requestPerPage: 4,
  open: false,
  notificationId: "",

  notificationsData: [],
  allNotificationsData: [],
  unReadNotificationsData: [],
  showNotification: false,
  unReadNotification: false,
  unReadNotificationsCount: 0,
  allNotifications: true,

  allNotificationsRouteData: [],
  allNotificationsRouteFinalData: [],

  notificationsPerPage: 10,
  myRequest: {},
  prefetching: false,

  notificationTitleAndBody: {},

  scrollToId: "",
  driverStatusOrderNo: "",
  driverStatusBiltyNo: "",

  showVehicles: "transit",
};

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    isFetchingSomethingAction(state, action) {
      const isFetching = action.payload;
      state.isFetchingSomething = isFetching;
    },
    prefetchingAction(state, action) {
      const prefetching = action.payload;
      state.prefetching = prefetching;
    },

    loadGoogleMapAction(state, action) {
      state.isLoaded = action.payload;
    },

    materialsAction(state, action) {
      state.materials = action.payload;
    },

    requestsPerPageAction(state, action) {
      state.requestPerPage = action.payload;
    },

    drawerOpenAction(state, action) {
      state.open = action.payload;
    },

    notificationIdAction(state, action) {
      state.notificationId = action.payload;
    },

    showNotificationAction(state, action) {
      state.showNotification = action.payload;
    },

    setAllNotificationsAction(state, action) {
      state.allNotifications = action.payload;
    },

    notificationsDataAction(state, action) {
      const notificationsData = action.payload;
      state.allNotificationsRouteData = notificationsData;

      if (notificationsData && notificationsData.length > 0) {
        let tempNotificationsData = [];
        let tempNotificationsDataLength =
          notificationsData.length > state.notificationsPerPage
            ? state.notificationsPerPage
            : notificationsData.length;

        for (let i = 0; i < state.notificationsPerPage; i++) {
          tempNotificationsData.push(notificationsData[i]);
        }

        state.notificationsData = tempNotificationsData;
        state.allNotificationsData = tempNotificationsData;
        state.allNotificationsRouteFinalData = tempNotificationsData;

        let unReadNotificationsCount = 0;
        let tempUnreadNotificationsData = [];
        for (let i = 0; i < notificationsData.length; i++) {
          if (!notificationsData[i]?.read) {
            state.unReadNotification = true;
            unReadNotificationsCount += 1;
            tempUnreadNotificationsData.push(notificationsData[i]);
          }
        }

        state.unReadNotificationsData = tempUnreadNotificationsData;
        state.unReadNotificationsCount = unReadNotificationsCount;
      }
    },

    scrollingNotificationsDataAction(state, action) {
      const page = action.payload;

      let tempNotificationsData = [];

      for (let i = 0; i < state.notificationsPerPage * page; i++) {
        if (
          state.allNotifications === true &&
          state.allNotificationsRouteData[i]
        ) {
          tempNotificationsData.push(state.allNotificationsRouteData[i]);
        }
        if (
          state.allNotifications === false &&
          state.unReadNotificationsData[i]
        ) {
          tempNotificationsData.push(state.unReadNotificationsData[i]);
        }
      }

      state.allNotificationsRouteFinalData = tempNotificationsData;
    },

    disableUnReadNotificationAction(state) {
      state.unReadNotification = false;
    },

    showReadUnReadNotificationsAction(state, action) {
      const { all, page } = action.payload;

      let tempShowUnReadNotifications = [];
      let tempShowUnReadNotificationsRoute = [];

      if (all === false) {
        // for (let i = 0; i < state.notificationsData.length; i++) {
        //   if (!state.notificationsData[i]?.read) {
        //     tempShowUnReadNotifications.push(state.notificationsData[i]);
        //   }
        // }

        // state.notificationsData = tempShowUnReadNotifications;

        for (let i = 0; i < state.notificationsPerPage; i++) {
          tempShowUnReadNotificationsRoute.push(
            state.unReadNotificationsData[i]
          );
        }
      } else {
        state.notificationsData = state.allNotificationsData;

        for (let i = 0; i < state.notificationsPerPage; i++) {
          tempShowUnReadNotificationsRoute.push(
            state.allNotificationsRouteData[i]
          );
        }
      }

      state.allNotificationsRouteFinalData = tempShowUnReadNotificationsRoute;
    },

    disableSingleUnReadNotificationRouteAction(state, action) {
      const notificationId = action.payload;

      for (let i = 0; i < state.allNotificationsRouteData.length; i++) {
        if (state.allNotificationsRouteData[i].id === notificationId) {
          state.allNotificationsRouteData[i].read = true;
          // state.notificationsData[i].read = true;
          break;
        }
      }
    },

    disableSingleUnReadNotificationAction(state, action) {
      const notificationId = action.payload;

      for (let i = 0; i < state.notificationsData.length; i++) {
        if (state.notificationsData[i].id === notificationId) {
          state.notificationsData[i].read = true;
          break;
        }
      }
    },

    notificationTitleAndBodyAction(state, action) {
      state.notificationTitleAndBody = action.payload;
    },

    scrollToIdAction(state, action) {
      state.scrollToId = action.payload;
    },

    updateDriverStatusAction(state, action) {
      const { orderNo, biltyNo } = action.payload;
      state.driverStatusOrderNo = orderNo;
      state.driverStatusBiltyNo = biltyNo;
    },

    showVehiclesAction(state, action) {
      state.showVehicles = action.payload;
    },
  },
});

export const {
  isFetchingSomethingAction,
  prefetchingAction,
  loadGoogleMapAction,
  materialsAction,
  requestsPerPageAction,
  drawerOpenAction,
  notificationIdAction,
  showNotificationAction,
  setAllNotificationsAction,
  notificationsDataAction,
  disableUnReadNotificationAction,
  showReadUnReadNotificationsAction,
  disableSingleUnReadNotificationAction,
  disableSingleUnReadNotificationRouteAction,
  scrollToIdAction,
  scrollingNotificationsDataAction,
  updateDriverStatusAction,
  showVehiclesAction,
} = utilsSlice.actions;

export default utilsSlice.reducer;
