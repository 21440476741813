import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import LogoImage from "./Logo2.png";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 18,
    // textAlign: "center",
    color: "black",
  },
  image: {
    width: 100,
    marginBottom: 10,
  },
  text: {
    margin: 4,
    fontSize: 12,
  },
  boldText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  viewer: {
    flexDirection: "row",
    // width: "90%",
  },

  gridViewerHeader: {
    backgroundColor: "#2f4d84",
    color: "white",
    flexDirection: "row",
    marginTop: 16,
    padding: "3 8",
    justifyContent: "space-between",
  },

  gridViewerValue: {
    backgroundColor: "#ececec",
    flexDirection: "row",
    padding: "3 8",
    justifyContent: "space-between",
  },

  gridViewerText: {
    fontSize: 12,
  },
});

const OrderInvoice = ({ invoice }) => {
  return (
    <>
      <Document key={1}>
        <Page size="A4" style={styles.body}>
          <Image style={styles.image} src={LogoImage} />
          <Text style={styles.title}>
            Order Number: {invoice?.orderInvoice?.["Order No"]}
          </Text>

          <View style={styles.viewer}>
            <View style={{ width: "50%" }}>
              <Text style={styles.text}>
                Customer ID: {invoice?.orderInvoice?.["Customer ID"]}
              </Text>

              <Text style={styles.text}>
                From (Origin): {invoice?.orderInvoice?.["From (Origin)"]}
              </Text>

              <Text style={styles.text}>
                To (Destination): {invoice?.orderInvoice?.["To: (Destination)"]}
              </Text>

              <Text style={styles.text}>
                Sender Name: {invoice?.orderInvoice?.["Sender Name"]}
              </Text>

              <Text style={styles.text}>
                Sender Address: {invoice?.orderInvoice?.["Sender Address"]}
              </Text>

              <Text style={styles.text}>
                Receiver name: {invoice?.orderInvoice?.["Receiver Name"]}
              </Text>

              <Text style={styles.text}>
                Receiver Address: {invoice?.orderInvoice?.["Receiver Address"]}
              </Text>

              <Text style={styles.text}>
                Additional Information:{" "}
                {invoice?.orderInvoice?.additionalDescription}
              </Text>
            </View>

            <View style={{ width: "50%" }}>
              <Text style={styles.text}>
                Invoice Number: {invoice?.orderInvoice?.["Invoice Number"]}
              </Text>

              <Text style={styles.text}>
                Order Number: {invoice?.orderInvoice?.["Order No"]}
              </Text>

              <Text style={styles.text}>
                Booking Number: {invoice?.orderInvoice?.["Booking Number"]}
              </Text>

              <Text style={styles.text}>
                Date: {invoice?.orderInvoice?.createdAt}
              </Text>

              <Text style={styles.text}>
                Due Date: {invoice?.orderInvoice?.due_date_text}
              </Text>

              <Text style={styles.text}>Vehicle Registration:</Text>

              <Text style={styles.text}>
                Vehicle Type: {invoice?.orderInvoice?.["Vehicle Types"]}
              </Text>

              <Text style={styles.text}>
                Weight (Ton): {invoice?.orderInvoice?.Weights}
              </Text>
            </View>
          </View>

          <View style={styles.gridViewerHeader}>
            <Text style={styles.gridViewerText}>Description of goods</Text>
            <Text style={styles.gridViewerText}>Amount PKR</Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>Fuel and Transit Expense</Text>
            <Text style={styles.gridViewerText}>
              {invoice?.orderInvoice?.fuelAndOtherCharges}
            </Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>Service charges</Text>
            <Text style={styles.gridViewerText}>
              {invoice?.orderInvoice?.serviceCharges}
            </Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>Sales tax</Text>
            <Text style={styles.gridViewerText}>
              {invoice?.orderInvoice?.salesTax}
            </Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>Insurance</Text>
            <Text style={styles.gridViewerText}>
              {invoice?.orderInvoice?.cargo_value}
            </Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>Grand total</Text>
            <Text style={styles.gridViewerText}>
              {invoice?.orderInvoice?.total}
            </Text>
          </View>

          <View style={styles.gridViewerValue}>
            <Text style={styles.gridViewerText}>Amount due</Text>
            <Text style={styles.gridViewerText}>
              {invoice?.orderInvoice?.due_amount}
            </Text>
          </View>

          <View style={[styles.viewer, { marginTop: 16 }]}>
            <Text style={[styles.text, { fontWeight: "bold" }]}>
              Amount in words: Pakistani Rupee
            </Text>
          </View>
          <Text style={[styles.text, { marginLeft: 10 }]}>
            {invoice?.orderInvoice?.totalInWords}
          </Text>

          {/* <View style={styles.viewer}>
            <Text style={styles.text}>Payment Method:</Text>
            <Text style={styles.text}>
              {invoice?.orderInvoice?.payment_method}
            </Text>
          </View> */}
        </Page>
      </Document>
    </>
  );
};

export default OrderInvoice;
