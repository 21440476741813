import styled from "@emotion/styled";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./AddressInfo.css";
import "../UpgradeToPro/UpgradeToPro.css";
import LogoBird from "../../images/logoBird.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetProvincesQuery } from "../../services/userService";
import { addressInfoAction } from "../../sliceReducers/userSlice";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "var(--primary)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--primary)",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary)",
    },
  },
});

const countries = ["Pakistan", "Afghanistan"];

const AddressInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fromName, setFromName] = useState("");
  const [fromAddress, setFromAddress] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [fromProvince, setFromProvince] = useState("");
  const [fromCountry, setFromCountry] = useState("");

  const [toName, setToName] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [toCity, setToCity] = useState("");
  const [toProvince, setToProvince] = useState("");
  const [toCountry, setToCountry] = useState("");

  const { offerAcceptedDocuments, addressInfoFrom, addressInfoTo } =
    useSelector((state) => state.userData);

  const { data: provincesData } = useGetProvincesQuery();

  useEffect(() => {
    if (addressInfoFrom && addressInfoTo) {
      setFromName(addressInfoFrom.name);
      setFromAddress(addressInfoFrom.address);
      setFromCity(addressInfoFrom.city);
      setFromProvince(addressInfoFrom.province);
      setFromCountry(addressInfoFrom.country);

      setToName(addressInfoTo.name);
      setToAddress(addressInfoTo.address);
      setToCity(addressInfoTo.city);
      setToProvince(addressInfoTo.province);
      setToCountry(addressInfoTo.country);
    }
  }, []);

  const addressInfoProceedHandler = (e, navigate) => {
    e.preventDefault();
    const from = {
      name: fromName,
      address: fromAddress,
      city: fromCity,
      province: fromProvince,
      country: fromCountry,
    };

    const to = {
      name: toName,
      address: toAddress,
      city: toCity,
      province: toProvince,
      country: toCountry,
    };

    dispatch(addressInfoAction({ from, to }));
    navigate("/paymentMethod");
  };

  return (
    <>
      <div className="addressInfoPage">
        <div className="addressInfoContainer">
          <Typography variant="h4">Address Info</Typography>
          <form
            className="upgradeToProForm"
            // style={{ zIndex: "1" }}
            onSubmit={(e) => addressInfoProceedHandler(e, navigate)}
          >
            {/******************   FROM *******************/}
            <Typography className="upgradeToProFormHeading">From</Typography>

            <CssTextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              className="upgradeToProFormTextInput"
              required
              value={fromName}
              onChange={(e) => {
                setFromName(e.target.value);
              }}
            />

            <CssTextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              className="upgradeToProFormTextInput"
              required
              value={fromAddress}
              onChange={(e) => {
                setFromAddress(e.target.value);
              }}
            />

            <CssTextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              className="upgradeToProFormTextInput"
              required
              value={fromCity}
              onChange={(e) => {
                setFromCity(e.target.value);
              }}
            />

            <CssTextField
              id="outlined-select-currency"
              className="upgradeToProFormTextInput"
              select
              label="Province"
              value={fromProvince}
              required
              onChange={(e) => {
                setFromProvince(e.target.value);
              }}
            >
              {provincesData &&
                provincesData.Data.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.provinceLongname}
                    style={{ color: "var(--Black)" }}
                  >
                    {option.provinceLongname}
                  </MenuItem>
                ))}
            </CssTextField>

            <CssTextField
              id="outlined-select-currency"
              className="upgradeToProFormTextInput"
              select
              label="Country"
              value={fromCountry}
              required
              onChange={(e) => {
                setFromCountry(e.target.value);
              }}
            >
              {countries &&
                countries.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={{ color: "var(--Black)" }}
                  >
                    {option}
                  </MenuItem>
                ))}
            </CssTextField>

            {/******************   To *******************/}
            <Typography className="upgradeToProFormHeading" pt={"1rem"}>
              To
            </Typography>

            <CssTextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              className="upgradeToProFormTextInput"
              required
              value={toName}
              onChange={(e) => {
                setToName(e.target.value);
              }}
            />

            <CssTextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              className="upgradeToProFormTextInput"
              required
              value={toAddress}
              onChange={(e) => {
                setToAddress(e.target.value);
              }}
            />

            <CssTextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              className="upgradeToProFormTextInput"
              required
              value={toCity}
              onChange={(e) => {
                setToCity(e.target.value);
              }}
            />

            <CssTextField
              id="outlined-select-currency"
              className="upgradeToProFormTextInput"
              select
              label="Province"
              value={toProvince}
              required
              onChange={(e) => {
                setToProvince(e.target.value);
              }}
            >
              {provincesData &&
                provincesData.Data.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.provinceLongname}
                    style={{ color: "var(--Black)" }}
                  >
                    {option.provinceLongname}
                  </MenuItem>
                ))}
            </CssTextField>

            <CssTextField
              id="outlined-select-currency"
              className="upgradeToProFormTextInput"
              select
              label="Country"
              value={toCountry}
              required
              onChange={(e) => {
                setToCountry(e.target.value);
              }}
            >
              {countries &&
                countries.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={{ color: "var(--Black)" }}
                  >
                    {option}
                  </MenuItem>
                ))}
            </CssTextField>

            <Button
              type="submit"
              size="large"
              variant="contained"
              className="adressInfoProceedButton"
            >
              Proceed
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddressInfo;
