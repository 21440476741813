import { toast } from "react-toastify";
import { LARGESCREEN } from "./config";
import "./notifyMessages.css";

export const notifySuccess = (message) =>
  toast.success(message, {
    // style: { width: window.innerWidth >= LARGESCREEN ? "50vw" : "100vw" },
    position: `${
      window.innerWidth >= LARGESCREEN ? "bottom-right" : "bottom-center"
    }`,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const notifyError = (message) =>
  toast.error(message, {
    // style: { width: window.innerWidth >= LARGESCREEN ? "50vw" : "100vw" },
    position: `${
      window.innerWidth >= LARGESCREEN ? "bottom-right" : "bottom-center"
    }`,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const notifyWarning = (message) =>
  toast.warning(message, {
    // style: { width: window.innerWidth >= LARGESCREEN ? "50vw" : "100vw" },
    position: `${
      window.innerWidth >= LARGESCREEN ? "bottom-right" : "bottom-center"
    }`,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const notifyInfo = (message) =>
  toast.info(message, {
    // style: { width: window.innerWidth >= LARGESCREEN ? "50vw" : "100vw" },
    position: `${
      window.innerWidth >= LARGESCREEN ? "bottom-right" : "bottom-center"
    }`,
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
