import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const Bilties = ({ vehicle, vehicleIndex }) => {
  const [loadingOptions, setloadingOptions] = useState([]);
  const [unLoadingOptions, setUnLoadingOptions] = useState([]);

  useEffect(() => {
    let tempLoadingOptions = [];
    let tempUnLoadingOptions = [];

    if (
      vehicle.type === "loading/unloading" &&
      vehicle?.loading_options?.length > 0
    ) {
      for (let i = 0; i < vehicle.loading_options.length; i++) {
        if (
          !tempLoadingOptions.some(
            (e) => e.name === vehicle.loading_options[i].name
          )
        ) {
          tempLoadingOptions.push({
            ...vehicle.loading_options[i],
            quantity: 0,
          });
        }
      }

      for (let i = 0; i < tempLoadingOptions.length; i++) {
        for (let j = 0; j < vehicle.loading_options.length; j++) {
          if (tempLoadingOptions[i].name === vehicle.loading_options[j].name) {
            tempLoadingOptions[i].quantity++;
          }
        }
      }

      setloadingOptions(tempLoadingOptions);
    }

    if (
      vehicle.type === "loading/unloading" &&
      vehicle?.unloading_options?.length > 0
    ) {
      for (let i = 0; i < vehicle.unloading_options.length; i++) {
        if (
          !tempUnLoadingOptions.some(
            (e) => e.name === vehicle.unloading_options[i].name
          )
        ) {
          tempUnLoadingOptions.push({
            ...vehicle.unloading_options[i],
            quantity: 0,
          });
        }
      }

      for (let i = 0; i < tempUnLoadingOptions.length; i++) {
        for (let j = 0; j < vehicle.unloading_options.length; j++) {
          if (
            tempUnLoadingOptions[i].name === vehicle.unloading_options[j].name
          ) {
            tempUnLoadingOptions[i].quantity++;
          }
        }
      }

      setUnLoadingOptions(tempUnLoadingOptions);
    }
  }, []);

  return (
    <>
      {vehicle.type === "vehicle" && (
        <div
          key={vehicleIndex}
          className="myOrderVehicleMaterialsDetailsContainer"
          style={{
            padding:
              vehicle.loading_options || vehicle.unloading_options
                ? "0.5rem 0.25rem"
                : "0 0.25rem",
          }}
        >
          <img src={vehicle.image} alt="Order image" />
          <div className="myOrderVehicleMaterialsDetails">
            <Typography>
              <b>Name: </b>
              {vehicle.name}
            </Typography>
            <Typography>
              <b>Quantity: </b>
              {vehicle.quantity}
            </Typography>
            <Typography>
              <b>Weight: </b>
              {vehicle.weight}
            </Typography>
            <Typography>
              <b>Material: </b>
              {vehicle?.material?.name
                ? vehicle?.material?.name
                : vehicle?.material}
            </Typography>
          </div>
        </div>
      )}

      {vehicle.type === "loading/unloading" &&
        vehicle.loading_options &&
        loadingOptions.map((loading, index) => (
          <div
            key={index}
            className="myOrderVehicleMaterialsDetailsContainer"
            style={{
              padding:
                vehicle.loading_options || vehicle.unloading_options
                  ? "0.5rem 0.25rem"
                  : "0 0.25rem",
            }}
          >
            <img src={loading.image} alt="Order image" />
            <div className="myOrderVehicleMaterialsDetails">
              <Typography>
                <b>Name: </b>
                {loading.name}
              </Typography>
              <Typography>
                <b>Quantity: </b>
                {loading.quantity}
              </Typography>
              {loading.name !== "Labour" && (
                <Typography>
                  <b>Weight: </b>
                  {loading.weight}
                </Typography>
              )}
              <Typography>
                <b>Type: </b>
                {loading.type}
              </Typography>
            </div>
          </div>
        ))}

      {vehicle.type === "loading/unloading" &&
        vehicle.unloading_options &&
        unLoadingOptions.map((unloading, index) => (
          <div
            key={index}
            className="myOrderVehicleMaterialsDetailsContainer"
            style={{
              padding:
                vehicle.loading_options || vehicle.unloading_options
                  ? "0.5rem 0.25rem"
                  : "0 0.25rem",
            }}
          >
            <img src={unloading.image} alt="Order image" />
            <div className="myOrderVehicleMaterialsDetails">
              <Typography>
                <b>Name: </b>
                {unloading.name}
              </Typography>
              <Typography>
                <b>Quantity: </b>
                {unloading.quantity}
              </Typography>
              {unloading.name !== "Labour" && (
                <Typography>
                  <b>Weight: </b>
                  {unloading.weight}
                </Typography>
              )}
              <Typography>
                <b>Type: </b>
                {unloading.type}
              </Typography>
            </div>
          </div>
        ))}
    </>
  );
};

export default Bilties;
