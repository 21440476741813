import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Loader from "../../utils/Loader";
import CloseIcon from "@mui/icons-material/Close";
import useDidMountEffect from "../../utils/useDidMountEffect";
import {
  useGetMyRequestsQuery,
  useSearchSingleOrderQuery,
} from "../../services/userService";
import { useSelector } from "react-redux";
import { delaySkip } from "../../utils/commonFunctions";
import MyRequestCard from "../MyRequests/MyRequestCard";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MyDeliveryCard from "../MyDeliveries/MyDeliveryCard";
import SingleRequestContainer from "../MyRequests/SingleRequestContainer";
import { useNavigate, useParams } from "react-router-dom";
import "./MyRequests.css";

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SingleRequest = () => {
  const [skipSearchSingleOrder, setSkipSearchSingleOrder] = useState(true);
  const { orderNo } = useParams();
  const [singleOrder, setSingleOrder] = useState();
  const [statusColor, setStatusColor] = useState("");
  const [statusText, setStatusText] = useState("");
  const [myRequestNumbers, setMyRequestNumbers] = useState([]);
  const [showSearchDialog, setShowSearchDialog] = useState(true);

  const navigate = useNavigate();

  const { user, token } = useSelector((state) => state.userData);

  useEffect(() => {
    if (token) {
      setSkipSearchSingleOrder(false);
    }
  }, [token]);

  let searchSingleOrderquery = {
    token,
    orderNo,
  };

  const {
    data: searchSingleOrderResponse,
    error: searchSingleOrderError,
    isFetching: searchSingleOrderFetching,
  } = useSearchSingleOrderQuery(searchSingleOrderquery, {
    skip: skipSearchSingleOrder,
  });

  useDidMountEffect(() => {
    if (
      !searchSingleOrderFetching &&
      searchSingleOrderResponse &&
      !searchSingleOrderError
    ) {
      if (searchSingleOrderResponse.status !== false) {
        setSkipSearchSingleOrder(true);
        setSingleOrder(searchSingleOrderResponse);
      } else if (searchSingleOrderResponse.status === false) {
        setSingleOrder(searchSingleOrderResponse);
        setSkipSearchSingleOrder(true);
      }
    }

    if (!searchSingleOrderFetching && searchSingleOrderError) {
      setSkipSearchSingleOrder(true);
    }
  }, [searchSingleOrderFetching]);

  //Adding delay in startPrice input because the price are real time
  useDidMountEffect(() => {
    //user is "finished typing," do something
    const doneTyping = () => {
      setSkipSearchSingleOrder(false);
    };

    if (orderNo) {
      delaySkip("searchOrder", doneTyping);
    } else {
      setSkipSearchSingleOrder(true);
    }
  }, [orderNo]);

  useDidMountEffect(() => {
    switch (singleOrder?.status) {
      case "pending":
        setStatusText("Finding Best Rates");
        setStatusColor("var(--primary)");
        break;
      case "offerReceived":
        setStatusText("Offers Received");
        setStatusColor("var(--QouteReceived2)");
        break;
      case "userCounter":
        setStatusText("Counter offer sent");
        setStatusColor("var(--CounterOfferReceived2)");
        break;
      case "vendorCounter":
        setStatusText("Counter By Vendor");
        setStatusColor("var(--CounterOfferReceived)");
        break;
      case "rejected":
        setStatusText("Rejected");
        setStatusColor("var(--BrightRed)");
        break;
      case "accepted":
        setStatusText("Active Orders");
        setStatusColor("var(--yellowAbmer)");
        break;
      case "qoute_accepted":
        setStatusText("Waiting for admin approval");
        setStatusColor("var(--Black)");
        break;
      case "completed":
        setStatusText("Completed Orders");
        setStatusColor("var(--SuccessDark)");
        break;

      default:
        setStatusText("");
        setStatusColor("");
        break;
    }
  }, [singleOrder]);

  const {
    data: getMyRequestsResponse,
    error: getMyRequestsError,
    isFetching: getMyRequestsFetching,
  } = useGetMyRequestsQuery(token);

  useDidMountEffect(() => {
    if (
      !getMyRequestsFetching &&
      getMyRequestsResponse &&
      !getMyRequestsError
    ) {
      let tempData = [{}];
      if (getMyRequestsResponse?.data) {
        getMyRequestsResponse.data.forEach((request) => {
          tempData.push({ reqNo: request.orderNo, status: request.status });
        });

        setMyRequestNumbers(tempData);
      }
    }
  }, [getMyRequestsFetching]);

  return (
    <>
      <Dialog
        maxWidth={"xl"}
        open={showSearchDialog}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        aria-describedby="alert-dialog-slide-description"
      >
        {searchSingleOrderFetching && <Loader />}
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "var(--primary)",
            position: "fixed",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowSearchDialog(false);
                navigate("/requests");
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{
                color: "white",
                textAlign: "center",
              }}
            >
              Order Number {orderNo}
            </Typography>

            <Button
              autoFocus
              color="inherit"
              // onClick={() => setManageTrackBiltiesOpen(false)}
            >
              {/* save */}
            </Button>
          </Toolbar>
        </AppBar>

        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            width: { xs: "90%", md: "initial" },
            margin: { xs: "auto", md: "initial" },
            justifyContent: { md: "space-around" },
            alignItems: { md: "flex-start" },
          }}
        >
          <SingleRequestContainer
            sx={{ marginTop: "5rem" }}
            // icon={<SearchRoundedIcon />} // Change
            // headingText={"Search Result"} // Change
            // headingColor={"var(--primary)"}
          >
            {singleOrder &&
              orderNo &&
              (singleOrder.status === "pending" ||
                singleOrder.status === "offerReceived" ||
                singleOrder.status === "userCounter" ||
                singleOrder.status === "vendorCounter" ||
                singleOrder.status === "qoute_accepted" ||
                singleOrder.status === "rejected") && (
                <MyRequestCard // Props Change
                  myRequest={singleOrder?.data}
                  index={0}
                  statusText={statusText}
                  statusColor={statusColor}
                  findingRates={singleOrder.status === "pending" ? true : false}
                  offerReceived={
                    singleOrder.status === "offerReceived" ? true : false
                  }
                  offerCounterByVendor={
                    singleOrder.status === "vendorCounter" ? true : false
                  }
                />
              )}

            {singleOrder &&
              orderNo &&
              (singleOrder.status === "accepted" ||
                singleOrder.status === "completed") && (
                <MyDeliveryCard // Props Change
                  myRequest={singleOrder?.data}
                  index={0}
                  statusText={statusText}
                  statusColor={statusColor}
                  orderCompleted={
                    singleOrder.status === "completed" ? true : false
                  }
                />
              )}

            {!searchSingleOrderFetching && singleOrder?.message && (
              <Typography sx={{ textAlign: "center", fontSize: "1.5rem" }}>
                {singleOrder?.message}
              </Typography>
            )}
          </SingleRequestContainer>
        </Stack>
      </Dialog>
    </>
  );
};

export default SingleRequest;
