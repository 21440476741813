import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./PaymentMethod.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageIcon from "@mui/icons-material/Image";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PlaceIcon from "@mui/icons-material/Place";
import {
  useAddContactPersonToRequestQuery,
  useAddressInfoQuery,
  useOrderAcceptQuery,
  useUserAcceptVenderCounterQouteQuery,
  useUserAcceptVenderQouteQuery,
  useUserAddPaymentMethodQuery,
} from "../../services/userService";
import { useDispatch, useSelector } from "react-redux";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import { notifyError, notifySuccess, notifyWarning } from "../../utils/notifyMessage";
import { toast } from "react-toastify";
import { LARGESCREEN } from "../../utils/config";
import "../../utils/notifyMessages.css";
import Loader from "../../utils/Loader";
import MyTeam from "../MyTeam/MyTeam";
import { useNavigate } from "react-router-dom";

const paymentMethods = [
  {
    name: "Bank transfer",
    paymentMethod: "bank",
    icon: <AccountBalanceIcon />,
  },
  {
    name: "Cash on delivery",
    paymentMethod: "cod",
    icon: <MonetizationOnIcon />,
  },
];
const PaymentMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState("credit");
  const [selectMethodOpen, setSelectMethodOpen] = useState(false);
  const [bankTransferImage, setBankTransferImage] = useState();
  const [skipPayment, setSkipPayment] = useState(true);
  const [pointOfPayment, setPointOfPayment] = useState("origin");
  const [bankTransferImagePreview, setBankTransferImagePreview] = useState("");
  const [formData, setFormData] = useState();
  const [actionNo, setActionNo] = useState(0);

  const [openSelectFromExistingList, setOpenSelectFromExistingList] =
    useState(false);

  const [selectedAgentFromList, setSelectedAgentFromList] = useState();

  const [skipAddressInfo, setSkipAddressInfo] = useState(true);

  const [skipUserAcceptQoute, setSkipUserAcceptQoute] = useState(true);

  const [
    skipUserAcceptVendorCounterQoute,
    setSkipUserAcceptVendorCounterQoute,
  ] = useState(true);

  const [skipAddContactPerson, setSkipAddContactPerson] = useState(true);

  const [skipOrderAccept, setSkipOrderAccept] = useState(true);

  const { token, offerAcceptedDocuments, addressInfoFrom, addressInfoTo } =
    useSelector((state) => state.userData);

  const { isFetchingSomething } = useSelector((state) => state.utils);

  /****************** ADDRESS INFO API *******************/
  const addressInfoQuery = {
    orderNo: offerAcceptedDocuments.orderNo,
    from: addressInfoFrom,
    to: addressInfoTo,
  };
  const {
    data: addressInfoResponse,
    error: addressInfoError,
    isFetching: addressInfoFetching,
  } = useAddressInfoQuery(addressInfoQuery, { skip: skipAddressInfo });

  useDidMountEffect(() => {
    if (
      addressInfoFetching === false &&
      addressInfoResponse &&
      !addressInfoError
    ) {
      setSkipAddressInfo(true);
      setSkipAddContactPerson(false);
    }
  }, [addressInfoFetching]);

  /****************** ADD CONTACT PERSON API *******************/
  const contactPerson = {
    token,
    orderNo: offerAcceptedDocuments.orderNo,
    phone: actionNo === 0 ? selectedAgentFromList : "",
  };

  const {
    data: addContactPersonResponse,
    error: addContactPersonError,
    isFetching: addContactPersonFetching,
  } = useAddContactPersonToRequestQuery(contactPerson, {
    skip: skipAddContactPerson,
  });

  useDidMountEffect(() => {
    if (
      addContactPersonFetching === false &&
      addContactPersonResponse &&
      !addContactPersonError
    ) {
      setSkipAddContactPerson(true);

      const paymentMethodForm = new FormData();
      paymentMethodForm.set("token", token);
      paymentMethodForm.set("payment_method", selectedMethod);
      paymentMethodForm.set("transfer_slip", bankTransferImage);
      paymentMethodForm.set("orderNo", offerAcceptedDocuments.orderNo);
      paymentMethodForm.set("accountNo", 44566542132445);
      paymentMethodForm.set("point_of_payment", pointOfPayment);

      setFormData(paymentMethodForm);
      setSkipPayment(false);
    }
  }, [addContactPersonFetching]);

  /****************** PAYMENT API *******************/
  const {
    data: paymentResponse,
    error: paymentError,
    isFetching: paymentFetching,
  } = useUserAddPaymentMethodQuery(formData, {
    skip: skipPayment,
  });

  useDidMountEffect(() => {
    if (paymentFetching === false && paymentResponse && !paymentError) {
      setSkipPayment(true);
      if (offerAcceptedDocuments.nature === "Qoute") {
        setSkipUserAcceptQoute(false);
      } else if (offerAcceptedDocuments.nature === "Vendor Counter Offer") {
        setSkipUserAcceptVendorCounterQoute(false);
      } else {
        setSkipOrderAccept(false);
      }
    }
  }, [paymentFetching]);

  /******************    USER ACCEPT QOUTE API *******************/

  const userAcceptQouteQuery = {
    token: token,
    orderNo: offerAcceptedDocuments.orderNo,
    vendor_phone: offerAcceptedDocuments.phone
      ? offerAcceptedDocuments.phone
      : offerAcceptedDocuments.vendor_phone,
  };

  const {
    data: userAcceptQouteResponse,
    error: userAcceptQouteError,
    isFetching: userAcceptQouteFetching,
  } = useUserAcceptVenderQouteQuery(userAcceptQouteQuery, {
    skip: skipUserAcceptQoute,
  });

  useDidMountEffect(() => {
    if (
      userAcceptQouteFetching === false &&
      userAcceptQouteResponse &&
      !userAcceptQouteError
    ) {
      console.log('userAcceptQouteResponse -> ',userAcceptQouteResponse)

      if (userAcceptQouteResponse.status === true) {
        if (selectedMethod === "cod" || selectedMethod === "credit") {
          setSkipOrderAccept(false);
        } else if (selectedMethod === "bank") {
          notifySuccess(
            "Order created! waiting for admin approval on to approve your bank slip then your order will be activated"
          );
          dispatch(isFetchingSomethingAction(false));
          navigate("/requests");
        }
      } else {
        notifyError(userAcceptQouteResponse.error);
        dispatch(isFetchingSomethingAction(false));
      }

      setSkipUserAcceptQoute(true);
      // setSkipOfferReceivedRequests(false);
      // setSkipRejectedRequests(false);

      // setOfferReceivedDecisionDialog(false);
    }
  }, [userAcceptQouteFetching]);
  // /******************    USER ACCEPT VENDOR COUNTER QOUTE API *******************/
  const {
    data: userAcceptVendorCounterQouteResponse,
    error: userAcceptVendorCounterQouteError,
    isFetching: userAcceptVendorCounterQouteFetching,
  } = useUserAcceptVenderCounterQouteQuery(userAcceptQouteQuery, {
    skip: skipUserAcceptVendorCounterQoute,
  });

  useDidMountEffect(async () => {
    if (
      userAcceptVendorCounterQouteFetching === false &&
      userAcceptVendorCounterQouteResponse &&
      !userAcceptVendorCounterQouteError
    ) {
      console.log('userAcceptVendorCounterQouteResponse -> ',userAcceptVendorCounterQouteResponse)
      if (userAcceptVendorCounterQouteResponse.status === true) {
        if (selectedMethod === "cod" || selectedMethod === "credit") {
          setSkipOrderAccept(false);
        } else if (selectedMethod === "bank") {
          notifySuccess(
            "Order created! waiting for admin approval on to approve your bank slip then your order will be activated"
          );
          dispatch(isFetchingSomethingAction(false));
          navigate("/requests");
        }
      } else {
        // let message = await userAcceptVendorCounterQouteFetching.error;
        // console.log('userAcceptVendorCounterQouteFetching.error -> ',userAcceptVendorCounterQouteFetching.error)
        notifyError(userAcceptVendorCounterQouteResponse.error);
        dispatch(isFetchingSomethingAction(false));
      }
      setSkipUserAcceptVendorCounterQoute(true);
      // setSkipOfferReceivedRequests(false);
      // setSkipRejectedRequests(false);
      // setOfferReceivedDecisionDialog(false);
    }
  }, [userAcceptVendorCounterQouteFetching]);

  /******************    ORDER ACCEPT QOUTE API *******************/
  const orderAcceptQuery = {
    token: token,
    orderNo: offerAcceptedDocuments.orderNo,
    vendor_phone: offerAcceptedDocuments.phone
      ? offerAcceptedDocuments.phone
      : offerAcceptedDocuments.vendor_phone,
    payment_method: selectedMethod,
    point_of_payment: pointOfPayment,
  };

  const {
    data: orderAcceptResponse,
    error: orderAcceptError,
    isFetching: orderAcceptFetching,
  } = useOrderAcceptQuery(orderAcceptQuery, {
    skip: skipOrderAccept,
  });

  useDidMountEffect(() => {
    if (
      orderAcceptFetching === false &&
      orderAcceptResponse &&
      !orderAcceptError
    ) {
      setSkipOrderAccept(true);
      // setSkipOfferReceivedRequests(false);
      // setSkipRejectedRequests(false);
      dispatch(isFetchingSomethingAction(false));
      // setOfferReceivedDecisionDialog(false);
      if (orderAcceptResponse.status === true) {
        notifySuccess("Order Created");
        navigate("/requests");
      } else {
        notifyError(orderAcceptResponse.error);
      }
    }
  }, [orderAcceptFetching]);

  /****************** HANDLERS *******************/
  const bankSlipUploadHandleChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setBankTransferImagePreview(reader.result);
        setBankTransferImage(e.target.files[0]);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const selectAgentHandler = (e) => {
    e.preventDefault();

    if (actionNo === 0) {
      setOpenSelectFromExistingList(true);
    } else if (actionNo === 2) {
      dispatch(isFetchingSomethingAction(true));
      setSkipAddressInfo(false);
    }
  };

  return (
    <>
      {isFetchingSomething && <Loader />}
      <div className="paymentMethodPage">
        {offerAcceptedDocuments.payment_method !== "Credit" ? (
          <div className="paymentMethodContainer">
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Select Payment Method
            </Typography>

            <div className="paymentMethods">
              {paymentMethods.map((paymentMethod) => (
                <Button
                  disableElevation
                  className={`paymentMethodCard ${
                    selectMethodOpen &&
                    selectedMethod === paymentMethod.paymentMethod &&
                    "paymentMethodCardColored"
                  }`}
                  variant="contained"
                  onClick={() => {
                    setSelectedMethod(paymentMethod.paymentMethod);
                    setSelectMethodOpen(!selectMethodOpen);
                  }}
                >
                  {paymentMethod.icon}
                  <Typography> {paymentMethod.name}</Typography>
                  <Typography>
                    {paymentMethod.paymentMethod === "bank"
                      ? "You have to upload transfer slip to proceed"
                      : "Default payment method"}
                  </Typography>
                </Button>
              ))}
            </div>

            <Dialog
              scroll="body"
              maxWidth={"xl"}
              open={selectMethodOpen}
              keepMounted
              onClose={() => setSelectMethodOpen(!selectMethodOpen)}
              aria-describedby="alert-dialog-slide-description"
            >
              <form action="" onSubmit={selectAgentHandler}>
                <DialogTitle className="paymentMethodDialogTitle">
                  {selectedMethod === "bank"
                    ? "MERIBILTY BANK DETAILS"
                    : "Select point of payment "}
                </DialogTitle>

                {selectedMethod === "bank" && (
                  <DialogContent className="paymentMethodDialogContent">
                    <Typography>MERIBILTY(Pvt) LIMITED</Typography>
                    <Typography>05001007745092</Typography>
                    <Typography>BANK ALFALAH LIMITED</Typography>
                    {bankTransferImagePreview && (
                      <>
                        <img
                          src={bankTransferImagePreview}
                          alt="Bank Slip Image"
                        />
                        <Button
                          onClick={() => {
                            setBankTransferImagePreview("");
                            setBankTransferImage();
                          }}
                        >
                          Remove
                        </Button>
                      </>
                    )}
                  </DialogContent>
                )}

                <DialogActions className="paymentMethodDialogAction">
                  {selectedMethod === "bank" && (
                    <>
                      <Button
                        variant="contained"
                        size="large"
                        component="label"
                        startIcon={<ImageIcon />}

                        // onClick={() => {
                        //   setActiveSidebarItem("My Requests");
                        // }}
                      >
                        Upload Transfer Slip
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          onChange={bankSlipUploadHandleChange}
                        />
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<CameraAltIcon />}
                        onClick={() => setSelectMethodOpen(!selectMethodOpen)}
                      >
                        Upload By Camera
                      </Button>
                    </>
                  )}

                  {selectedMethod === "cod" && (
                    // <>
                    //   <Button
                    //     variant="contained"
                    //     size="large"
                    //     startIcon={<RadioButtonCheckedIcon />}
                    //     // onClick={() => {
                    //     //   setActiveSidebarItem("My Requests");
                    //     // }}
                    //   >
                    //     Origin
                    //   </Button>
                    //   <Button
                    //     variant="contained"
                    //     size="large"
                    //     startIcon={<PlaceIcon />}
                    //     onClick={() => setSelectMethodOpen(!selectMethodOpen)}
                    //   >
                    //     Destination
                    //   </Button>
                    // </>

                    <RadioGroup
                      className="pointOfPaymentContainer"
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue={"origin"}
                    >
                      <FormControlLabel
                        className={`pointOfPaymentButton ${
                          pointOfPayment === "origin" &&
                          "pointOfPaymentButtonActivated"
                        } `}
                        value="origin"
                        control={
                          <Radio
                            style={{
                              color:
                                pointOfPayment === "origin"
                                  ? "white"
                                  : "var(--Black)",
                            }}
                          />
                        }
                        label="Origin"
                        onClick={() => setPointOfPayment("origin")}
                      />
                      <FormControlLabel
                        className={`pointOfPaymentButton ${
                          pointOfPayment === "destination" &&
                          "pointOfPaymentButtonActivated"
                        } `}
                        value="destination"
                        control={
                          <Radio
                            style={{
                              color:
                                pointOfPayment === "destination"
                                  ? "white"
                                  : "var(--Black)",
                            }}
                          />
                        }
                        label="Destination"
                        onClick={() => setPointOfPayment("destination")}
                      />
                    </RadioGroup>
                  )}
                </DialogActions>

                <DialogTitle className="paymentMethodAgentDialogTitle">
                  Select contact person or clearing agents
                </DialogTitle>

                <DialogActions className="paymentMethodAgentDialogAction">
                  <>
                    <Button
                      size="large"
                      type="submit"
                      onClick={() => setActionNo(0)}
                    >
                      Select from existing list
                    </Button>

                    <Button
                      size="large"
                      type="submit"
                      onClick={() => setActionNo(0)}
                    >
                      invite new
                    </Button>

                    <Button
                      size="large"
                      type="submit"
                      onClick={() => setActionNo(2)}
                    >
                      I will manage myself
                    </Button>
                  </>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        ) : (
          <>
            <Dialog
              scroll="body"
              maxWidth={"xl"}
              open={
                offerAcceptedDocuments.payment_method === "Credit"
                  ? true
                  : false
              }
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <Button
                variant="contained"
                sx={{ margin: "1rem" }}
                onClick={() => navigate("/addressInfo")}
              >
                Back
              </Button>
              <form action="" onSubmit={selectAgentHandler}>
                <DialogTitle className="paymentMethodAgentDialogTitle">
                  Select contact person or clearing agents
                </DialogTitle>

                <DialogActions className="paymentMethodAgentDialogAction">
                  <>
                    <Button
                      size="large"
                      type="submit"
                      onClick={() => setActionNo(0)}
                    >
                      Select from existing list
                    </Button>

                    <Button
                      size="large"
                      type="submit"
                      onClick={() => setActionNo(0)}
                    >
                      invite now
                    </Button>

                    <Button
                      size="large"
                      type="submit"
                      onClick={() => setActionNo(2)}
                    >
                      I will manage myself
                    </Button>
                  </>
                </DialogActions>
              </form>
            </Dialog>
          </>
        )}
        <Dialog
          open={openSelectFromExistingList}
          fullWidth
          on
          onClose={() => setOpenSelectFromExistingList(false)}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            Select from existing list
          </DialogTitle>
          <DialogContent>
            <MyTeam
              gridXs={12}
              gridSm={6}
              gridMd={6}
              gridLg={6}
              selectedAgentFromList={selectedAgentFromList}
              setSelectedAgentFromList={setSelectedAgentFromList}
              fromPayment
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{ marginBottom: "1rem", marginRight: "1rem" }}
              onClick={() => {
                dispatch(isFetchingSomethingAction(true));
                setSkipAddressInfo(false);
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default PaymentMethod;
