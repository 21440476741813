import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import Logo from "../../images/Blue-Original.png";
import Fill1 from "../../images/Fill1.png";
import Fill2 from "../../images/Fill2.png";
import Fill3 from "../../images/Fill3.png";

import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { motion } from "framer-motion";

import ButtonComponent from "../../utils/ButtonComponent";

import Loader from "../../utils/Loader";

import "react-phone-input-2/lib/style.css";
import "./LoginSignup.css";
import { LARGESCREEN } from "../../utils/config";
import SignupForm from "./SignupForm";
import LoginForm from "./LoginForm";
import OtpDialogbox from "../../utils/OtpDialogbox";

const LoginSignup = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [showLoginFrom, setShowLoginForm] = useState(true);
  const [showOTPFloatingButton, setShowOTPFloatingButton] = useState(false);

  const [loginPhone, setLoginPhone] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  // const [loginPhone, setLoginPhone] = useState("+923000728550");
  // const [loginPassword, setLoginPassword] = useState("savul123");
  // const [loginPhone, setLoginPhone] = useState("+923363811091");
  // const [loginPassword, setLoginPassword] = useState("ikram123");
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const [accountCreated, setAccountCreated] = useState(false);

  const { isFetchingSomething } = useSelector((state) => state.utils);

  return (
    <>
      {isFetchingSomething && <Loader />}

      {showOtp && (
        <OtpDialogbox
          otp={otp}
          setOtp={setOtp}
          showOtp={showOtp}
          setShowOtp={setShowOtp}
          setShowLoginForm={setShowLoginForm}
          setAccountCreated={setAccountCreated}
        />
      )}

      {showOTPFloatingButton && !showOtp && (
        <ButtonComponent
          text={"Enter OTP"}
          className={"showOtpFloatingButton"}
          onClick={() => setShowOtp(true)}
        />
      )}

      <div className="userLoginFormPage">
        <img src={Logo} alt="Logo Image" />

        {/******************   FORMS *******************/}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          className="userLoginSignupForms"
        >
          {/******************  SIGNUP FORM *******************/}
          {!showLoginFrom && (
            <SignupForm
              name={name}
              setName={setName}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              showConfirmPassword={showConfirmPassword}
              setShowConfirmPassword={setShowConfirmPassword}
              setShowLoginForm={setShowLoginForm}
              setShowOtp={setShowOtp}
              setOtp={setOtp}
              setShowOTPFloatingButton={setShowOTPFloatingButton}
            />
          )}

          {/******************  LOGIN FORM *******************/}

          {showLoginFrom && (
            <LoginForm
              loginPhone={loginPhone}
              setLoginPhone={setLoginPhone}
              loginPassword={loginPassword}
              setLoginPassword={setLoginPassword}
              showLoginPassword={showLoginPassword}
              setShowLoginPassword={setShowLoginPassword}
              setShowLoginForm={setShowLoginForm}
              accountCreated={accountCreated}
            />
          )}
        </motion.div>

        {/******************  DOWNLOAD MOBILE APPLICATION *******************/}
        <motion.div
          initial={{
            y: window.innerWidth >= LARGESCREEN ? -200 : 200,
            opacity: 0,
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="downloadMobileApp"
        >
          <Typography>Download App</Typography>

          <div className="downloadMobileAppIcons">
            <a
              href="https://apps.apple.com/pk/app/meribilty-user/id6443484347"
              target="_blank"
            >
              <div className="downloadForText">
                <AppleIcon className="appleIcon" />
                <Typography>Download For Iphone</Typography>
              </div>
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.meribilty.user"
              target="_blank"
            >
              <div className="downloadForText">
                <AndroidIcon className="androidIcon" />
                <Typography>Download For Android</Typography>
              </div>
            </a>
          </div>
        </motion.div>
      </div>

      <div className="loginBackgroundImage">
        <img src={Fill1} alt="" />
        <img src={Fill2} alt="" />
        <img src={Fill3} alt="" />
      </div>
    </>
  );
};

export default LoginSignup;
