import React from "react";
import "./ButtonComponent.css";

const ButtonComponent = ({ className, text, type, disabled, onClick }) => {
  return (
    <>
      <button
        type={type}
        className={`buttonMain ${className}`}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
    </>
  );
};

export default ButtonComponent;
