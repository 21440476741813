export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const paginationHandlerFunction = (
  currentPage,
  completeData,
  setCurrentData,

  perPage
) => {
  const indexOfLastPost = currentPage * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  setCurrentData(completeData?.slice(indexOfFirstPost, indexOfLastPost));
};

export const moreDetailsData = async (
  myRequest,
  statusText,
  createQouteCounter,
  setMyOrderDialogDetailsContext,
  setDirectionsResponse
) => {
  let grossWeight = 0;
  let materials = [];
  let numOfVehicles = 0;
  let numOfLoadings = 0;
  let numOfUnLoadings = 0;

  if (myRequest.bilties && myRequest.bilty) {
    for (let i = 0; i < myRequest.bilties.length; i++) {
      if (myRequest.bilties[i].weight) {
        let temp = myRequest.bilties[i].weight;
        temp = temp.substring(temp.indexOf("-") + 1);
        grossWeight += parseInt(temp);
        materials.push(myRequest.bilties[i].material);
        if (i === myRequest.bilties.length - 1) {
          materials = [...new Set(materials)].join(", ");
        }
      }

      if (myRequest.bilties[i].type === "vehicle") {
        numOfVehicles += 1;
      }
      if (
        myRequest.bilties[i].type === "loading/unloading" &&
        myRequest.bilties[i].loading_options
      ) {
        numOfLoadings = myRequest.bilties[i].loading_options.length;
      }
      if (
        myRequest.bilties[i].type === "loading/unloading" &&
        myRequest.bilties[i].unloading_options
      ) {
        numOfUnLoadings = myRequest.bilties[i].unloading_options.length;
      }
    }
  } else if (myRequest.bilties) {
    for (let i = 0; i < myRequest.bilties.length; i++) {
      if (myRequest.bilties[i].weight) {
        let temp = myRequest.bilties[i].weight;
        temp = temp.substring(temp.indexOf("-") + 1);
        grossWeight += parseInt(temp);
        materials.push(myRequest.bilties[i].material);
        if (i === myRequest.bilties.length - 1) {
          materials = [...new Set(materials)].join(", ");
        }
      }

      if (myRequest.bilties[i].type === "vehicle") {
        numOfVehicles += 1;
      }
      if (
        myRequest.bilties[i].type === "loading/unloading" &&
        myRequest.bilties[i].loading_options
      ) {
        numOfLoadings = myRequest.bilties[i].loading_options.length;
      }
      if (
        myRequest.bilties[i].type === "loading/unloading" &&
        myRequest.bilties[i].unloading_options
      ) {
        numOfUnLoadings = myRequest.bilties[i].unloading_options.length;
      }
    }
  } else if (myRequest.bilty) {
    for (let i = 0; i < myRequest.bilty.length; i++) {
      if (myRequest.bilty[i].weight) {
        let temp = myRequest.bilty[i].weight;
        temp = temp.substring(temp.indexOf("-") + 1);
        grossWeight += parseInt(temp);
        materials.push(myRequest.bilty[i].material);
        if (i === myRequest.bilty.length - 1) {
          materials = [...new Set(materials)].join(", ");
        }
      }

      if (myRequest.bilty[i].type === "vehicle") {
        numOfVehicles += 1;
      }
      if (
        myRequest.bilty[i].type === "loading/unloading" &&
        myRequest.bilty[i].loading_options
      ) {
        numOfLoadings = myRequest.bilty[i].loading_options.length;
      }
      if (
        myRequest.bilty[i].type === "loading/unloading" &&
        myRequest.bilty[i].unloading_options
      ) {
        numOfUnLoadings = myRequest.bilty[i].unloading_options.length;
      }
    }
  }

  const myOrderDialogDetailsContext = [
    {
      name: "Type",
      value: myRequest.request_type === "transit" ? "Transit" : "Nationwide",
    },
    { name: "Status", value: statusText },
    { name: "Created at", value: createQouteCounter.dateTime },
    { name: "Origin", value: myRequest.originAddress },
    { name: "Destination", value: myRequest.destinationAddress },
    {
      name: "Container return address",
      value: myRequest.containerReturnAddress,
    },
    { name: "Gross weight", value: `${grossWeight} Tons` },
    {
      name: "Number of vehicles",
      value: numOfVehicles,
    },
    {
      name: "Number of loading",
      value: numOfLoadings,
    },
    {
      name: "Number of unloading",
      value: numOfUnLoadings,
    },
    { name: "Materials", value: materials },
    { name: "Pickup time", value: createQouteCounter.dateTime },
  ];

  setMyOrderDialogDetailsContext(myOrderDialogDetailsContext);

  /******************    GOOGLE MAP *******************/
  // eslint-disable-next-line no-undef
  const directionsService = new google.maps.DirectionsService();
  const results = await directionsService.route({
    // eslint-disable-next-line no-undef
    origin: new google.maps.LatLng(myRequest.orgLat, myRequest.orgLng),
    // eslint-disable-next-line no-undef
    destination: new google.maps.LatLng(myRequest.desLat, myRequest.desLng),
    // eslint-disable-next-line no-undef
    travelMode: google.maps.TravelMode.DRIVING,
  });
  setDirectionsResponse(results);
};

//setup before functions
let typingTimer; //timer identifier
let doneTypingInterval = 1000; //time in ms (.5 seconds)

export const delaySkip = (id, doneTyping) => {
  let input = document.getElementById(id);

  //on keyup, start the countdown
  if (input) {
    input.addEventListener("keyup", () => {
      clearTimeout(typingTimer);
      if (input.value) {
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
      }
    });
  }
};

export const dateTimeOrder = (myRequest, setCreateQouteCounter) => {
  let createQouteCounterDateTime = "";
  let createQouteCounterDateText = "";
  if (myRequest.order_completed_on) {
    createQouteCounterDateTime = myRequest.order_completed_on.split(",");
    createQouteCounterDateText = "Completed";
  } else if (myRequest.order_accepted_on) {
    createQouteCounterDateTime = myRequest.order_accepted_on.split(",");
    createQouteCounterDateText = "Accepted";
  } else if (myRequest.qoute_accepted_on) {
    createQouteCounterDateTime = myRequest.qoute_accepted_on.split(",");
    createQouteCounterDateText = "Accepted";
  } else if (myRequest.qoute_rejected_on) {
    createQouteCounterDateTime = myRequest.qoute_rejected_on.split(",");
    createQouteCounterDateText = "Rejected";
  } else if (myRequest.user_counter_rejected_on) {
    createQouteCounterDateTime = myRequest.user_counter_rejected_on.split(",");
    createQouteCounterDateText = "Rejected";
  } else if (myRequest.user_counter_accepted_on) {
    createQouteCounterDateTime = myRequest.user_counter_accepted_on.split(",");
    createQouteCounterDateText = "Countered";
  } else if (myRequest.vendor_countered_on) {
    createQouteCounterDateTime = myRequest.vendor_countered_on.split(",");
    createQouteCounterDateText = "Countered";
  } else if (myRequest.counteredAt) {
    createQouteCounterDateTime = myRequest.counteredAt.split(",");
    createQouteCounterDateText = "Countered";
  } else if (myRequest.qoutedAt) {
    createQouteCounterDateTime = myRequest.qoutedAt.split(",");
    createQouteCounterDateText = "Qouted";
  } else if (myRequest.createdAt) {
    createQouteCounterDateTime = myRequest.createdAt.split(",");
    createQouteCounterDateText = "Created";
  }

  setCreateQouteCounter({
    text: createQouteCounterDateText,
    dateTime: createQouteCounterDateTime,
  });
};
