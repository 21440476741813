import React, { useEffect, useState } from "react";
import "./Notification.css";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BiCircle } from "react-icons/bi";
import dayjs from "dayjs";

import {
  showReadUnReadNotificationsAction,
  disableSingleUnReadNotificationAction,
  setAllNotificationsAction,
  showNotificationAction,
  disableSingleUnReadNotificationRouteAction,
  scrollingNotificationsDataAction,
} from "../../sliceReducers/utilsSlice";
import APIhandler from "../../utils/APIhandler";
import { userApi, useReadNotificationQuery } from "../../services/userService";
import { notifyError } from "../../utils/notifyMessage";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import useDidMountEffect from "../../utils/useDidMountEffect";
// import DisableSingleUnReadNotificationAPI from "./DisableSingleUnReadNotificationAPI";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Notification = ({
  position,
  right,
  top,
  width,
  mobileWidth,
  maxHeight,
  overflowY,
  seeAllButton,
  mobileRight,
  mobileTop,
  data,
  allNotificationsRouteData,
}) => {
  const navigate = useNavigate();

  const {
    allNotifications,
    allNotificationsRouteFinalData,
    allNotificationsRouteData: allNotificationsRouteDataLength,
  } = useSelector((state) => state.utils);

  const { token } = useSelector((state) => state.userData);
  const [notificationId, setNotificationId] = useState();
  const dispatch = useDispatch();
  const locat = useLocation().pathname;

  const [skipReadSingleNotification, setSkipReadSingleNotification] =
    useState(true);

  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);

  let tokenNotificationId = {
    notificationID: notificationId,
    token,
  };

  const {
    data: readNotificationResponse,
    error: readNotificationError,
    isFetching: readNotificationFetching,
  } = useReadNotificationQuery(tokenNotificationId, {
    skip: skipReadSingleNotification,
  });

  const notificationDecision = (notification) => {
    if (notification?.routes === "singleRequest") {
      navigate(`/singleRequest/${notification?.orderNo}`);
    } else if (notification?.routes === "requests") {
      navigate("/requests");
    }
  };

  const fetchNotifications = () => {
    if (
      allNotificationsRouteFinalData.length <
      allNotificationsRouteDataLength.length
    ) {
      setHasNext(true);
      setPage(page + 1);
    } else if (
      allNotificationsRouteFinalData.length ==
      allNotificationsRouteDataLength.length
    ) {
      setHasNext(false);
    }
  };

  useEffect(() => {
    if (allNotificationsRouteData) {
      dispatch(scrollingNotificationsDataAction(page));
    }
  }, [page]);

  useEffect(() => {
    if (
      locat === "/notifications" &&
      allNotificationsRouteFinalData.length > 0
    ) {
      dispatch(scrollingNotificationsDataAction(1));
    }
  }, [locat]);

  return (
    <>
      {/* <DisableSingleUnReadNotificationAPI
        skipReadSingleNotification={
          skipReadSingleNotification
        }
        setSkipReadSingleNotification={
          setSkipReadSingleNotification
        }
        notificationId={notificationId}
      /> */}

      <APIhandler
        responseData={readNotificationResponse}
        serverError={readNotificationError}
        isFetching={readNotificationFetching}
        onStatusTrue={() => {
          dispatch(userApi.endpoints.getNotifications.initiate(token));
          setSkipReadSingleNotification(true);
        }}
        onStatusFalse={() => {
          notifyError("Not able to fetch loading options");
          setSkipReadSingleNotification(true);
        }}
      />

      <Box
        animate={{ opacity: [0, 1], transition: { duration: 0.2 } }}
        sx={{
          position: position,
          right: { xs: mobileRight, md: right },
          top: { xs: mobileTop, md: top },
          width: { xs: mobileWidth, md: width },
          maxHeight: maxHeight,
          ":hover": {
            overflowY: overflowY,
          },
        }}
        className="notificationContainer"
        id="notification"
        onClick={(e) => e.stopPropagation()}
      >
        <Stack>
          <Typography
            sx={{
              color: "var(--primary)",
              font: "600 1.5rem Poppins",
              marginBottom: "1rem",
              display: { xs: "none", md: "inherit" },
            }}
          >
            Notifications
          </Typography>

          <Stack spacing={1} direction="row">
            <Button
              style={{ backgroundColor: allNotifications && "#62cafa36" }}
              onClick={() => {
                if (!allNotifications) {
                  dispatch(setAllNotificationsAction(true));
                  dispatch(scrollingNotificationsDataAction(1));
                  setPage(1);
                  // dispatch(
                  //   showReadUnReadNotificationsAction({ all: true, page })
                  // );
                }
              }}
              className="allUnreadNotificationButton"
            >
              All
            </Button>
            <Button
              style={{ backgroundColor: !allNotifications && "#62cafa36" }}
              onClick={() => {
                if (allNotifications) {
                  // dispatch(
                  //   showReadUnReadNotificationsAction({ all: false, page })
                  // );
                  dispatch(setAllNotificationsAction(false));
                  dispatch(scrollingNotificationsDataAction(1));
                  setPage(1);
                }
              }}
              className="allUnreadNotificationButton"
            >
              Unread
            </Button>
          </Stack>

          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Typography
              sx={{ color: "var(--Black)", font: "600 1.2rem Roboto" }}
            >
              Earlier
            </Typography>
            {seeAllButton && (
              <Button
                sx={{
                  backgroundColor: !allNotifications && "#62cafa36",
                }}
                onClick={() => {
                  navigate("/notifications");
                  dispatch(showNotificationAction(false));
                }}
                className="allUnreadNotificationButton"
              >
                See all
              </Button>
            )}
          </Stack>
        </Stack>

        <div className="allUnreadNotificationButtonBox">
          {allNotificationsRouteFinalData.length > 0 ? (
            <InfiniteScroll
              //  className="containerH2"
              dataLength={allNotificationsRouteFinalData.length} //This is important field to render the next data
              next={fetchNotifications}
              hasMore={
                allNotificationsRouteFinalData.length <
                  allNotificationsRouteDataLength.length && hasNext
              }
              // loader={
              //   finalProducts.length > 0 ? (
              //     <LoaderLoading />
              //   ) : (
              //     <h1 style={{ paddingTop: "5rem", font: "400 1.5rem Roboto" }}>
              //       LOADING...
              //     </h1>
              //   )
              // }
              endMessage={
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", padding: "1rem" }}
                >
                  No More Notifications
                </Typography>
              }
            >
              <List>
                {data.map((notification, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        sx={{ display: notification?.read && "none" }}
                      >
                        <CircleIcon
                          sx={{ color: "var(--primary)", fontSize: "1rem" }}
                        />
                      </IconButton>
                    }
                    onClick={() => notificationDecision(notification)}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (!notification?.read) {
                          if (allNotificationsRouteData) {
                            dispatch(
                              disableSingleUnReadNotificationRouteAction(
                                notification.id
                              )
                            );
                          } else {
                            dispatch(
                              disableSingleUnReadNotificationAction(
                                notification.id
                              )
                            );
                          }
                          setNotificationId(notification.id);
                          // setSkipReadSingleNotification(false);
                        }
                      }}
                      sx={{ borderRadius: "10px", alignItems: "flex-start" }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        <Avatar
                          sx={{
                            backgroundColor: "var(--primary)",
                            marginTop: "0.6rem",
                            marginRight: "0.5rem",
                          }}
                        >
                          A
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: "var(--Black)",
                        }}
                        primaryTypographyProps={{
                          fontWeight: "600",
                        }}
                        primary={"Admin"}
                        secondary={
                          <Stack sx={{ fontSize: "0.9rem" }}>
                            <Box sx={{ fontWeight: "600" }}>
                              {notification?.title}
                            </Box>
                            <Box>{notification?.body}</Box>
                            <Box
                              sx={{
                                color: "var(--primary)",
                                fontWeight: "600",
                                fontFamily: "Roboto",
                              }}
                            >
                              {dayjs(notification?.created).fromNow()}
                            </Box>
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </InfiniteScroll>
          ) : (
            <Typography
              sx={{
                color: "var(--Black)",
                font: "500 1.5rem Roboto",
                p: "1rem 0",
                textAlign: "center",
              }}
            >
              No Notifications
            </Typography>
          )}
        </div>
      </Box>
    </>
  );
};

export default Notification;
