import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddContactPersonQuery,
  useEditContactPersonQuery,
} from "../../services/userService";
import { updateContactPersonsAction } from "../../sliceReducers/userSlice";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import APIhandler from "../../utils/APIhandler";
import Loader from "../../utils/Loader";
import { notifyError, notifySuccess } from "../../utils/notifyMessage";
import useDidMountEffect from "../../utils/useDidMountEffect";

const AddTeamMemberDialog = ({
  refetchGetContactPersons,
  openAddTeamMember,
  setOpenAddTeamMember,
  addOrEditTeamMember,
  dataToBeEdited,
}) => {
  const dispatch = useDispatch();

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [skipAddContactPerson, setSkipAddContactPerson] = useState(true);
  const [skipEditContactPerson, setSkipEditContactPerson] = useState(true);

  const { token, contactPersons, user } = useSelector(
    (state) => state.userData
  );

  let addContactPersonQuery = {
    token,
    fullname,
    email,
    phone,
  };

  const {
    data: addContactPersonResponse,
    isFetching: addContactPersonFetching,
    error: addContactPersonError,
  } = useAddContactPersonQuery(addContactPersonQuery, {
    skip: skipAddContactPerson,
  });

  let editContactPersonQuery = {
    token,
    fullname,
    email,
    agent_phone: phone,
    user_phone: user?.phone,
  };

  const {
    data: editContactPersonResponse,
    isFetching: editContactPersonFetching,
    error: editContactPersonError,
  } = useEditContactPersonQuery(editContactPersonQuery, {
    skip: skipEditContactPerson,
  });

  const addContactPersonHandler = () => {
    if (fullname && email && phone) {
      if (addOrEditTeamMember === 0) {
        setSkipAddContactPerson(false);
      } else if (addOrEditTeamMember === 1) {
        setSkipEditContactPerson(false);
      }

      dispatch(isFetchingSomethingAction(true));
    } else {
      notifyError("Fill all fields");
    }
  };

  useDidMountEffect(() => {
    if (
      !addContactPersonFetching &&
      addContactPersonResponse?.status === false
    ) {
      notifyError(addContactPersonResponse.error);
    }
  }, [addContactPersonFetching]);

  useEffect(() => {
    if (addOrEditTeamMember === 1) {
      setFullname(dataToBeEdited.fullname);
      setEmail(dataToBeEdited.email);
      setPhone(dataToBeEdited.phone);
    }
  }, []);

  return (
    <>
      <APIhandler
        responseData={addContactPersonResponse}
        isFetching={addContactPersonFetching}
        serverError={addContactPersonError}
        onStatusTrue={() => {
          //   dispatch(updateContactPersonsAction(addContactPersonResponse.data));
          refetchGetContactPersons();
          notifySuccess(addContactPersonResponse.message);
          setSkipAddContactPerson(true);
          // dispatch(isFetchingSomethingAction(false));
        }}
        onStatusFalse={() => {
          refetchGetContactPersons();
          setSkipAddContactPerson(true);
          dispatch(isFetchingSomethingAction(false));
        }}
        onServerError={() => {
          refetchGetContactPersons();
          setSkipAddContactPerson(true);
          dispatch(isFetchingSomethingAction(false));
        }}
      />

      <APIhandler
        responseData={editContactPersonResponse}
        isFetching={editContactPersonFetching}
        serverError={editContactPersonError}
        onStatusTrue={() => {
          //   dispatch(updateContactPersonsAction(addContactPersonResponse.data));
          refetchGetContactPersons();
          notifySuccess(editContactPersonResponse.message);
          setSkipAddContactPerson(true);
          // dispatch(isFetchingSomethingAction(false));
        }}
        onStatusFalse={() => {
          setSkipAddContactPerson(true);
          dispatch(isFetchingSomethingAction(false));
        }}
        onServerError={() => {
          setSkipAddContactPerson(true);
          dispatch(isFetchingSomethingAction(false));
        }}
      />

      <Dialog
        open={openAddTeamMember}
        onClose={() => setOpenAddTeamMember(false)}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Add a team member
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Stack spacing={0.5}>
              <Typography>Name</Typography>
              <TextField
                label="Enter agent's name"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </Stack>
            <Stack spacing={0.5}>
              <Typography>Email</Typography>
              <TextField
                label="Enter agent's Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>

            <Stack spacing={0.5}>
              <Typography>Phone</Typography>
              <PhoneInput
                className="addTeam"
                placeholder="Enter phone number"
                country={"pk"}
                value={phone}
                countryCodeEditable={false}
                onChange={(e) => setPhone("+" + e)}
              />
            </Stack>

            <Button onClick={addContactPersonHandler} variant="contained">
              Add
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddTeamMemberDialog;
