import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRemoveContactPersonQuery } from "../../services/userService";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import APIhandler from "../../utils/APIhandler";
import { notifySuccess } from "../../utils/notifyMessage";

const DeleteTeamMemberDialog = ({
  openDeleteTeamMember,
  setOpenDeleteTeamMember,
  dataToBeEdited,
  refetchGetContactPersons,
}) => {
  const dispatch = useDispatch();

  const [skipRemoveContact, setSkipRemoveContact] = useState(true);

  const { token } = useSelector((state) => state.userData);

  let removeContactPersonQuery = {
    token,
    agent_phone: dataToBeEdited.phone,
  };

  const {
    data: removeContactPersonResponse,
    isFetching: removeContactPersonFetching,
    error: removeContactPersonError,
  } = useRemoveContactPersonQuery(removeContactPersonQuery, {
    skip: skipRemoveContact,
  });

  const deleteContactPersonHandler = () => {
    setSkipRemoveContact(false);
    dispatch(isFetchingSomethingAction(true));
  };

  return (
    <>
      <APIhandler
        responseData={removeContactPersonResponse}
        isFetching={removeContactPersonFetching}
        serverError={removeContactPersonError}
        onStatusTrue={() => {
          //   dispatch(updateContactPersonsAction(addContactPersonResponse.data));
          refetchGetContactPersons();
          notifySuccess(removeContactPersonResponse.message);
          setSkipRemoveContact(true);
          // dispatch(isFetchingSomethingAction(false));
        }}
        onStatusFalse={() => {
          setSkipRemoveContact(true);
          dispatch(isFetchingSomethingAction(false));
        }}
        onServerError={() => {
          setSkipRemoveContact(true);
          dispatch(isFetchingSomethingAction(false));
        }}
      />

      <Dialog
        open={openDeleteTeamMember}
        onClose={() => setOpenDeleteTeamMember(false)}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Are your sure you want to delete {dataToBeEdited.phone} agent
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={deleteContactPersonHandler}
            variant="contained"
            sx={{ alignSelf: "flex-end", margin: "1rem" }}
          >
            Delete
          </Button>

          <Button
            onClick={() => setOpenDeleteTeamMember(false)}
            sx={{ alignSelf: "flex-end", margin: "1rem" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteTeamMemberDialog;
