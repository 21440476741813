import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import Bilties from "../../utils/Bilties";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MoreDetailsDialogBox = ({
  moreDetailsOpen,
  setMoreDetailsOpen,
  statusColor,
  statusText,
  myRequest,
  myOrderDialogDetailsContext,
  directionsResponse,
}) => {
  const { isLoaded } = useSelector((state) => state.utils);

  return (
    <Dialog
      maxWidth={"xl"}
      open={moreDetailsOpen}
      TransitionComponent={Transition}
      keepMounted
      // scroll="body"
      onClose={() => {
        setMoreDetailsOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        ".MuiPaper-root": { margin: "1rem !important", width: "100%" },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: statusColor,
          color: "white",
          textAlign: "center",
        }}
      >
        {statusText} of order #{myRequest.orderNo}
      </DialogTitle>

      <DialogContent className="myOrderDetailsDialogBox">
        {myOrderDialogDetailsContext.map((myOrderDialogDetails, index) => (
          <Typography
            key={index}
            className="myOrderMoreDetails"
            sx={{ b: { fontWeight: "600" } }}
          >
            <b>{myOrderDialogDetails.name}: </b>
            {myOrderDialogDetails.value}
          </Typography>
        ))}

        <Stack
          style={{
            width: "100%",
            padding: "1rem 0",
            // boxSizing: "border-box",
          }}
        >
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "30vh" }}
              zoom={15}
              disableDefaultUI={true}
            >
              <>
                {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}
              </>
            </GoogleMap>
          ) : (
            <></>
          )}
        </Stack>
            {console.log("bilty",myRequest.bilty)}
            {console.log("bilties",myRequest.bilties)}
            {myRequest.bilty && myRequest.bilty.length > 0
  ? myRequest.bilty.map((vehicle, vehicleIndex) => (
      <Bilties
        key={vehicleIndex}
        vehicle={vehicle}
        vehicleIndex={vehicleIndex}
      />
    ))
  : myRequest.bilties && myRequest.bilties.length > 0
      ? myRequest.bilties.map((vehicle, vehicleIndex) => (
          <Bilties
            key={vehicleIndex}
            vehicle={vehicle}
            vehicleIndex={vehicleIndex}
          />
        ))
      : myRequest.bilty && myRequest.bilty.length > 0
      ? myRequest.bilty.map((vehicle, vehicleIndex) => (
          <Bilties
            key={vehicleIndex}
            vehicle={vehicle}
            vehicleIndex={vehicleIndex}
          />
        ))
      : null
}
      </DialogContent>

      <DialogActions sx={{ borderTop: "1px solid var(--borderGrey)" }}>
        <Button
          className="myOrderDetailsDialogBoxClose"
          onClick={() => {
            setMoreDetailsOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoreDetailsDialogBox;
