import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Rating,
  Slide,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { dateTimeOrder, moreDetailsData } from "../../utils/commonFunctions";
import MoreDetailsDialogBox from "../MyRequests/MoreDetailsDialogBox";
import MyRequestDeliveryCard from "../MyRequests/MyRequestDeliveryCard";
import ManageTrackBilties from "./ManageTrackBilties";
import SubmitRatingExperience from "./SubmitRatingExperience";
import "../MyRequests/MyRequests.css";
import "./MyDeliveryCard.css";
import { useDispatch, useSelector } from "react-redux";
import { updateDriverStatusAction } from "../../sliceReducers/utilsSlice";
import OrderInvoiceDialog from "./OrderInvoiceDialog";
import { useGetOrderInvoiceQuery } from "../../services/userService";
import APIhandler from "../../utils/APIhandler";
import PdfDownloader from "../PdfDownloader";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../../utils/Loader";

const MyDeliveryCard = ({
  myRequest,
  index,
  statusText,
  statusColor,
  status,
  findingRates,
  orderCompleted,
  setSkipCompletedOrders,
}) => {
  const dispatch = useDispatch();
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);

  const [openAdditionalInformation, setOpenAdditionalInformation] =
    useState(false);

  const [createQouteCounter, setCreateQouteCounter] = useState({
    dateTime: "",
    text: "",
  });

  const pickUpTime = new Date(Date.parse(myRequest.date)).toLocaleString();

  const [manageTrackBiltiesOpen, setManageTrackBiltiesOpen] = useState(false);

  const [userRatingOpen, setUserRatingOpen] = useState(false);

  const [userExperienceOpen, setUserExperienceOpen] = useState(false);

  const [myOrderDialogDetailsContext, setMyOrderDialogDetailsContext] =
    useState([]);

  const [numberOfVehicles, setNumberOfVehicles] = useState(0);

  const [openOrderInvoice, setOpenOrderInvoice] = useState(false);

  const [invoice, setInvoice] = useState();

  const { driverStatusOrderNo } = useSelector((state) => state.utils);

  const { token } = useSelector((state) => state.userData);

  const [skipGetOrderInvoice, setSkipGetOrderInvoice] = useState(true);

  let orderInvoiceQuery = {
    token,
    orderNo: myRequest.orderNo,
  };

  const {
    data: getOrderInvoiceResponse,
    isFetching: getOrderInvoiceFetching,
    error: getOrderInvoiceError,
  } = useGetOrderInvoiceQuery(orderInvoiceQuery, { skip: skipGetOrderInvoice });

  const handleMoreDetailsOpen = () => {
    setMoreDetailsOpen(true);
  };

  useDidMountEffect(() => {
    if (moreDetailsOpen) {
      moreDetailsData(
        myRequest,
        statusText,
        createQouteCounter,
        setMyOrderDialogDetailsContext,
        setDirectionsResponse
      );
    }
  }, [moreDetailsOpen]);

  useEffect(() => {
    dateTimeOrder(myRequest, setCreateQouteCounter);
  }, []);

  useEffect(() => {
    let numOfVehicles = 0;
    if (myRequest.bilties && myRequest.bilty) {
      for (let i = 0; i < myRequest.bilties.length; i++) {
        if (myRequest.bilties[i].type === "vehicle") {
          numOfVehicles += 1;
        }
      }
    } else if (myRequest.bilties) {
      for (let i = 0; i < myRequest.bilties.length; i++) {
        if (myRequest.bilties[i].type === "vehicle") {
          numOfVehicles += 1;
        }
      }
    } else {
      for (let i = 0; i < myRequest.bilty.length; i++) {
        if (myRequest.bilty[i].type === "vehicle") {
          numOfVehicles += 1;
        }
      }
    }
    setNumberOfVehicles(numOfVehicles);
  }, []);

  useEffect(() => {
    if (driverStatusOrderNo && myRequest.orderNo === driverStatusOrderNo) {
      setManageTrackBiltiesOpen(true);
    }
  }, [driverStatusOrderNo]);

  return (
    <>
      {getOrderInvoiceFetching && <Loader />}
      <APIhandler
        responseData={getOrderInvoiceResponse}
        isFetching={getOrderInvoiceFetching}
        serverError={getOrderInvoiceError}
        onStatusTrue={() => {
          setInvoice({
            orderInvoice: getOrderInvoiceResponse?.data?.user,
          });
          setOpenOrderInvoice(true);
          setSkipGetOrderInvoice(true);
        }}
        onStatusFalse={() => {}}
        onServerError={() => {}}
      />

      <div className="myOrderCard">
        <MyRequestDeliveryCard
          statusColor={statusColor}
          statusText={statusText}
          createQouteCounter={createQouteCounter}
          myRequest={myRequest}
          numberOfVehicles={numberOfVehicles}
          pickUpTime={pickUpTime}
          handleMoreDetailsOpen={handleMoreDetailsOpen}
          status={status}
          findingRates={findingRates}
          openAdditionalInformation={openAdditionalInformation}
          setOpenAdditionalInformation={setOpenAdditionalInformation}
        />

        {moreDetailsOpen && (
          <MoreDetailsDialogBox
            moreDetailsOpen={moreDetailsOpen}
            setMoreDetailsOpen={setMoreDetailsOpen}
            myRequest={myRequest}
            statusColor={statusColor}
            statusText={statusText}
            myOrderDialogDetailsContext={myOrderDialogDetailsContext}
            directionsResponse={directionsResponse}
          />
        )}

        {/******************  MANAGE AND TRACK BILTIES *******************/}

        {!orderCompleted && (
          <div
            className="myOrderStatusReceived"
            style={{
              backgroundColor: statusColor,
              border: "1px solid var(--Black)",
            }}
          >
            <Button
              style={{
                backgroundColor: statusColor,
                borderRadius: "0px 0px 5px 5px",
                // padding: "1rem 0",
                fontSize: "0.9rem",
              }}
              onClick={() => setManageTrackBiltiesOpen(true)}
            >
              Manage/Track Bilties
            </Button>
          </div>
        )}

        {/* {!orderCompleted && manageTrackBiltiesOpen && (
          <ManageTrackBilties
            statusColor={statusColor}
            myRequest={myRequest}
            manageTrackBiltiesOpen={manageTrackBiltiesOpen}
            setManageTrackBiltiesOpen={setManageTrackBiltiesOpen}
          />
        )} */}
        {manageTrackBiltiesOpen && (
          <ManageTrackBilties
            statusColor={statusColor}
            myRequest={myRequest}
            manageTrackBiltiesOpen={manageTrackBiltiesOpen}
            setManageTrackBiltiesOpen={setManageTrackBiltiesOpen}
          />
        )}

        {/******************  USER RATING STARS *******************/}
        {orderCompleted && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Rating
                value={myRequest.user_ratings ? myRequest.user_ratings : 0}
                readOnly
                size="medium"
                precision={0.5}
              />
            </div>

            <Typography
              style={{
                font: "400 1rem Poppins",
                textAlign: "center",
                paddingBottom: "0.5rem",
                textDecoration: "underline",
                height: "1rem",
                cursor: myRequest.user_experience && "pointer",
              }}
              onClick={() => {
                myRequest.user_experience && setUserExperienceOpen(true);
              }}
            >
              {myRequest.user_experience ? "See user experience" : ""}
            </Typography>
          </>
        )}

        {/******************  ORDER COMPLETED ACTIONS *******************/}
        {orderCompleted && (
          <div
            className="myCompletedOrdersButtons"
            // style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          >
            <Button
              style={{ borderRight: "1px solid var(--Black)" }}
              onClick={() => {
                setSkipGetOrderInvoice(false);
              }}
            >
              {"Invoice"}
            </Button>
            <Button
              style={{
                borderRadius: myRequest.user_ratings && "0px 0px 5px 0px",
              }}
              onClick={() => setManageTrackBiltiesOpen(true)}
            >
              {"Manage"}
            </Button>
            {!myRequest.user_ratings && (
              <Button
                style={{ borderLeft: "1px solid var(--Black)" }}
                onClick={() => {
                  setUserRatingOpen(true);
                }}
              >
                {"Rating"}
              </Button>
            )}
          </div>
        )}

        {openOrderInvoice && (
          <Dialog
            maxWidth="xl"
            sx={{
              ".MuiPaper-root": {
                margin: "1rem",
              },
            }}
            keepMounted
            open={openOrderInvoice}
            onClose={() => setOpenOrderInvoice(false)}
          >
            <DialogContent>
              <OrderInvoiceDialog
                invoice={invoice}
                orderInvoiceFetching={false}
                setOpenBiltyOrder={setOpenOrderInvoice}
              />
            </DialogContent>

            <DialogActions sx={{ borderTop: "1px solid var(--borderGrey)" }}>
              <PdfDownloader
                text={"Download Invoice"}
                startIcon={<DownloadIcon />}
                orderInvoice={invoice}
                invoiceFetching={false}
              />
            </DialogActions>
          </Dialog>
        )}

        {/*  ****************** SUBMIT USER RATING AND EXPERIENCE ****************** */}
        {(userRatingOpen || userExperienceOpen) && (
          <SubmitRatingExperience
            userRatingOpen={userRatingOpen}
            setUserRatingOpen={setUserRatingOpen}
            myRequest={myRequest}
            setSkipCompletedOrders={setSkipCompletedOrders}
            userExperienceOpen={userExperienceOpen}
            setUserExperienceOpen={setUserExperienceOpen}
          />
        )}
      </div>
    </>
  );
};

export default MyDeliveryCard;
