import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { CheckOutlined } from "@mui/icons-material";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import { useDispatch, useSelector } from "react-redux";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PdfDownloader from "../PdfDownloader";
import MyDocument from "../../utils/OrderInvoice";
import VehicleCard from "./VehicleCard";
import LoadingUnloadingCard from "./LoadingUnloadingCard";
import {
  useGetBiltyInvoiceQuery,
  useGetOrderInvoiceQuery,
} from "../../services/userService";
import APIhandler from "../../utils/APIhandler";
import OrderInvoiceDialog from "./OrderInvoiceDialog";
import BiltyInvoiceDialog from "./BiltyInvoiceDialog";
import { useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BiltyContainer = styled(Stack)({
  alignItems: "flex-start",
});

const SingleBilty = ({
  singleBiltyOpen,
  setSingleBiltyOpen,
  statusColor,
  singleBilty,
  pendingDocumentsNumber,
  setDocumentsPendingOpen,
  setskipSingleBilty,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.userData);
  const [orderInvoiceFetching, setOrderInvoiceFetching] = useState(true);
  // const [biltyInvoiceFetching, setBiltyInvoiceFetching] = useState(true);
  const [invoice, setInvoice] = useState();
  const [openBiltyOrder, setOpenBiltyOrder] = useState(false);
  const [isBiltyOrder, setIsBiltyOrder] = useState("bilty");

  let orderInvoiceQuery = {
    token,
    orderNo: singleBilty.orderNo,
  };

  const {
    data: getOrderInvoiceResponse,
    isFetching: getOrderInvoiceFetching,
    error: getOrderInvoiceError,
  } = useGetOrderInvoiceQuery(orderInvoiceQuery);

  // let biltyInvoiceQuery = {
  //   token,
  //   orderNo: singleBilty.orderNo,
  //   biltyNo: singleBilty.biltyNo,
  // };

  // const {
  //   data: getBiltyInvoiceResponse,
  //   isFetching: getBiltyInvoiceFetching,
  //   error: getBiltyInvoiceError,
  // } = useGetBiltyInvoiceQuery(biltyInvoiceQuery);

  // console.error("ORDER INVOICE", getOrderInvoiceResponse);
  console.error("ORDER INVOICE", getOrderInvoiceResponse);
  return (
    <>
      <APIhandler
        responseData={getOrderInvoiceResponse}
        isFetching={getOrderInvoiceFetching}
        serverError={getOrderInvoiceError}
        onStatusTrue={() => {
          let tempInvoiceResponse = getOrderInvoiceResponse?.data?.bilty;
          for (let i = 0; i < tempInvoiceResponse.length; i++) {
            if (tempInvoiceResponse[i]?.biltyNo === singleBilty?.biltyNo) {
              setInvoice({
                biltyInvoice: tempInvoiceResponse[i],
                orderInvoice: getOrderInvoiceResponse?.data?.user,
              });
              break;
            }
          }

          setOrderInvoiceFetching(false);
        }}
        onStatusFalse={() => {}}
        onServerError={() => {}}
      />

      {/* <APIhandler
        responseData={getBiltyInvoiceResponse}
        isFetching={getBiltyInvoiceFetching}
        serverError={getBiltyInvoiceError}
        onStatusTrue={() => {
          setBiltyInvoiceFetching(false);
        }}
        onStatusFalse={() => {}}
        onServerError={() => {}}
      /> */}

      <Dialog
        maxWidth={"xl"}
        open={singleBiltyOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll="paper"
        onClose={() => {
          dispatch(isFetchingSomethingAction(false));
          setSingleBiltyOpen(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: statusColor,
            color: "white",
          }}
        >
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setSingleBiltyOpen(false)}
                aria-label="close"
              >
                <ArrowBackRoundedIcon />
              </IconButton>
              <Typography variant="h5">Bilty #{singleBilty.biltyNo}</Typography>
            </Stack>
            <IconButton
              onClick={() => {
                dispatch(isFetchingSomethingAction(true));
                setskipSingleBilty(false);
              }}
            >
              <ReplayRoundedIcon sx={{ fontSize: "2rem", color: "white" }} />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "1rem !important" }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ alignItems: "flex-start" }}
            spacing={2}
          >
            {singleBilty.type === "vehicle" && (
              <BiltyContainer direction="row">
                <VehicleCard
                  vehicle={singleBilty}
                  sxImage={{
                    marginRight: "1rem",
                    img: { width: "4rem", height: "4rem" },
                  }}
                />
              </BiltyContainer>
            )}

            {/* {singleBilty.type === "loading/unloading" &&
            singleBilty.loading_options &&
            loadingOptions.map((loading, index) => (
              <BiltyContainer direction="row" key={index}>
                <LoadingUnloadingCard
                  vehicle={singleBilty}
                  loadingUnloading={loading}
                  sxImage={{
                    marginRight: "1rem",
                    img: { width: "4rem", height: "4rem" },
                  }}
                  hideButton
                />
              </BiltyContainer>
            ))}

          {singleBilty.type === "loading/unloading" &&
            singleBilty.unloading_options &&
            unLoadingOptions.map((unloading, index) => (
              <BiltyContainer direction="row">
                <LoadingUnloadingCard
                  vehicle={singleBilty}
                  loadingUnloading={unloading}
                  sxImage={{
                    marginRight: "1rem",
                    img: { width: "4rem", height: "4rem" },
                  }}
                  hideButton
                />
              </BiltyContainer>
            ))} */}

            <Stack
              spacing={1}
              sx={{ alignItems: "flex-end", alignSelf: "flex-end" }}
            >
              <Typography
                sx={{
                  backgroundColor: "var(--primary)",
                  borderRadius: "100px",
                  color: "white",
                  padding: "0.25rem 1rem",
                }}
              >
                Rs {singleBilty.amount}
              </Typography>
              <Button
                variant="contained"
                sx={{ borderRadius: "100px" }}
                disableElevation
                startIcon={<LocalPhoneRoundedIcon />}
              >
                {singleBilty.vendor_phone}
              </Button>

              <IconButton
                onClick={() => {
                  navigate("/chat");
                }}
              >
                <ChatOutlinedIcon
                  sx={{ fontSize: "2rem", color: "var(--primary)" }}
                />
              </IconButton>
            </Stack>
          </Stack>

          <Divider sx={{ marginY: "1rem" }} />

          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Button
              onClick={() => {
                setIsBiltyOrder("bilty");
                setOpenBiltyOrder(true);
              }}
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{
                font: "400 0.9rem Roboto",
                textDecoration: "none",
                backgroundColor: "var(--primary)",
                color: "white",
                borderRadius: "100px",
              }}
            >
              Bilty
            </Button>

            <Button
              onClick={() => {
                setIsBiltyOrder("order");
                setOpenBiltyOrder(true);
              }}
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{
                font: "400 0.9rem Roboto",
                textDecoration: "none",
                backgroundColor: "var(--primary)",
                color: "white",
                borderRadius: "100px",
              }}
            >
              Invoice
            </Button>
          </Stack>

          <Typography
            sx={{
              color: "var(--Black)",
              marginY: "1rem",
              textAlign: "center",
              font: "600 1.2rem Poppins",
            }}
          >
            Tracking & Check Points
          </Typography>

          <List
            sx={{
              width: "100%",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      singleBilty?.driver_alotted_on && "var(--primary)",
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Driver Alloting Time"
                secondary={
                  singleBilty?.driver_alotted_on
                    ? singleBilty?.driver_alotted_on
                    : "Pending"
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      singleBilty?.driver_reached_on && "var(--primary)",
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Driver Reached On"
                secondary={
                  singleBilty?.driver_reached_on
                    ? singleBilty?.driver_reached_on
                    : "Pending"
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      singleBilty?.driver_pickup_on && "var(--primary)",
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Delivery Pickup Time"
                secondary={
                  singleBilty?.driver_pickup_on
                    ? singleBilty?.driver_pickup_on
                    : "Pending"
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      singleBilty?.driver_delivered_on && "var(--primary)",
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Delivered Time"
                secondary={
                  singleBilty?.driver_delivered_on
                    ? singleBilty?.driver_delivered_on
                    : "Pending"
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      singleBilty?.unloading_complete_on && "var(--primary)",
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Delivery Unloading Time"
                secondary={
                  singleBilty?.unloading_complete_on
                    ? singleBilty?.unloading_complete_on
                    : "Pending"
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      singleBilty?.container_returned_on && "var(--primary)",
                  }}
                >
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Container Return Time"
                secondary={
                  singleBilty?.container_returned_on
                    ? singleBilty?.container_returned_on
                    : "Pending"
                }
              />
            </ListItem>
          </List>

          <DialogActions className="downloadBiltyInvoiceActions">
            <Button
              className="downloadBiltyInvoiceActionsButtons"
              variant="contained"
              size="large"
              component="label"
              startIcon={
                pendingDocumentsNumber.includes("pending") ? (
                  <PendingActionsIcon />
                ) : (
                  <CheckOutlined />
                )
              }
              onClick={() => setDocumentsPendingOpen(true)}
            >
              {pendingDocumentsNumber}
              {/* <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={bankSlipUploadHandleChange}
                /> */}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="xl"
        sx={{
          ".MuiPaper-root": {
            margin: "1rem",
          },
        }}
        keepMounted
        open={openBiltyOrder}
        onClose={() => setOpenBiltyOrder(false)}
      >
        <DialogContent>
          {isBiltyOrder === "order" ? (
            <OrderInvoiceDialog
              invoice={invoice}
              orderInvoiceFetching={orderInvoiceFetching}
              setOpenBiltyOrder={setOpenBiltyOrder}
            />
          ) : (
            <BiltyInvoiceDialog
              invoice={invoice}
              orderInvoiceFetching={orderInvoiceFetching}
              setOpenBiltyOrder={setOpenBiltyOrder}
            />
          )}
        </DialogContent>

        <DialogActions sx={{ borderTop: "1px solid var(--borderGrey)" }}>
          {isBiltyOrder === "order" ? (
            <PdfDownloader
              text={"Download Invoice"}
              startIcon={<DownloadIcon />}
              orderInvoice={invoice}
              invoiceFetching={orderInvoiceFetching}
            />
          ) : (
            <PdfDownloader
              text={"Download Bilty"}
              startIcon={<DownloadIcon />}
              biltyInvoice={invoice}
              invoiceFetching={orderInvoiceFetching}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleBilty;
