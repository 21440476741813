import { Grid, IconButton, Stack, styled, Typography } from "@mui/material";
import React from "react";
import PdfDownloader from "../PdfDownloader";
import DownloadIcon from "@mui/icons-material/Download";
import Logo from "../../images/Logo2.png";
import CloseIcon from "@mui/icons-material/Close";

const OrderInvoiceContainer = styled(Stack)({
  img: {
    width: "100px",
  },
});

const BiltyInvoiceDialog = ({
  invoice,
  orderInvoiceFetching,
  setOpenBiltyOrder,
}) => {
  return (
    <OrderInvoiceContainer>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <img src={Logo} alt="Logo image" />
        <IconButton
          edge="start"
          onClick={() => setOpenBiltyOrder(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Typography>Bilty Number: {invoice?.biltyInvoice?.biltyNo}</Typography>

      <Typography>
        Customer ID: {invoice?.orderInvoice?.["Customer ID"]}
      </Typography>

      <Typography>
        Customer name: {invoice?.orderInvoice?.["Sender Name"]}
      </Typography>

      <Typography>
        Customer address: {invoice?.orderInvoice?.["Sender Address"]}
      </Typography>

      <Typography>
        Receiver name: {invoice?.orderInvoice?.["Receiver Name"]}
      </Typography>

      <Typography>
        Receiver Address: {invoice?.orderInvoice?.["Receiver Address"]}
      </Typography>

      <Typography>
        From (Origin): {invoice?.orderInvoice?.["From (Origin)"]}
      </Typography>

      <Typography>
        To (Destination): {invoice?.orderInvoice?.["To: (Destination)"]}
      </Typography>

      <Typography>
        Invoice Number: {invoice?.orderInvoice?.["Invoice Number"]}
      </Typography>

      <Typography>
        Order Number: {invoice?.orderInvoice?.["Order No"]}
      </Typography>

      <Grid
        container
        sx={{
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "0.2rem",
          backgroundColor: "var(--primary)",
          color: "white",
          marginTop: "1rem",
        }}
      >
        <Grid item xs={4}>
          <Typography>Description of goods</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Weight (Kg/Ton)</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Vehicle type</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          padding: "0.2rem",
          backgroundColor: "var(--lightGrey)",
        }}
      >
        <Grid item xs={4}>
          <Typography>{invoice?.biltyInvoice?.material}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography> {invoice?.biltyInvoice?.weight} Tons</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography> {invoice?.biltyInvoice?.name}</Typography>
        </Grid>
      </Grid>
    </OrderInvoiceContainer>
  );
};

export default BiltyInvoiceDialog;
