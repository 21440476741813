import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import Notification from "./Notification";

const AllNotifications = () => {
  const { allNotificationsRouteData, allNotificationsRouteFinalData } =
    useSelector((state) => state.utils);

  return (
    <Stack sx={{ position: "relative", alignItems: "center", width: "100%" }}>
      <Notification
        data={allNotificationsRouteFinalData}
        mobileWidth="100vw"
        width="50vw"
        allNotificationsRouteData
      />
    </Stack>
  );
};

export default AllNotifications;
