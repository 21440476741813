import { configureStore } from "@reduxjs/toolkit";

import { userApi } from "./services/userService";

import userSlice from "./sliceReducers/userSlice";
import utilsSlice from "./sliceReducers/utilsSlice";

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    userData: userSlice,
    utils: utilsSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      userApi.middleware
    ),
});

export default store;
