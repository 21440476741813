import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

const MobileDrawer = ({
  DrawerHeader,
  handleDrawer,
  sidebarItems,
  activeNavbar,
  handleDrawerAction,
}) => {
  const { open } = useSelector((state) => state.utils);
  const { user, token, proApplicationData, myWalletData } = useSelector(
    (state) => state.userData
  );

  return (
    <Drawer
      elevation={window.innerWidth > 900 ? 1 : 0}
      open={
        typeof window !== "undefined" && window.innerWidth <= 900 ? open : false
      }
      onClose={handleDrawer}
      sx={{ display: { xs: "inherit", md: "none" } }}
    >
      <DrawerHeader />

      <List>
        {sidebarItems.map((text) => (
          <Box key={text.name}>
            {((text.name === "Upgrade To Pro" &&
              proApplicationData?.proApplication?.status === "pending") ||
              !myWalletData?.data?.credit_amount ||
              text.name !== "Upgrade To Pro") && (
              <ListItem
                // disablePadding
                sx={{
                  display: "block",
                  padding: "0.5rem",
                  justifyContent: "center",
                }}
                onClick={() => {
                  handleDrawerAction(text);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open
                      ? "initial"
                      : { sm: "initial", md: "center" },
                    px: 2.5,
                    bgcolor: activeNavbar === text.name && "var(--primary)",
                    borderRadius: "5px",
                    ":hover": {
                      backgroundColor: "var(--primary)",
                      color: "white",
                      ".listItemButton": { color: "white" },
                    },
                  }}
                >
                  <ListItemIcon
                    className="listItemButton"
                    sx={{
                      minWidth: 0,
                      mr: open && 3,
                      color:
                        activeNavbar === text.name ? "white" : "var(--grey)",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>

                  <ListItemText
                    primary={text.name}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: activeNavbar === text.name && "white",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {text.name === "Settings" && <Divider />}
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default MobileDrawer;
