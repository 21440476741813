import useDidMountEffect from "./useDidMountEffect";

function APIhandler({
  isFetching,
  responseData,
  serverError,
  onStatusTrue,
  onStatusFalse,
  onServerError,
}) {
  useDidMountEffect(() => {
    if (!isFetching && responseData && !serverError) {
      if (responseData.status === true) {
        onStatusTrue();
      } else if (responseData.status === false) {
        onStatusFalse();
      }
    }
    if (!isFetching && serverError) {
      onServerError();
    }
  }, [isFetching]);

  return null;
}

export default APIhandler;
