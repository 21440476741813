import { Typography } from "@mui/material";
import React, { Suspense } from "react";

const withSuspense = (LazyComponent) => (props) => {
  return (
    <>
      <Suspense
        fallback={
          <Typography sx={{ textAlign: "center" }}>Loading...</Typography>
        }
      >
        <LazyComponent {...props} />
      </Suspense>
    </>
  );
};

export default withSuspense;
