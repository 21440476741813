import { Dialog, DialogActions, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import MyButton from "./MyButton";
import OtpInput from "react18-input-otp";
import APIhandler from "./APIhandler";
import { useState } from "react";
import { notifyError, notifySuccess } from "./notifyMessage";
import { useDispatch } from "react-redux";
import { isFetchingSomethingAction } from "../sliceReducers/utilsSlice";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../firebase";
import { loginAction } from "../sliceReducers/userSlice";
import { useNavigate } from "react-router-dom";
import { useSignupOTPQuery } from "../services/userService";

function OtpDialogbox({
  otp,
  setOtp,
  showOtp,
  setShowOtp,
  setShowLoginForm,
  setAccountCreated,
}) {
  const dispatch = useDispatch();
  const [skipOtp, setSkipOtp] = useState(true);
  const navigate = useNavigate();

  const otpQuery = {
    otp: parseInt(otp),
  };

  const {
    data: otpResponse,
    error: otpError,
    isFetching: otpFetching,
  } = useSignupOTPQuery(otpQuery, { skip: skipOtp });

  return (
    <>
      <APIhandler
        responseData={otpResponse}
        serverError={otpError}
        isFetching={otpFetching}
        onStatusTrue={() => {
          // dispatch(loginAction({ user: otpResponse.profile }));
          // signInWithCustomToken(auth, otpResponse.token)
          //   .then((userCredential) => {
          //     notifySuccess("User created successfully");
          //     dispatch(isFetchingSomethingAction(false));
          //     navigate("/home");
          //   })
          //   .catch((error) => {
          //     notifyError("Verification failed");
          //     setSkipOtp(true);
          //     dispatch(isFetchingSomethingAction(false));
          //   });
          dispatch(isFetchingSomethingAction(false));
          setShowLoginForm(true);
          setShowOtp(false);
          setAccountCreated(true);
          // navigate("/");
          notifySuccess(
            "Account created successfully, Please login to continue"
          );
          setSkipOtp(true);
        }}
        onStatusFalse={() => {
          notifyError("Verification failed");
          setAccountCreated(false);
          setSkipOtp(true);
          dispatch(isFetchingSomethingAction(false));
        }}
        onServerError={() => {
          setSkipOtp(true);
          dispatch(isFetchingSomethingAction(false));
        }}
      />
      <Dialog open={showOtp} onClose={() => setShowOtp(false)}>
        <Stack spacing={1} sx={{ padding: "1rem" }}>
          <Typography
            sx={{ font: "500 1.3rem Roboto", marginBottom: "1rem" }}
            textAlign={"center"}
          >
            Enter 4 Digit OTP
          </Typography>

          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            numInputs={4}
            inputStyle={{
              width: "3rem",
              height: "4rem",
              margin: "0.5rem",
              font: "500 1.2rem Roboto",
            }}
          />

          <DialogActions>
            <MyButton
              text={"Submit OTP"}
              color={"white"}
              width={"100%"}
              size={"large"}
              disabled={otp.length === 4 ? false : true}
              onClick={() => {
                toast.dismiss();
                dispatch(isFetchingSomethingAction(true));
                setSkipOtp(false);
              }}
            />
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  );
}

export default OtpDialogbox;
