import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import PhoneInput from "react-phone-input-2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ButtonComponent from "../../utils/ButtonComponent";
import { useLoginQuery } from "../../services/userService";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { loginAction } from "../../sliceReducers/userSlice";
import { signInWithCustomToken } from "firebase/auth";
import { notifyError, notifySuccess } from "../../utils/notifyMessage";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const LoginForm = ({
  loginPhone,
  setLoginPhone,
  loginPassword,
  setLoginPassword,
  showLoginPassword,
  setShowLoginPassword,
  setShowLoginForm,
  accountCreated,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skipLogin, setSkipLogin] = useState(true);
  //LOGIN API REQUEST **********************

  const loginQuery = {
    phone: loginPhone,
    password: loginPassword,
  };

  const {
    data: loginData,
    error: loginError,
    isFetching: isLoginFetching,
  } = useLoginQuery(loginQuery, { skip: skipLogin });

  const submitLoginForm = (e) => {
    e.preventDefault();
    toast.dismiss();
    dispatch(isFetchingSomethingAction(true));
    setSkipLogin(false);
  };

  useDidMountEffect(() => {
    if (isLoginFetching === false && loginData && !loginError) {
      if (loginData.status) {
        console.log("token -> ", loginData.token);
        dispatch(loginAction({ user: loginData.profile }));
        setSkipLogin(true);
        signInWithCustomToken(auth, loginData.token)
          .then((userCredential) => {
            const user = userCredential.user;
            notifySuccess("Login Successfully");
            dispatch(isFetchingSomethingAction(false));
            navigate("/home");
            console.log("signInWithCustomToken");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            dispatch(isFetchingSomethingAction(false));
            console.log(errorCode, errorMessage);
          });
      } else {
        // notifyError(loginData.error);
        notifyError("Incorrect Phone Number or Password");
        setSkipLogin(true);
        dispatch(isFetchingSomethingAction(false));
      }
    } else if (isLoginFetching === false && !loginData) {
      setSkipLogin(true);
      notifyError("Incorrect Phone Number or Password");
      dispatch(isFetchingSomethingAction(false));
    }
    if (isLoginFetching === false && loginError) {
      //   if (loginError.data.errors && loginError.data.errors.length > 0) {
      //     notifyError(loginError.data.errors[0].msg);
      //   } else {
      //     notifyError(loginError.data.message);
      //   }

      setSkipLogin(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [isLoginFetching]);

  return (
    <form action="" className="userForm" onSubmit={submitLoginForm}>
      <h1>Login</h1>
      {accountCreated && (
        <Typography
          sx={{
            mt: "1rem",
            color: "var(--SuccessDark)",
            fontSize: "1rem",
            ":hover": {
              color: "var(--SuccessDark) !important",
            },
          }}
        >
          Account created successfully, Please login to continue.
        </Typography>
      )}
      <div className="inputBox">
        <p>Phone</p>
        <div>
          <PhoneInput
            className="inputField"
            placeholder="Enter phone number"
            country={"pk"}
            value={loginPhone}
            countryCodeEditable={false}
            onChange={(e) => setLoginPhone("+" + e)}
          />
        </div>
      </div>
      <div className="inputBox">
        <p>Password</p>
        <div>
          <LockIcon />
          <input
            required
            className="inputField"
            type={`${showLoginPassword ? "text" : "password"}`}
            value={loginPassword}
            placeholder="Password"
            onChange={(e) => setLoginPassword(e.target.value)}
          />

          {!showLoginPassword && (
            <VisibilityIcon
              onClick={() => setShowLoginPassword(true)}
              style={{
                right: "2rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            />
          )}
          {showLoginPassword && (
            <VisibilityOffIcon
              onClick={() => setShowLoginPassword(false)}
              style={{
                right: "2rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            />
          )}
        </div>
      </div>
      <p className="forgottenPassword">Forgotten password?</p>
      <ButtonComponent type={"submit"} text={"Login"} className="loginButton" />
      <p className="haveAccount" onClick={() => setShowLoginForm(false)}>
        Dont have an account?<b> Sign Up</b>
      </p>
    </form>
  );
};

export default LoginForm;
