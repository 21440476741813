import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Bilties from "../../utils/Bilties";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaceIcon from "@mui/icons-material/Place";

const MyRequestDeliveryCard = ({
  statusColor,
  statusText,
  createQouteCounter,
  myRequest,
  numberOfVehicles,
  pickUpTime,
  handleMoreDetailsOpen,
  status,
  findingRates,
  openAdditionalInformation,
  setOpenAdditionalInformation,
}) => {
  if (myRequest.orderNo === "0325") {
    console.log(myRequest);
  }

  return (
    <>
      {/******************   OFFER STATUS *******************/}
      <Typography
        className="requestOfferStatus"
        style={{ backgroundColor: statusColor }}
      >
        {statusText}
      </Typography>

      {/******************   ORDER CREATED/QOUTED/COUNTERED DATETIME *******************/}
      <Typography textAlign={"center"} pt={"0.25rem"}>
        {createQouteCounter.text}: {createQouteCounter.dateTime}
      </Typography>

      <div style={{ padding: "1rem", paddingTop: "0.5rem" }}>
        {/******************   ORDER NUMBER AND ORDER TYPE *******************/}
        <div className="myOrderNoType">
          <Typography>{myRequest.orderNo}</Typography>
          {console.log("Request Type",myRequest)}
          {(myRequest.request_type || myRequest.type) && (
            <Typography>
          
              {myRequest.request_type === "transit" || myRequest.type === "transit"
                ? "Transit"
                : "Nationwide"}
            </Typography>
          )}
        </div>

        {/******************  NUMBER OF VEHICLES *******************/}
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            marginY: "0.5rem",
            alignItems: "flex-end",
          }}
        >
          <Typography className="myOrderNumberOfVehicles">
            Num of vehicles: {numberOfVehicles}
          </Typography>

          {/******************  ADDITIONAL INFORMATION *******************/}
          <Box
            sx={{
              display: "flex",
              // justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "var(--BrightRed2)",
                ":hover": {
                  backgroundColor: "var(--BrightRed2Dark)",
                },
                visibility: myRequest.additionalDescription
                  ? "visible"
                  : "hidden",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenAdditionalInformation(true);
              }}
            >
              Additional Information
            </Button>
          </Box>
        </Stack>

        {openAdditionalInformation && (
          <Dialog
            open={openAdditionalInformation}
            onClose={(e) => {
              e.stopPropagation();
              setOpenAdditionalInformation(false);
            }}
          >
            <DialogTitle
              sx={{ backgroundColor: "var(--BrightRed2)", color: "white" }}
            >
              Additional Information
            </DialogTitle>
            <DialogContent>
              <Typography pt={"1rem"} className="myRequestPickUpTime">
                {myRequest.additionalDescription}
              </Typography>
            </DialogContent>
          </Dialog>
        )}

        {/******************  VEHICLES *******************/}
        {myRequest.bilty ? (
          <>
            <div
              className={`myOrderVehicleMaterials  ${
                myRequest.bilty.length > 1 && "myOrderVehicleMaterialsScroll"
              }`}
            >
              {myRequest.bilty.map((vehicle, vehicleIndex) => (
                <Bilties
                  key={vehicleIndex}
                  vehicle={vehicle}
                  vehicleIndex={vehicleIndex}
                />
              ))}
            </div>
          </>
        ) : (
          myRequest.bilties && (
            <>
              <div
                className={`myOrderVehicleMaterials  ${
                  myRequest.bilties.length > 1 &&
                  "myOrderVehicleMaterialsScroll"
                }`}
              >
                {myRequest.bilties.map((vehicle, vehicleIndex) => (
                  <Bilties
                    key={vehicleIndex}
                    vehicle={vehicle}
                    vehicleIndex={vehicleIndex}
                  />
                ))}
              </div>
            </>
          )
        )}

        {/******************  PICKUP TIME AND DISTANCE *******************/}
        <Typography pt={"1rem"} className="myRequestPickUpTime">
          <b>Pickup time: </b>
          {pickUpTime}
        </Typography>

        <Typography className="myRequestPickUpTime">
          <b>Distance: </b>
          {myRequest.disText}
        </Typography>

        <Typography className="myRequestPickUpTime">
          <b>Payment Term: </b>
          {myRequest.payment_method}
        </Typography>

        <Typography className="myRequestPickUpTime" sx={{ height: "1rem" }}>
          {(myRequest.payment_method === "Credit" ||
            myRequest.payment_method === "credit") && (
            <>
              <b>Credit Term: </b>
              {myRequest.credit_duration} days
            </>
          )}
        </Typography>

        {/******************  ORIGIN DESTINATION *******************/}
        <div className="myOrderOriginDestination">
          <div className="myOrderOriginDestinationDetails">
            <Stack sx={{ alignItems: "center" }}>
              <RadioButtonCheckedIcon
                style={{
                  color: "var(--primary)",
                  marginBottom: "0.1rem",
                }}
              />
              <div className="circle"></div>
              <div className="circle"></div>
            </Stack>
            <Typography>{myRequest.originAddress}</Typography>
          </div>
          {/* <MoreVertIcon /> */}

          <div className="myOrderOriginDestinationDetails">
            <Stack sx={{ alignItems: "center" }}>
              <PlaceIcon
                style={{
                  color: "var(--BrightRed1)",
                  marginTop: "0.1rem",
                  marginBottom: "0.1rem",
                }}
              />
              <div className="circle"></div>
              <div className="circle"></div>
            </Stack>
            <Typography>{myRequest.destinationAddress}</Typography>
          </div>
          {/* <MoreVertIcon /> */}
          <div className="myOrderOriginDestinationDetails">
            <RadioButtonCheckedIcon
              style={{ color: "var(--primary)", marginTop: "0.1rem" }}
            />
            <Typography>{myRequest.containerReturnAddress}</Typography>
          </div>
        </div>

        {/******************  MORE DETAILS AND QOUTE AMOUNT *******************/}
        <div
          className="moreDetailsQouteAmount"
          style={{
            justifyContent: status === "pending" && "center",
            // padding: "1rem",
            // paddingTop: "0.5rem",
          }}
        >
          <Typography
            onClick={handleMoreDetailsOpen}
            className="myOrderMoreDetails"
          >
            More Details
          </Typography>

          {!findingRates && (
            <Typography
              className={
                myRequest.qoute_amount
                  ? "vendorQoutedAmount"
                  : "userQoutedAmount"
              }
            >
              {myRequest.qoute_amount
                ? myRequest.qoute_amount
                : myRequest.amount}
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default MyRequestDeliveryCard;
