import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useDispatch, useSelector } from "react-redux";

import { notifyError, notifyInfo } from "../../utils/notifyMessage";
import { useNavigate } from "react-router-dom";
import useDidMountEffect from "../../utils/useDidMountEffect";
import LogoBird from "../../images/logoBird.png";
import FileImage from "../../images/fileImage.jpg";
import "../MyRequests/OfferAccepted.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useUploadTransitCargoDocumentsQuery } from "../../services/userService";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import Loader from "../../utils/Loader";

const DocumentPending = ({
  alreadyPresentDocument,
  singleBilty,
  singleBiltyRefetch,
  setskipSingleBilty,
  setDocumentsPendingOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [billOfLanding, setBillOfLanding] = useState();
  const [billOfLandingPreview, setBillOfLandingPreview] = useState("");

  const [invoice, setInvoice] = useState();
  const [invoicePreview, setInvoicePreview] = useState("");

  const [gd, setGd] = useState();
  const [gdPreview, setGdPreview] = useState("");

  const [demandLetter, setDemandLetter] = useState();
  const [demandLetterPreview, setDemandLetterPreview] = useState("");

  const [packingList, setPackingList] = useState();
  const [packingListPreview, setPackingListPreview] = useState("");

  const [formData, setFormData] = useState();

  const [skipTransitCargoDocument, setSkipTransitCargoDocument] =
    useState(true);

  const { offerAcceptedDocuments, token } = useSelector(
    (state) => state.userData
  );

  const documentUploadHandleChange = (e, setDocument, setDocumentPreview) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setDocumentPreview(reader.result);
        setDocument(e.target.files[0]);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const documentsArray = [
    {
      name: "Bill of landing",
      preview: billOfLandingPreview,
      set: setBillOfLanding,
      setPreview: setBillOfLandingPreview,
    },
    {
      name: "Invoice",
      preview: invoicePreview,
      set: setInvoice,
      setPreview: setInvoicePreview,
    },
    {
      name: "GD",
      preview: gdPreview,
      set: setGd,
      setPreview: setGdPreview,
    },
    {
      name: "Demand letter",
      preview: demandLetterPreview,
      set: setDemandLetter,
      setPreview: setDemandLetterPreview,
    },
    {
      name: "Packing list",
      preview: packingListPreview,
      set: setPackingList,
      setPreview: setPackingListPreview,
    },
  ];

  useEffect(() => {
    for (let i = 0; i < documentsArray.length; i++) {
      if (
        alreadyPresentDocument[i] &&
        alreadyPresentDocument[i].value === true
      ) {
        documentsArray[i].setPreview(alreadyPresentDocument[i].url);
      }
    }
  }, []);

  const offerAcceptedProceedHandler = () => {
    if (billOfLanding || invoice || gd || demandLetter || packingList) {
      let docLength = 0;
      const uploadTransitDocumentsForm = new FormData();
      uploadTransitDocumentsForm.set("token", token);
      uploadTransitDocumentsForm.set("orderNo", singleBilty.orderNo);
      uploadTransitDocumentsForm.set("vendor_phone", singleBilty.vendor_phone);
      uploadTransitDocumentsForm.set("vendor_name", singleBilty.vendor_name);
      if (billOfLanding) {
        docLength += 1;
        uploadTransitDocumentsForm.set("bill_of_landing", billOfLanding);
      }
      if (invoice) {
        docLength += 1;
        uploadTransitDocumentsForm.set("invoice", invoice);
      }
      if (gd) {
        docLength += 1;
        uploadTransitDocumentsForm.set("gd", gd);
      }
      if (demandLetter) {
        docLength += 1;
        uploadTransitDocumentsForm.set("demand_letter", demandLetter);
      }
      if (packingList) {
        docLength += 1;
        uploadTransitDocumentsForm.set("packaging_list", packingList);
      }

      uploadTransitDocumentsForm.set("docLength", docLength);

      setFormData(uploadTransitDocumentsForm);
      setSkipTransitCargoDocument(false);
    } else {
      notifyInfo("Upload atleast one document");
    }
  };

  const defaultImage = (fileType, setPreview) => {
    if (fileType) {
      let temp = fileType.name;
      temp = temp.substring(temp.indexOf(".") + 1);
      if (temp === "png" || temp === "jpg" || temp === "jfif") {
        return;
      } else {
        setPreview(FileImage);
      }
    }
  };

  useDidMountEffect(() => {
    defaultImage(billOfLanding, setBillOfLandingPreview);
    defaultImage(invoice, setInvoicePreview);
    defaultImage(gd, setGdPreview);
    defaultImage(demandLetter, setDemandLetterPreview);
    defaultImage(packingList, setPackingListPreview);
  }, [billOfLanding, invoice, gd, demandLetter, packingList]);

  const {
    data: uploadTransitCargoDocumentsRespoonse,
    error: uploadTransitCargoDocumentsError,
    isFetching: uploadTransitCargoDocumentsFetching,
  } = useUploadTransitCargoDocumentsQuery(formData, {
    skip: skipTransitCargoDocument,
  });

  useDidMountEffect(() => {
    if (
      uploadTransitCargoDocumentsFetching === false &&
      uploadTransitCargoDocumentsRespoonse &&
      !uploadTransitCargoDocumentsError
    ) {
      setskipSingleBilty(false);
      dispatch(isFetchingSomethingAction(true));
      setSkipTransitCargoDocument(true);
    }

    if (
      uploadTransitCargoDocumentsFetching === false &&
      uploadTransitCargoDocumentsError
    ) {
    }
  }, [uploadTransitCargoDocumentsFetching]);

  return (
    <>
      {uploadTransitCargoDocumentsFetching && <Loader />}
      <div className="offerAcceptedPage">
        {/* <Typography variant="h4">Documents Pending</Typography> */}
        <div className="offerAcceptedContainer">
          {documentsArray.map((document, index) => {
            let isAlreadyPresent = false;
            if (
              alreadyPresentDocument[index] &&
              alreadyPresentDocument[index].value === true
            ) {
              isAlreadyPresent = true;
            }

            return (
              <div
                className="offerAcceptedCard"
                key={index}
                style={{ width: "10rem", justifyContent: "space-between" }}
              >
                <Typography className="offerAcceptedCardHeading">
                  {document.name}
                </Typography>
                {document.preview ? (
                  <img src={document.preview} alt="" />
                ) : (
                  <FileUploadIcon />
                )}
                {!document.preview && (
                  <Button
                    variant="contained"
                    component="label"
                    className="offAcceptedUploadButtons"
                  >
                    Upload
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      onChange={(e) =>
                        documentUploadHandleChange(
                          e,
                          document.set,
                          document.setPreview
                        )
                      }
                    />
                  </Button>
                )}

                {document.preview && isAlreadyPresent && (
                  <Typography
                    className="offerAcceptedCardHeading"
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    Already Uploaded
                  </Typography>
                )}

                {document.preview && !isAlreadyPresent && (
                  <Button
                    variant="contained"
                    component="label"
                    className="offAcceptedRemoveButtons"
                    onClick={() => {
                      document.setPreview("");
                      document.set("");
                    }}
                  >
                    {/* <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onChange={(e) =>
                      documentUploadHandleChange(
                        e,
                        document.set,
                        document.setPreview
                      )
                    }
                  /> */}
                    Remove
                  </Button>
                )}
              </div>
            );
          })}
        </div>
        <div className="offerAcceptedNextPreviousButtonContainer">
          {alreadyPresentDocument && alreadyPresentDocument.length < 5 ? (
            <Button
              size="large"
              variant="contained"
              //   style={{
              //     backgroundColor:
              //       activeStep === 0 ? "var(--Grey)" : "var(--primary)",
              //   }}
              //   disabled={activeStep === 0}
              onClick={offerAcceptedProceedHandler}
            >
              Proceed
            </Button>
          ) : (
            <>
              <Typography
                className="offerAcceptedCardHeading"
                style={{ marginTop: "1rem", textAlign: "center" }}
              >
                All documents are already uploaded
              </Typography>
              <Button
                size="large"
                variant="contained"
                //   style={{
                //     backgroundColor:
                //       activeStep === 0 ? "var(--Grey)" : "var(--primary)",
                //   }}
                //   disabled={activeStep === 0}
                onClick={() => setDocumentsPendingOpen(false)}
              >
                Continue
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentPending;
