import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserGiveRatingQuery } from "../../services/userService";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import Loader from "../../utils/Loader";
import { notifyError, notifySuccess } from "../../utils/notifyMessage";
import useDidMountEffect from "../../utils/useDidMountEffect";

const SubmitRatingExperience = ({
  userRatingOpen,
  setUserRatingOpen,
  myRequest,
  setSkipCompletedOrders,
  userExperienceOpen,
  setUserExperienceOpen,
}) => {
  const dispatch = useDispatch();
  const { user, token, myWalletData, creditRemaining } = useSelector(
    (state) => state.userData
  );
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [skipUserRating, setSkipUserRating] = useState(true);
  // /******************    USER RATING API *******************/
  const userRatingQuery = {
    token,
    orderNo: myRequest.orderNo,
    rating,
    experience: comment,
  };

  const {
    data: userRatingResponse,
    error: userRatingError,
    isFetching: userRatingFetching,
  } = useUserGiveRatingQuery(userRatingQuery, {
    skip: skipUserRating,
  });

  useDidMountEffect(() => {
    if (
      userRatingFetching === false &&
      userRatingResponse &&
      !userRatingError
    ) {
      if (userRatingResponse.status === true) {
        dispatch(isFetchingSomethingAction(false));
        setSkipCompletedOrders(false);
        setSkipUserRating(true);
        notifySuccess("Rating submitted successfully");
        setUserRatingOpen(false);
      }
    }

    if (userRatingFetching === false && userRatingError) {
      //
      // setskipSingleBilty(true);
      setSkipUserRating(true);
    }
  }, [userRatingFetching]);

  return (
    <>
      {/*  ****************** SUBMIT USER RATING  ****************** */}
      {userRatingOpen && (
        <Dialog
          maxWidth={"xl"}
          open={userRatingOpen}
          keepMounted
          scroll="body"
          onClose={() => setUserRatingOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{
              backgroundColor: "var(--SuccessDark)",
              color: "white",
              textAlign: "center",
            }}
          >
            Give Rating
          </DialogTitle>

          <Typography
            style={{
              // backgroundColor: "var(--darkPrimary)",
              // color: "white",
              marginTop: "0.5rem",
              font: "600 1rem Poppins",
              textAlign: "center",
              padding: "0",
            }}
          >
            {myRequest.vendor_name}
          </Typography>

          <Typography
            style={{
              // backgroundColor: "var(--darkPrimary)",
              // color: "white",
              textAlign: "center",
              padding: "0",
            }}
          >
            Order# {myRequest.orderNo}
          </Typography>

          <DialogTitle
            style={{
              font: "600 1rem Poppins",
              textAlign: "center",
            }}
          >
            How was your expreience?
          </DialogTitle>

          <Typography
            style={{
              // backgroundColor: "var(--darkPrimary)",
              // color: "white",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Your feedback will help us improve your next move
          </Typography>

          <DialogContent
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Rating
              onChange={(e) => setRating(e.target.value)}
              value={rating}
              size="large"
            />
            <TextField
              style={{ marginTop: "2rem", width: "100%" }}
              id="outlined-multiline-static"
              label="Additional Comments"
              multiline
              rows={4}
              defaultValue="Default Value"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </DialogContent>

          <DialogActions style={{ padding: "1rem", justifyContent: "center" }}>
            <Button
              className="myProCreditsDialogBoxClose"
              onClick={() => {
                dispatch(isFetchingSomethingAction(true));
                setSkipUserRating(false);
              }}
            >
              Submit Review
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/*  ****************** USER EXPERIENCE ****************** */}
      {userExperienceOpen && (
        <Dialog
          maxWidth={"xl"}
          open={userExperienceOpen}
          keepMounted
          scroll="body"
          onClose={() => setUserExperienceOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{
              backgroundColor: "var(--SuccessDark)",
              color: "white",
              textAlign: "center",
            }}
          >
            User experience
          </DialogTitle>

          <DialogContent
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                // backgroundColor: "var(--darkPrimary)",
                // color: "white",
                padding: "1rem",
                textAlign: "center",
              }}
            >
              {myRequest.user_experience}
            </Typography>
          </DialogContent>

          {/* <DialogActions style={{ padding: "1rem", justifyContent: "center" }}>
            <Button
              className="myProCreditsDialogBoxClose"
              onClick={() => {
                setSkipUserRating(false);
              }}
            >
              Submit Review
            </Button>
          </DialogActions> */}
        </Dialog>
      )}
    </>
  );
};

export default SubmitRatingExperience;
