import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(window.localStorage.getItem("user")),
  token: "",
  proApplicationData: {},
  loadingOptions: [],
  unloadingOptions: [],
  allvehiclesData: [],
  transitVehicles: [],
  nationwideVehicles: [],
  myPendingRequestData: [],
  offersReceivedData: [],
  counterByUserRequestsData: [],
  counterByVendorRequestData: [],
  counterAcceptedByVendorData: [],
  rejectedByUserRequestsData: [],
  rejectedByVendorRequestsData: [],
  waitingForAdminApprovalData: [],
  activeOrdersData: [],
  completedOrdersData: [],
  myWalletData: {},
  transactions: [],
  transactionsOrderNo: [],
  allTransactions: [],
  acceptedAmounts: [],
  acceptedAmountsTime: [],
  offerAcceptedDocuments: {},
  creditRemaining: 0,
  amount: 0,
  addressInfoFrom: JSON.parse(window.localStorage.getItem("from")) || {},
  addressInfoTo: JSON.parse(window.localStorage.getItem("to")) || {},
  chatClient: null,
  contactPersons: [],
  filteredContactPersons: [],
  applicationLoaded: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginAction(state, action) {
      const { user } = action.payload;
      state.user = user;
      window.localStorage.setItem("user", JSON.stringify(user));
    },

    logoutAction(state, action) {
      state.applicationLoaded = action.payload;
    },

    tokenAction(state, action) {
      const token = action.payload;
      state.token = token;
    },

    proApplicationDataAction(state, action) {
      const proApplicationData = action.payload;
      state.proApplicationData = proApplicationData;
    },

    allVehiclesDataAction(state, action) {
      const allvehiclesData = action.payload;
      state.allvehiclesData = allvehiclesData;
      let transitVehiclesTemp = [];
      let nationwideVehiclesTemp = [];
      for (let i = 0; i < allvehiclesData.length; i++) {
        if (allvehiclesData[i].vehicle_category === "transit") {
          transitVehiclesTemp.push(allvehiclesData[i]);
        } else if (allvehiclesData[i].vehicle_category === "upcountry") {
          nationwideVehiclesTemp.push(allvehiclesData[i]);
        }
      }

      state.transitVehicles = transitVehiclesTemp;
      state.nationwideVehicles = nationwideVehiclesTemp;
    },

    loadingOptionsDataAction(state, action) {
      const loadingOptions = action.payload;
      state.loadingOptions = loadingOptions;
    },

    unloadingOptionsDataAction(state, action) {
      const unloadingOptions = action.payload;
      state.unloadingOptions = unloadingOptions;
    },

    myPendingRequestsAction(state, action) {
      const myPendingRequestData = action.payload;
      state.myPendingRequestData = myPendingRequestData;
    },

    offersReceivedAction(state, action) {
      const offersReceivedData = action.payload;
      state.offersReceivedData = offersReceivedData;
    },

    counterByUserRequestsAction(state, action) {
      const counterByUserRequestsData = action.payload;
      state.counterByUserRequestsData = counterByUserRequestsData;
    },

    counterByVendorRequestsAction(state, action) {
      const counterByVendorRequestData = action.payload;
      state.counterByVendorRequestData = counterByVendorRequestData;
    },

    counterAcceptedByVendorRequestsAction(state, action) {
      const counterAcceptedByVendorData = action.payload;
      state.counterAcceptedByVendorData = counterAcceptedByVendorData;
    },

    myRejectedRequestsAction(state, action) {
      const myRejectedRequestsData = action.payload;
      state.rejectedByVendorRequestsData = myRejectedRequestsData.filter(
        (rejectedReq) => {
          return rejectedReq.status === "user_counter_rejected";
        }
      );

      state.rejectedByUserRequestsData = myRejectedRequestsData.filter(
        (rejectedReq) => {
          return (
            rejectedReq.status === "qoute_rejected" ||
            rejectedReq.status === "vendor_counter_rejected"
          );
        }
      );
      // state.myRejectedRequestsData = myRejectedRequestsData;
    },

    waitingForAdminApprovalRequestsAction(state, action) {
      const waitingForAdminApprovalData = action.payload;
      state.waitingForAdminApprovalData = waitingForAdminApprovalData;
    },

    activeOrdersAction(state, action) {
      const activeOrdersData = action.payload;
      state.activeOrdersData = activeOrdersData;
    },

    completedOrdersAction(state, action) {
      const completedOrdersData = action.payload;
      state.completedOrdersData = completedOrdersData;
    },

    myWalletAction(state, action) {
      const myWalletData = action.payload;
      state.myWalletData = myWalletData;
      state.amount = myWalletData.data.amount;

      if (myWalletData?.data?.transactions) {
        let tempWallet = [...myWalletData.data.transactions];
        let checkTempWallet = [...myWalletData.data.transactions];
        let tempTransactionsOrderNo = [];
        let acceptedAmounts = [];
        let acceptedAmountsTime = [];
        for (let i = 0; i < checkTempWallet.length; i++) {
          if (!checkTempWallet[i].request_type) {
            tempWallet.splice(i, 1);
          } else {
            tempTransactionsOrderNo.push(checkTempWallet[i].orderNo);
            acceptedAmounts.push(checkTempWallet[i].acceptedAmount);
            acceptedAmountsTime.push(
              checkTempWallet[i].time.substring(
                0,
                checkTempWallet[i].time.indexOf(",")
              )
            );
          }
        }

        state.transactions = tempWallet.sort((a, b) => b.orderNo - a.orderNo);
        state.transactionsOrderNo = tempTransactionsOrderNo;
        state.allTransactions = tempWallet;
        if (acceptedAmounts.length >= 30) {
          state.acceptedAmounts = acceptedAmounts.slice(-30);
          state.acceptedAmountsTime = acceptedAmountsTime.slice(-30);
        } else {
          state.acceptedAmounts = acceptedAmounts;
          state.acceptedAmountsTime = acceptedAmountsTime;
        }
      }
      state.creditRemaining =
        myWalletData.data.credit_amount - myWalletData.data.credit_used;
    },

    deleteWalletAction(state, action) {
      state.myWalletData = action.payload;
    },

    filterTransactionsAction(state, action) {
      const { filterTransactions, searchTransaction } = action.payload;

      if (filterTransactions === "All") {
        state.transactions = state.allTransactions;
        if (searchTransaction !== "") {
          state.transactions = state.transactions.filter((x) => {
            return x.orderNo === searchTransaction;
          });
        }
      } else if (filterTransactions === "Transit") {
        state.transactions = state.myWalletData.data.transactions.filter(
          (x) => {
            return x.request_type === "transit";
          }
        );
        if (searchTransaction !== "") {
          state.transactions = state.transactions.filter((x) => {
            return x.orderNo === searchTransaction;
          });
        }
      } else if (filterTransactions === "NationWide") {
        state.transactions = state.myWalletData.data.transactions.filter(
          (x) => {
            return x.request_type === "upcountry";
          }
        );

        if (searchTransaction !== "") {
          state.transactions = state.transactions.filter((x) => {
            return x.orderNo === searchTransaction;
          });
        }
      }
    },

    offerAcceptedDocumentsAction(state, action) {
      const offerAcceptedDocuments = action.payload;
      state.offerAcceptedDocuments = offerAcceptedDocuments;
    },

    addressInfoAction(state, action) {
      const { from, to } = action.payload;
      state.addressInfoFrom = from;
      state.addressInfoTo = to;
      window.localStorage.setItem("from", JSON.stringify(from));
      window.localStorage.setItem("to", JSON.stringify(to));
    },

    chatClientAction(state, action) {
      state.chatClient = action.payload;
    },

    getContactPersonsAction(state, action) {
      const data = action.payload;
      state.filteredContactPersons = data;
      state.contactPersons = data;
    },

    filterContactPersonsAction(state, action) {
      const filteredContactPerson = action.payload;

      let filteredContactPersons = [];

      if (filteredContactPerson) {
        for (let i = 0; i < state.contactPersons.length; i++) {
          if (filteredContactPerson.phone === state.contactPersons[i].phone) {
            filteredContactPersons.push(state.contactPersons[i]);
            break;
          }
        }

        state.filteredContactPersons = filteredContactPersons;
      } else {
        state.filteredContactPersons = state.contactPersons;
      }
    },

    updateContactPersonsAction(state, action) {
      const data = action.payload;
      state.contactPersons.push(data);
    },
  },
});

export const {
  loginAction,
  logoutAction,
  tokenAction,
  proApplicationDataAction,
  allVehiclesDataAction,
  myPendingRequestsAction,
  offersReceivedAction,
  counterByUserRequestsAction,
  counterByVendorRequestsAction,
  counterAcceptedByVendorRequestsAction,
  myRejectedRequestsAction,
  waitingForAdminApprovalRequestsAction,
  activeOrdersAction,
  completedOrdersAction,
  loadingOptionsDataAction,
  unloadingOptionsDataAction,
  myWalletAction,
  filterTransactionsAction,
  offerAcceptedDocumentsAction,
  addressInfoAction,
  chatClientAction,
  getContactPersonsAction,
  updateContactPersonsAction,
  filterContactPersonsAction,
  deleteWalletAction,
} = userSlice.actions;

export default userSlice.reducer;
