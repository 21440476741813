import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  ADDRESS_INFO_API,
  ADD_CONTACT_PERSON_API,
  ADVANCE_DEPOSIT_API,
  BASE_URL,
  CALCULATE_INSURANCE_API,
  CHAT_JOIN_API,
  CREATE_REQUEST_API,
  EDIT_CONTACT_PERSON_API,
  FCM_TOKEN_API,
  GET_ALL_VEHICLES_API,
  GET_CONTACT_PERSONS_API,
  GET_COUNTER_ACCEPTED_BY_VENDOR_API,
  GET_COUNTER_BY_USER_API,
  GET_COUNTER_BY_VENDOR_API,
  GET_CREDIT_DAYS_API,
  GET_CURRENT_CREDIT_DAYS_API,
  GET_LOADING_OPTIONS_API,
  GET_MATERIALS_API,
  GET_MY_REQUESTS_API,
  GET_NOTIFICATIONS_API,
  GET_OFFER_RECEIVED_API,
  ORDER_INVOICE_API,
  GET_PROVINCES_API,
  GET_PRO_APPLICATION_API,
  GET_REJECTED_REQUESTS_API,
  GET_SINGLE_BILTY_API,
  GET_UNLOADING_OPTIONS_API,
  GET_USER_ACTIVE_REQUEST_API,
  GET_USER_COMPLETED_ORDERS_API,
  GET_USER_PENDING_REQUEST_API,
  GET_USER_PROFILE_API,
  GET_WAITING_FOR_ADMIN_APPROVAL_API,
  GET_WALLET,
  LOGIN_API,
  ORDER_ACCEPT_API,
  READ_NOTIFICATIONS_API,
  REGISTER_API,
  REGISTER_API_OTP,
  REMOVE_CONTACT_PERSON_API,
  SEARCH_ORDER_API,
  UPDATE_USER_PROFILE_API,
  UPGRADE_TO_PRO_API,
  UPLOAD_TRANSIT_CARGO_DOCUMENTS_API,
  USER_ACCEPT_VENDOR_COUNTER_QOUTE_API,
  USER_ACCEPT_VENDOR_QOUTE_API,
  USER_ADD_CONTACT_PERSON_TO_REQUEST_API,
  USER_ADD_PAYMENT_METHOD_API,
  USER_CANCEL_ORDER_API,
  USER_COUNTER_VENDOR_QOUTE_API,
  USER_GIVE_RATING_API,
  USER_REJECT_VENDOR_COUNTER_QOUTE_API,
  USER_REJECT_VENDOR_QOUTE_API,
  BILTY_INVOICE_API,
  CHANGE_USER_PASSWORD_API,
  PRO_USER_LESS_CREDITS_API,
  INVITE_FRIENDS_API,
  GET_SINGLE_OFFER_RECEIVED_API,
} from "../utils/config";

// const headers = new Headers();
// headers.append(
//   "Access-Control-Allow-Origin",
//   "https://staging-api.meribilty.com"
// );

export const userApi = createApi({
  reducerPath: "userApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // mode: "no-cors",
    credentials: "omit",
  }),
  endpoints: (builder) => ({
    signup: builder.query({
      query: (signupForm) => {
        let url = REGISTER_API;

        return {
          url,
          method: "POST",
          body: signupForm,
        };
      },
    }),
    signupOTP: builder.query({
      query: (otp) => {
        let url = REGISTER_API_OTP;

        return {
          url,
          method: "POST",
          body: otp,
        };
      },
    }),
    login: builder.query({
      query: (loginForm) => {
        let url = LOGIN_API;

        return {
          url,
          method: "POST",
          body: loginForm,
        };
      },
    }),
    getWallet: builder.query({
      query: (token) => {
        let url = GET_WALLET;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    upgradeToProApplication: builder.query({
      query: (proApplicationForm) => {
        let url = UPGRADE_TO_PRO_API;

        return {
          url,
          method: "POST",
          body: proApplicationForm,
        };
      },
    }),
    getUpgradeToProApplication: builder.query({
      query: (token) => {
        let url = GET_PRO_APPLICATION_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getAllVehicles: builder.query({
      query: () => {
        let url = GET_ALL_VEHICLES_API;

        return {
          url,
          method: "POST",
        };
      },
    }),
    getLoadingOptions: builder.query({
      query: () => {
        let url = GET_LOADING_OPTIONS_API;

        return {
          url,
          method: "POST",
        };
      },
    }),
    getUnLoadingOptions: builder.query({
      query: () => {
        let url = GET_UNLOADING_OPTIONS_API;

        return {
          url,
          method: "POST",
        };
      },
    }),
    createRequest: builder.query({
      query: (reuqestQuery) => {
        let url = CREATE_REQUEST_API;

        return {
          url,
          method: "POST",
          body: reuqestQuery,
        };
      },
    }),
    getPendingRequests: builder.query({
      query: (token) => {
        let url = GET_USER_PENDING_REQUEST_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getOfferReceivedRequests: builder.query({
      query: (token) => {
        let url = GET_OFFER_RECEIVED_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getSingleOfferReceivedRequests: builder.query({
      query: (token) => {
        let url = GET_SINGLE_OFFER_RECEIVED_API;

        return {
          url,
          method: "POST",
          body: token,
        };
      },
    }),
    getCounterByUserRequests: builder.query({
      query: (token) => {
        let url = GET_COUNTER_BY_USER_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getCounterByVendorRequests: builder.query({
      query: (token) => {
        let url = GET_COUNTER_BY_VENDOR_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getCounterAcceptedByVendorRequests: builder.query({
      query: (token) => {
        let url = GET_COUNTER_ACCEPTED_BY_VENDOR_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getRejectedRequests: builder.query({
      query: (token) => {
        let url = GET_REJECTED_REQUESTS_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getWaitingForAdminApprovalRequests: builder.query({
      query: (token) => {
        let url = GET_WAITING_FOR_ADMIN_APPROVAL_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getActiveRequests: builder.query({
      query: (token) => {
        let url = GET_USER_ACTIVE_REQUEST_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    getCompletedOrder: builder.query({
      query: (token) => {
        let url = GET_USER_COMPLETED_ORDERS_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),
    userRejectVenderQoute: builder.query({
      query: (userRejectOffer) => {
        let url = USER_REJECT_VENDOR_QOUTE_API;

        return {
          url,
          method: "POST",
          body: userRejectOffer,
        };
      },
    }),
    userCounterVenderQoute: builder.query({
      query: (userCounterOffer) => {
        let url = USER_COUNTER_VENDOR_QOUTE_API;

        return {
          url,
          method: "POST",
          body: userCounterOffer,
        };
      },
    }),

    userAcceptVenderQoute: builder.query({
      query: (userAcceptOffer) => {
        let url = USER_ACCEPT_VENDOR_QOUTE_API;

        return {
          url,
          method: "POST",
          body: userAcceptOffer,
        };
      },
    }),

    userRejectVenderCounterQoute: builder.query({
      query: (userRejectCounterOffer) => {
        let url = USER_REJECT_VENDOR_COUNTER_QOUTE_API;

        return {
          url,
          method: "POST",
          body: userRejectCounterOffer,
        };
      },
    }),

    userAcceptVenderCounterQoute: builder.query({
      query: (userAcceptCounterOffer) => {
        let url = USER_ACCEPT_VENDOR_COUNTER_QOUTE_API;

        return {
          url,
          method: "POST",
          body: userAcceptCounterOffer,
        };
      },
    }),

    userCancelOrder: builder.query({
      query: (userCancelOrder) => {
        let url = USER_CANCEL_ORDER_API;

        return {
          url,
          method: "POST",
          body: userCancelOrder,
        };
      },
    }),

    userProfileUpdate: builder.query({
      query: (userProfileForm) => {
        let url = UPDATE_USER_PROFILE_API;

        return {
          url,
          method: "POST",
          body: userProfileForm,
        };
      },
    }),

    getUserProfile: builder.query({
      query: (token) => {
        let url = GET_USER_PROFILE_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),

    calculateInsurance: builder.query({
      query: (cargoValue) => {
        let url = CALCULATE_INSURANCE_API;

        return {
          url,
          method: "POST",
          body: { cargo_value: cargoValue },
        };
      },
    }),

    getCreditDays: builder.query({
      query: () => {
        let url = GET_CREDIT_DAYS_API;

        return {
          url,
          method: "GET",
        };
      },
    }),

    getCurrentCreditDays: builder.query({
      query: (token) => {
        let url = GET_CURRENT_CREDIT_DAYS_API;

        return {
          url,
          method: "POST",
          body: token,
        };
      },
    }),

    chatJoin: builder.query({
      query: (token) => {
        let url = CHAT_JOIN_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),

    addressInfo: builder.query({
      query: (addressInfo) => {
        let url = ADDRESS_INFO_API;

        return {
          url,
          method: "POST",
          body: addressInfo,
        };
      },
    }),

    userAddPaymentMethod: builder.query({
      query: (paymentMethod) => {
        let url = USER_ADD_PAYMENT_METHOD_API;

        return {
          url,
          method: "POST",
          body: paymentMethod,
        };
      },
    }),

    getProvinces: builder.query({
      query: () => {
        let url = GET_PROVINCES_API;

        return {
          url,
          method: "GET",
        };
      },
    }),

    getMaterials: builder.query({
      query: () => {
        let url = GET_MATERIALS_API;

        return {
          url,
          method: "POST",
        };
      },
    }),

    addContactPersonToRequest: builder.query({
      query: (addContactPerson) => {
        let url = USER_ADD_CONTACT_PERSON_TO_REQUEST_API;

        return {
          url,
          method: "POST",
          body: addContactPerson,
        };
      },
    }),

    getSingleBilty: builder.query({
      query: (singleBiltyNumber) => {
        let url = GET_SINGLE_BILTY_API;

        return {
          url,
          method: "POST",
          body: singleBiltyNumber,
        };
      },
    }),

    uploadTransitCargoDocuments: builder.query({
      query: (transitCargoDocument) => {
        let url = UPLOAD_TRANSIT_CARGO_DOCUMENTS_API;

        return {
          url,
          method: "POST",
          body: transitCargoDocument,
        };
      },
    }),

    userGiveRating: builder.query({
      query: (userRating) => {
        let url = USER_GIVE_RATING_API;

        return {
          url,
          method: "POST",
          body: userRating,
        };
      },
    }),

    FCMToken: builder.query({
      query: (currentToken) => {
        let url = FCM_TOKEN_API;

        return {
          url,
          method: "POST",
          body: currentToken,
        };
      },
    }),

    getNotifications: builder.query({
      query: (token) => {
        let url = GET_NOTIFICATIONS_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),

    readNotification: builder.query({
      query: (tokenNotificationId) => {
        let url = READ_NOTIFICATIONS_API;

        return {
          url,
          method: "POST",
          body: tokenNotificationId,
        };
      },
    }),

    searchSingleOrder: builder.query({
      query: (searchSingleOrderquery) => {
        let url = SEARCH_ORDER_API;
        // console.log("Abbas",searchSingleOrderquery);

        return {
          url,
          method: "POST",
          body: searchSingleOrderquery,
        };
      },
    }),

    getMyRequests: builder.query({
      query: (token) => {
        let url = GET_MY_REQUESTS_API;

        return {
          url,
          method: "POST",
          body: { token: token },
        };
      },
    }),

    orderAccept: builder.query({
      query: (orderAcceptQuery) => {
        let url = ORDER_ACCEPT_API;

        return {
          url,
          method: "POST",
          body: orderAcceptQuery,
        };
      },
    }),

    getContactPersons: builder.query({
      query: (token) => {
        let url = GET_CONTACT_PERSONS_API;

        return {
          url,
          method: "POST",
          body: { token },
        };
      },
    }),

    addContactPerson: builder.query({
      query: (addContactPersonQuery) => {
        let url = ADD_CONTACT_PERSON_API;

        return {
          url,
          method: "POST",
          body: addContactPersonQuery,
        };
      },
    }),

    editContactPerson: builder.query({
      query: (editContactPersonQuery) => {
        let url = EDIT_CONTACT_PERSON_API;

        return {
          url,
          method: "POST",
          body: editContactPersonQuery,
        };
      },
    }),

    removeContactPerson: builder.query({
      query: (removeContactPersonQuery) => {
        let url = REMOVE_CONTACT_PERSON_API;

        return {
          url,
          method: "POST",
          body: removeContactPersonQuery,
        };
      },
    }),

    advanceDeposit: builder.query({
      query: (advanceDepositQuery) => {
        let url = ADVANCE_DEPOSIT_API;

        return {
          url,
          method: "POST",
          body: advanceDepositQuery,
        };
      },
    }),
    getOrderInvoice: builder.query({
      query: (orderInvoiceQuery) => {
        let url = ORDER_INVOICE_API;

        return {
          url,
          method: "POST",
          body: orderInvoiceQuery,
        };
      },
    }),

    getBiltyInvoice: builder.query({
      query: (biltyInvoiceQuery) => {
        let url = BILTY_INVOICE_API;

        return {
          url,
          method: "POST",
          body: biltyInvoiceQuery,
        };
      },
    }),

    changeUserPassword: builder.query({
      query: (changeUserPasswordQuery) => {
        let url = CHANGE_USER_PASSWORD_API;

        return {
          url,
          method: "POST",
          body: changeUserPasswordQuery,
        };
      },
    }),

    proUserLessCredits: builder.query({
      query: (proUserLessCreditsQuery) => {
        let url = PRO_USER_LESS_CREDITS_API;

        return {
          url,
          method: "POST",
          body: proUserLessCreditsQuery,
        };
      },
    }),

    inviteFriend: builder.query({
      query: (inviteFriendQuery) => {
        let url = INVITE_FRIENDS_API;

        return {
          url,
          method: "POST",
          body: inviteFriendQuery,
        };
      },
    }),
  }),
});

export const {
  useSignupQuery,
  useSignupOTPQuery,
  useLoginQuery,
  useGetWalletQuery,
  useUpgradeToProApplicationQuery,
  useGetUpgradeToProApplicationQuery,
  useGetAllVehiclesQuery,
  useCreateRequestQuery,
  useGetPendingRequestsQuery,
  useGetOfferReceivedRequestsQuery,
  useGetCounterByUserRequestsQuery,
  useGetCounterByVendorRequestsQuery,
  useGetCounterAcceptedByVendorRequestsQuery,
  useGetRejectedRequestsQuery,
  useGetWaitingForAdminApprovalRequestsQuery,
  useGetActiveRequestsQuery,
  useGetCompletedOrderQuery,
  useUserRejectVenderQouteQuery,
  useUserCounterVenderQouteQuery,
  useUserAcceptVenderQouteQuery,
  useUserRejectVenderCounterQouteQuery,
  useUserAcceptVenderCounterQouteQuery,
  useUserCancelOrderQuery,
  useUserProfileUpdateQuery,
  useGetUserProfileQuery,
  useGetLoadingOptionsQuery,
  useGetUnLoadingOptionsQuery,
  useCalculateInsuranceQuery,
  useGetCreditDaysQuery,
  useGetCurrentCreditDaysQuery,
  useChatJoinQuery,
  useAddressInfoQuery,
  useUserAddPaymentMethodQuery,
  useGetProvincesQuery,
  useAddContactPersonToRequestQuery,
  useGetSingleBiltyQuery,
  useUploadTransitCargoDocumentsQuery,
  useGetMaterialsQuery,
  useUserGiveRatingQuery,
  useFCMTokenQuery,
  useGetNotificationsQuery,
  useReadNotificationQuery,
  useSearchSingleOrderQuery,
  useGetMyRequestsQuery,
  useOrderAcceptQuery,
  useGetContactPersonsQuery,
  useAddContactPersonQuery,
  useEditContactPersonQuery,
  useRemoveContactPersonQuery,
  useAdvanceDepositQuery,
  useGetOrderInvoiceQuery,
  useGetBiltyInvoiceQuery,
  useChangeUserPasswordQuery,
  useProUserLessCreditsQuery,
  useInviteFriendQuery,
  useGetSingleOfferReceivedRequestsQuery,
} = userApi;
