import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const VehiclesTypeCardContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "white",
  margin: "0.5rem",
  padding: "1rem",
  img: {
    width: "8rem",
    height: "8rem",
    objectFit: "contain",
  },

  [theme.breakpoints.down("md")]: {
    margin: "0.5rem 0",
  },
}));

const VehiclesTypeCard = ({ vehicle }) => {
  const [openWeights, setOpenWeights] = useState(false);

  return (
    <>
      <VehiclesTypeCardContainer onClick={() => setOpenWeights(true)}>
        <img src={vehicle.vehicle_image} />
        <Typography>Name: {vehicle.vehicle_type}</Typography>
        <Typography>Max Weight: {vehicle.vehicle_limit} Tons</Typography>
        <Typography
          sx={{
            color: "var(--primary)",
            textDecoration: "underLine",
            cursor: "pointer",
          }}
        >
          See weight limits range
        </Typography>
      </VehiclesTypeCardContainer>

      {openWeights && (
        <Dialog open={openWeights} onClose={() => setOpenWeights(false)}>
          <DialogTitle>{vehicle.vehicle_type} weight limit</DialogTitle>
          <DialogContent>
            {vehicle?.weights?.map((weight, index) => (
              <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    backgroundColor: "var(--primary)",
                    borderRadius: "50%",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "1rem" }}>{weight} Tons</Typography>
              </Stack>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default VehiclesTypeCard;
