import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//Staging
// const firebaseConfig = {
//   apiKey: "AIzaSyCbBTHsKQJC2d_BZ0efR-1BK-FSuQNKFKk",
//   authDomain: "meribilty-staging.firebaseapp.com",
//   databaseURL:
//     "https://meribilty-staging-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "meribilty-staging",
//   storageBucket: "meribilty-staging.appspot.com",
//   messagingSenderId: "446195242927",
//   appId: "1:446195242927:web:db4e4ee21aa8e20852015a",
//   measurementId: "G-VKYKJH5SZS",
// };

//Live
const firebaseConfig = {
  apiKey: "AIzaSyCOep7LqctzV93Ec1Idf-J7JcDTTd0XOno",
  authDomain: "meribilty-331311.firebaseapp.com",
  databaseURL:
    "https://meribilty-331311-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "meribilty-331311",
  storageBucket: "meribilty-331311.appspot.com",
  messagingSenderId: "330892733913",
  appId: "1:330892733913:web:876bca426a69cb94dd7dc8",
  measurementId: "G-PYKEF9NJP0",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, auth };
