import {
  Button,
  Divider,
  Grid,
  ListItem,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import LoadingUnloadingCard from "./LoadingUnloadingCard";
import VehicleCard from "./VehicleCard";

const BiltyListItemContainer = styled(Stack)({
  width: "100%",
  alignItems: "center",
});

const ManageTrackBiltiesCard = ({
  setSingleBiltyNum,
  setskipSingleBilty,
  vehicle,
  setSingleBiltyOpen,
}) => {
  const dispatch = useDispatch();

  const [loadingOptions, setloadingOptions] = useState([]);
  const [unLoadingOptions, setUnLoadingOptions] = useState([]);

  useEffect(() => {
    let tempLoadingOptions = [];
    let tempUnLoadingOptions = [];

    if (
      vehicle.type === "loading/unloading" &&
      vehicle?.loading_options?.length > 0
    ) {
      for (let i = 0; i < vehicle.loading_options.length; i++) {
        if (
          !tempLoadingOptions.some(
            (e) => e.name === vehicle.loading_options[i].name
          )
        ) {
          tempLoadingOptions.push({
            ...vehicle.loading_options[i],
            quantity: 0,
          });
        }
      }

      for (let i = 0; i < tempLoadingOptions.length; i++) {
        for (let j = 0; j < vehicle.loading_options.length; j++) {
          if (tempLoadingOptions[i].name === vehicle.loading_options[j].name) {
            tempLoadingOptions[i].quantity++;
          }
        }
      }

      setloadingOptions(tempLoadingOptions);
    }

    if (
      vehicle.type === "loading/unloading" &&
      vehicle?.unloading_options?.length > 0
    ) {
      for (let i = 0; i < vehicle.unloading_options.length; i++) {
        if (
          !tempUnLoadingOptions.some(
            (e) => e.name === vehicle.unloading_options[i].name
          )
        ) {
          tempUnLoadingOptions.push({
            ...vehicle.unloading_options[i],
            quantity: 0,
          });
        }
      }

      for (let i = 0; i < tempUnLoadingOptions.length; i++) {
        for (let j = 0; j < vehicle.unloading_options.length; j++) {
          if (
            tempUnLoadingOptions[i].name === vehicle.unloading_options[j].name
          ) {
            tempUnLoadingOptions[i].quantity++;
          }
        }
      }

      setUnLoadingOptions(tempUnLoadingOptions);
    }
  }, []);

  const loadingUnloadingLength =
    loadingOptions.length + unLoadingOptions.length;

  return (
    <>
      {vehicle.type === "vehicle" && (
        <ListItem
          button
          sx={{ paddingBottom: "1rem" }}
          onClick={() => {
            setSingleBiltyNum(vehicle.biltyNo);
            dispatch(isFetchingSomethingAction(true));
            setskipSingleBilty(false);
          }}
        >
          {vehicle.type === "vehicle" && (
            <BiltyListItemContainer direction="row" spacing={3}>
              <VehicleCard
                vehicle={vehicle}
                sxdetails={{ flex: "1" }}
                sxImage={{ img: { width: "5rem", height: "5rem" } }}
                showButton
              />

              <Button
                variant="contained"
                sx={{
                  alignSelf: "center",
                  // fontWeight: "600",
                  display: { xs: "none", lg: "inherit" },
                }}
                onClick={() => {
                  // setSingleBilty(vehicle);
                  // setSingleBiltyOpen(true);
                }}
              >
                Details
              </Button>
            </BiltyListItemContainer>
          )}
        </ListItem>
      )}

      {vehicle.type === "vehicle" && <Divider />}

      {vehicle.type !== "vehicle" && (
        <Stack
          direction="row"
          sx={{
            flexWrap: { xs: "wrap", lg: "nowrap" },
            justifyContent: { xs: "center" },
            marginTop: "1rem",
          }}
        >
          {vehicle.type === "loading/unloading" &&
            vehicle.loading_options &&
            loadingOptions.map((loading, index) => (
              <LoadingUnloadingCard
                vehicle={vehicle}
                loadingUnloading={loading}
                setSingleBiltyOpen={setSingleBiltyOpen}
                sxImage={{ img: { width: "5rem", height: "5rem" } }}
                loadingUnloadingText={"Loading"}
                loadingUnloadingLength={loadingUnloadingLength}
              />
            ))}

          {vehicle.type === "loading/unloading" &&
            vehicle.unloading_options &&
            unLoadingOptions.map((unloading, index) => (
              <LoadingUnloadingCard
                vehicle={vehicle}
                loadingUnloading={unloading}
                setSingleBiltyOpen={setSingleBiltyOpen}
                sxImage={{ img: { width: "5rem", height: "5rem" } }}
                loadingUnloadingText={"Unloading"}
                loadingUnloadingLength={loadingUnloadingLength}
              />
            ))}
        </Stack>
      )}
    </>
  );
};

export default ManageTrackBiltiesCard;
