import React, { useState } from "react";
import ButtonComponent from "../../utils/ButtonComponent";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneInput from "react-phone-input-2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import { useSignupQuery } from "../../services/userService";
import { toast } from "react-toastify";
import { notifyError } from "../../utils/notifyMessage";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import { useDispatch } from "react-redux";

const SignupForm = ({
  name,
  setName,
  phone,
  setPhone,
  email,
  setEmail,
  password,
  setPassword,
  showPassword,
  setShowPassword,
  confirmPassword,
  setConfirmPassword,
  showConfirmPassword,
  setShowConfirmPassword,
  setShowLoginForm,
  setShowOtp,
  setOtp,
  setShowOTPFloatingButton,
}) => {
  const dispatch = useDispatch();
  const [signupSkip, setSignupSkip] = useState(true);
  const [proUser, setProUser] = useState(false);

  //SIGNUP API REQUEST **********************

  const signupQuery = {
    fullname: name,
    phone: phone,
    email: email,
    password: password,
    pro: proUser,
  };

  const { data: signupData, isFetching: isSignupFetching } = useSignupQuery(
    signupQuery,
    { skip: signupSkip }
  );

  const submitSignupForm = (e) => {
    e.preventDefault();
    toast.dismiss();

    if (password.length < 6) {
      notifyError("Password must be atleast 6 characters");
    } else if (password !== confirmPassword) {
      notifyError("Confirm password not matched");
    } else {
      dispatch(isFetchingSomethingAction(true));
      setSignupSkip(false);
    }
  };

  useDidMountEffect(() => {
    if (isSignupFetching === false && signupData) {
      if (signupData.status) {
        setShowOTPFloatingButton(true);
        setShowOtp(true);
        setSignupSkip(true);
        setOtp(signupData.otp.toString());
        dispatch(isFetchingSomethingAction(false));
      } else {
        notifyError(signupData.error);
        notifyError(signupData.message);
        setSignupSkip(true);
      }
      dispatch(isFetchingSomethingAction(false));
    }

    if (isSignupFetching === false && !signupData) {
      setSignupSkip(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [isSignupFetching]);

  return (
    <form action="" className="userForm" onSubmit={submitSignupForm}>
      <h1>Sign Up</h1>
      <div className="inputBox">
        <p>Name</p>
        <div>
          <PersonIcon />
          <input
            className="inputField"
            type="text"
            placeholder="Name"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="inputBox">
        <p>Phone</p>
        <div>
          <PhoneInput
            countryCodeEditable={false}
            className="inputField"
            placeholder="Enter phone number"
            country={"pk"}
            value={phone}
            onChange={(e) => setPhone("+" + e)}
          />
        </div>
      </div>
      <div className="inputBox">
        <p>Email</p>
        <div>
          <EmailIcon />
          <input
            className="inputField"
            type="text"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="inputBox">
        <p>Password</p>
        <div>
          <LockIcon />
          <input
            className="inputField"
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          {!showPassword && (
            <VisibilityIcon
              onClick={() => setShowPassword(true)}
              style={{
                right: "2rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            />
          )}
          {showPassword && (
            <VisibilityOffIcon
              onClick={() => setShowPassword(false)}
              style={{
                right: "2rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            />
          )}
        </div>
      </div>
      <div className="inputBox">
        <p>Confirm Password</p>
        <div>
          <LockIcon />
          <input
            className="inputField"
            type={`${showConfirmPassword ? "text" : "password"}`}
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />

          {!showConfirmPassword && (
            <VisibilityIcon
              onClick={() => setShowConfirmPassword(true)}
              style={{
                right: "2rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            />
          )}
          {showConfirmPassword && (
            <VisibilityOffIcon
              onClick={() => setShowConfirmPassword(false)}
              style={{
                right: "2rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            />
          )}
        </div>
      </div>

      {/* <RadioGroup
              className="normalProUserContainer"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={"normal"}
            >
              <FormControlLabel
                className="normalProUserButton"
                value="normal"
                control={<Radio style={{ color: "white" }} />}
                label="Normal User"
                onClick={() => setProUser(false)}
              />
              <FormControlLabel
                className="normalProUserButton"
                value="pro"
                control={<Radio style={{ color: "white" }} />}
                label="Pro User"
                onClick={() => setProUser(true)}
              />
            </RadioGroup> */}

      {/* <div className="normalProUserContainer">
              <div className="normalProUserButton">
                <Typography>Pro User</Typography>
                <Switch
                  checked={proUser}
                  onChange={(e) => setProUser(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <p>More details about Pro user?</p>
            </div> */}

      <ButtonComponent
        type={"submit"}
        text={"Sign Up"}
        className="signUpButton"
      />

      <p className="haveAccount" onClick={() => setShowLoginForm(true)}>
        Already have an account?<b> Login</b>
      </p>
    </form>
  );
};

export default SignupForm;
