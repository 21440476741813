import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  useChangeUserPasswordQuery,
  useGetUserProfileQuery,
  useUserProfileUpdateQuery,
} from "../../services/userService";
import useDidMountEffect from "../../utils/useDidMountEffect";
import { loginAction } from "../../sliceReducers/userSlice";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import APIhandler from "../../utils/APIhandler";
import { notifyError, notifySuccess } from "../../utils/notifyMessage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loader from "../../utils/Loader";

const MyProfile = styled(Stack)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "var(--boxShadow)",
  width: "50%",
  borderRadius: "10px",

  boxSizing: "border-box",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    boxShadow: "none",
    borderRadius: "0px",
    borderTop: "1px solid var(--borderGrey)",
    borderBottom: "1px solid var(--borderGrey)",
  },
}));

const MySettings = styled(Stack)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "var(--boxShadow)",
  width: "50%",
  borderRadius: "10px",
  a: {
    textDecoration: "none",
    color: "var(--black)",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    boxShadow: "none",
    borderRadius: "0px",
    border: "1px solid var(--borderGrey)",
  },
}));

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Settings = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.userData);
  const { isFetchingSomething } = useSelector((state) => state.utils);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [skipUpdateProfile, setSkipUpdateProfile] = useState(true);
  const [skipUserProfile, setSkipUserProfile] = useState(true);
  const [skipChangePassword, setSkipChangePassword] = useState(true);
  const [updateProfileOpen, setUpdateProfileOpen] = useState(false);
  const [toBeUpdatedNumber, setToBeUpdatedNumber] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  //   const [openTermsAndCond, setOpenTermsAndCond] = useState(false);
  //   const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  //   const [openFAQs, setOpenFAQs] = useState(false);
  //   const [openChangePassword, setOpenChangePassword] = useState(false);
  //   const [openContactUs, setOpenContactUs] = useState(false);

  const settingsButtons = [
    {
      primary: "Terms & Conditions",
      link: "https://meribilty.com/termsofservice/",
    },
    {
      primary: "Privacy Policy",
      link: "https://meribilty.com/privacy-policy/",
    },
    {
      primary: "FAQs",
      link: "https://meribilty.com/faq/",
    },

    {
      primary: "Contact Us",
      link: "https://meribilty.com",
    },
  ];

  const myProfileData = [
    { name: "Full name", icon: <PersonIcon />, value: user.fullname },
    { name: "Email", icon: <EmailIcon />, value: user.email },
    {
      name: "Change password",
      icon: <LockIcon />,
      value: "Click to change password",
    },
    { name: "Phone", icon: <LocalPhoneIcon />, value: user.phone },
  ];

  let UpdateData = {
    token: token,
    fullname: fullName,
    email: email,
  };

  const {
    data: updateProfileResponse,
    isFetching: updateProfileFetching,
    error: updateProfileError,
  } = useUserProfileUpdateQuery(UpdateData, {
    skip: skipUpdateProfile,
  });

  useDidMountEffect(() => {
    if (
      updateProfileFetching === false &&
      updateProfileResponse &&
      !updateProfileError
    ) {
      setSkipUpdateProfile(true);
      setSkipUserProfile(false);
    }
  }, [updateProfileFetching]);

  const {
    data: userProfileResponse,
    isFetching: userProfileFetching,
    error: userProfileError,
  } = useGetUserProfileQuery(token, {
    skip: skipUserProfile,
  });

  useDidMountEffect(() => {
    if (
      userProfileFetching === false &&
      userProfileResponse &&
      !userProfileError
    ) {
      dispatch(loginAction({ user: userProfileResponse.data }));
      dispatch(isFetchingSomethingAction(false));
      setSkipUserProfile(true);
      handleUpdateProfileClose();
    }
  }, [userProfileFetching]);

  const handleUpdateProfileOpen = () => {
    setUpdateProfileOpen(true);
  };

  const handleUpdateProfileClose = () => {
    setUpdateProfileOpen(false);
  };

  useEffect(() => {
    setFullName(user.fullname);
    setEmail(user.email);
  }, [user]);

  const handleUpdateProfile = () => {
    if (toBeUpdatedNumber !== 2) {
      dispatch(isFetchingSomethingAction(true));
      setSkipUpdateProfile(false);
    } else {
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        notifyError("Fill all fields");
      } else if (newPassword.length < 6) {
        notifyError("Password should be greater than 6 characters");
      } else if (newPassword !== confirmNewPassword) {
        notifyError("New password and confirm new password not matched");
      } else {
        dispatch(isFetchingSomethingAction(true));
        setSkipChangePassword(false);
      }
    }
  };

  let changeUserPasswordQuery = {
    token,
    oldPassword,
    newPassword,
    confirmNewPassword,
  };

  const {
    data: changeUserPasswordResponse,
    isFetching: changeUserPasswordFetching,
    error: changeUserPasswordError,
  } = useChangeUserPasswordQuery(changeUserPasswordQuery, {
    skip: skipChangePassword,
  });

  return (
    <>
      {isFetchingSomething && <Loader />}
      <APIhandler
        responseData={changeUserPasswordResponse}
        isFetching={changeUserPasswordFetching}
        serverError={changeUserPasswordError}
        onStatusTrue={() => {
          dispatch(isFetchingSomethingAction(false));
          setSkipChangePassword(true);
          notifySuccess(changeUserPasswordResponse.message);
          setUpdateProfileOpen(false);
        }}
        onStatusFalse={() => {
          dispatch(isFetchingSomethingAction(false));
          setSkipChangePassword(true);
          notifyError(changeUserPasswordResponse.message);
        }}
        onServerError={() => {}}
      />

      <Stack
        spacing={2}
        direction={{ xs: "column" }}
        sx={{
          alignItems: { xs: "center" },
        }}
      >
        <MyProfile sx={{ marginTop: { md: "2rem" } }}>
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: "600",
              padding: "1rem",
              paddingBottom: "0",
            }}
          >
            General profile settings
          </Typography>

          <List>
            {myProfileData.map((profileData, index, arr) => (
              <Stack>
                <Divider />
                <ListItemButton
                  disableRipple={profileData.name === "Phone" && true}
                  onClick={() => {
                    if (profileData.name === "Full name") {
                      setToBeUpdatedNumber(0);
                    } else if (profileData.name === "Email") {
                      setToBeUpdatedNumber(1);
                    } else if (profileData.name === "Change password") {
                      setToBeUpdatedNumber(2);
                    }

                    if (profileData.name !== "Phone") {
                      handleUpdateProfileOpen();
                    }
                  }}
                  sx={{
                    justifyContent: "space-between",
                    ":hover": {
                      backgroundColor:
                        profileData.name === "Phone" && "transparent",
                    },
                  }}
                >
                  <Stack direction="row" sx={{ alignItems: "flex-end" }}>
                    <ListItemIcon
                      sx={{ marginBottom: "0.25rem", color: "var(--primary)" }}
                    >
                      {profileData.icon}
                    </ListItemIcon>
                    <Stack>
                      <ListItemText secondary={profileData.name} />
                      <ListItemText
                        sx={{
                          color:
                            profileData.name === "Phone" && "var(--textGrey)",
                        }}
                        primary={profileData.value}
                      />
                    </Stack>
                  </Stack>

                  {profileData.name !== "Phone" && (
                    <Typography sx={{ color: "var(--textGrey)" }}>
                      Edit
                    </Typography>
                  )}
                </ListItemButton>
              </Stack>
            ))}
          </List>
        </MyProfile>

        <MySettings>
          <List>
            {settingsButtons.map((settingsButton, index, arr) => (
              <Box key={index}>
                <a
                  href={settingsButton.link}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Stack>
                    <ListItemButton
                      onClick={() => console.log("Clicked")}
                      sx={{ padding: "1rem" }}
                    >
                      <ListItemText primary={settingsButton.primary} />
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  </Stack>
                  {index !== arr.length - 1 && <Divider />}
                </a>
              </Box>
            ))}
          </List>
        </MySettings>
      </Stack>

      <Dialog
        maxWidth={"xl"}
        open={updateProfileOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleUpdateProfileClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle alignSelf={"center"}>
          {toBeUpdatedNumber === 0
            ? "Update your name"
            : toBeUpdatedNumber === 1
            ? "Update your Email"
            : toBeUpdatedNumber === 2 && "Update your Password"}
        </DialogTitle>

        <DialogContent
          className="myCounterDialogBox"
          style={{ paddingBottom: "0" }}
        >
          {toBeUpdatedNumber === 0 && (
            <TextField
              id="outlined-basic"
              label="Full name"
              variant="outlined"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
              style={{ margin: "0.5rem", width: "100%" }}
            />
          )}

          {toBeUpdatedNumber === 1 && (
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{ margin: "0.5rem", width: "100%" }}
            />
          )}

          {toBeUpdatedNumber === 2 && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <>
                      {!showOldPassword && (
                        <VisibilityIcon
                          onClick={() => setShowOldPassword(true)}
                          style={{
                            right: "2rem",
                            cursor: "pointer",
                            pointerEvents: "auto",
                            color: "var(--textGrey)",
                          }}
                        />
                      )}
                      {showOldPassword && (
                        <VisibilityOffIcon
                          onClick={() => setShowOldPassword(false)}
                          style={{
                            right: "2rem",
                            cursor: "pointer",
                            pointerEvents: "auto",
                            color: "var(--textGrey)",
                          }}
                        />
                      )}
                    </>
                  ),
                }}
                id="outlined-basic"
                label="Old password"
                variant="outlined"
                required
                type={!showOldPassword ? "password" : "text"}
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                sx={{ width: "100%", marginTop: "0.5rem" }}
              />

              <TextField
                InputProps={{
                  endAdornment: (
                    <>
                      {!showNewPassword && (
                        <VisibilityIcon
                          onClick={() => setShowNewPassword(true)}
                          style={{
                            right: "2rem",
                            cursor: "pointer",
                            pointerEvents: "auto",
                            color: "var(--textGrey)",
                          }}
                        />
                      )}
                      {showNewPassword && (
                        <VisibilityOffIcon
                          onClick={() => setShowNewPassword(false)}
                          style={{
                            right: "2rem",
                            cursor: "pointer",
                            pointerEvents: "auto",
                            color: "var(--textGrey)",
                          }}
                        />
                      )}
                    </>
                  ),
                }}
                id="outlined-basic"
                label="New password"
                variant="outlined"
                required
                type={!showNewPassword ? "password" : "text"}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                sx={{ width: "100%" }}
              />

              <TextField
                InputProps={{
                  endAdornment: (
                    <>
                      {!showConfirmNewPassword && (
                        <VisibilityIcon
                          onClick={() => setShowConfirmNewPassword(true)}
                          style={{
                            right: "2rem",
                            cursor: "pointer",
                            pointerEvents: "auto",
                            color: "var(--textGrey)",
                          }}
                        />
                      )}
                      {showConfirmNewPassword && (
                        <VisibilityOffIcon
                          onClick={() => setShowConfirmNewPassword(false)}
                          style={{
                            right: "2rem",
                            cursor: "pointer",
                            pointerEvents: "auto",
                            color: "var(--textGrey)",
                          }}
                        />
                      )}
                    </>
                  ),
                }}
                id="outlined-basic"
                label="Confirm new password"
                variant="outlined"
                required
                type={!showConfirmNewPassword ? "password" : "text"}
                value={confirmNewPassword}
                onChange={(e) => {
                  setconfirmNewPassword(e.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </Stack>
          )}
        </DialogContent>

        <DialogActions className="myCounterDialogBox">
          <>
            <Button
              className="myRejectAcceptDialogBoxYes"
              onClick={handleUpdateProfile}
            >
              Update
            </Button>

            <Button
              className="myRejectAcceptDialogBoxNo"
              onClick={handleUpdateProfileClose}
            >
              Cancel
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Settings;
