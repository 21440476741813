import React, { useState } from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Loader from "../../utils/Loader";
import CloseIcon from "@mui/icons-material/Close";
import useDidMountEffect from "../../utils/useDidMountEffect";
import {
  useGetMyRequestsQuery,
  useSearchSingleOrderQuery,
} from "../../services/userService";
import { useSelector } from "react-redux";
import { delaySkip } from "../../utils/commonFunctions";
import MyRequestCard from "../MyRequests/MyRequestCard";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MyDeliveryCard from "../MyDeliveries/MyDeliveryCard";
import SingleRequestContainer from "../MyRequests/SingleRequestContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchRequest = () => {
  const [skipSearchSingleOrder, setSkipSearchSingleOrder] = useState(true);
  const [orderNo, setOrderNo] = useState("");
  const [singleOrder, setSingleOrder] = useState();
  const [statusColor, setStatusColor] = useState("");
  const [statusText, setStatusText] = useState("");
  const [myRequestNumbers, setMyRequestNumbers] = useState([]);

  const { token } = useSelector((state) => state.userData);

  let searchSingleOrderquery = {
    token,
    orderNo,
  };

  const {
    data: searchSingleOrderResponse,
    error: searchSingleOrderError,
    isFetching: searchSingleOrderFetching,
  } = useSearchSingleOrderQuery(searchSingleOrderquery, {
    skip: skipSearchSingleOrder,
  });

  useDidMountEffect(() => {
    if (
      !searchSingleOrderFetching &&
      searchSingleOrderResponse &&
      !searchSingleOrderError
    ) {
      if (searchSingleOrderResponse.status !== false) {
        setSkipSearchSingleOrder(true);
        setSingleOrder(searchSingleOrderResponse);
      } else if (searchSingleOrderResponse.status === false) {
        setSingleOrder(searchSingleOrderResponse);
        setSkipSearchSingleOrder(true);
      }
    }

    if (!searchSingleOrderFetching && searchSingleOrderError) {
      setSkipSearchSingleOrder(true);
    }
  }, [searchSingleOrderFetching]);

  //Adding delay in startPrice input because the price are real time
  useDidMountEffect(() => {
    //user is "finished typing," do something
    const doneTyping = () => {
      setSkipSearchSingleOrder(false);
    };

    if (orderNo) {
      delaySkip("searchOrder", doneTyping);
    } else {
      setSkipSearchSingleOrder(true);
    }
  }, [orderNo]);

  useDidMountEffect(() => {
    switch (singleOrder?.status) {
      case "pending":
        setStatusText("Finding Best Rates");
        setStatusColor("var(--primary)");
        break;
      case "offerReceived":
        setStatusText("Offers Received");
        setStatusColor("var(--QouteReceived2)");
        break;
      case "userCounter":
        setStatusText("Counter offer sent");
        setStatusColor("var(--CounterOfferReceived2)");
        break;
      case "vendorCounter":
        setStatusText("Counter By Vendor");
        setStatusColor("var(--CounterOfferReceived)");
        break;
      case "rejected":
        setStatusText("Rejected");
        setStatusColor("var(--BrightRed)");
        break;
      case "accepted":
        setStatusText("Active Orders");
        setStatusColor("var(--yellowAbmer)");
        break;
      case "qoute_accepted":
        setStatusText("Waiting for admin approval");
        setStatusColor("var(--Black)");
        break;
      case "user_counter_accepted":
        setStatusText("Accepted by vendor");
        setStatusColor("var(--CounterOfferReceived)");
        break;
      case "completed":
        setStatusText("Completed Orders");
        setStatusColor("var(--SuccessDark)");
        break;

      default:
        setStatusText("");
        setStatusColor("");
        break;
    }
  }, [singleOrder]);

  const {
    data: getMyRequestsResponse,
    error: getMyRequestsError,
    isFetching: getMyRequestsFetching,
  } = useGetMyRequestsQuery(token);

  useDidMountEffect(() => {
    if (
      !getMyRequestsFetching &&
      getMyRequestsResponse &&
      !getMyRequestsError
    ) {
      let tempData = [{}];
      if (getMyRequestsResponse?.data) {
        getMyRequestsResponse.data.forEach((request) => {
          tempData.push({ reqNo: request.orderNo, status: request.status });
        });

        setMyRequestNumbers(tempData);
      }
    }
  }, [getMyRequestsFetching]);

  return (
    <>
      {searchSingleOrderFetching && <Loader />}
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: { xs: "90%", md: "initial" },
          margin: { xs: "auto", md: "initial" },
          justifyContent: { md: "space-around" },
          alignItems: { md: "flex-start" },
          marginTop: { md: "2rem !important" },
        }}
      >
        <TextField
          id="searchOrder"
          label="Enter order number"
          variant="outlined"
          value={orderNo}
          onChange={(e) => {
            setOrderNo(e.target.value);
          }}
          style={{ margin: "1rem 0" }}
        />

        <SingleRequestContainer
          icon={<SearchRoundedIcon />} // Change
          headingText={"Search Result"} // Change
          headingColor={"var(--primary)"}
        >
          {singleOrder &&
            orderNo &&
            (singleOrder.status === "pending" ||
              singleOrder.status === "offerReceived" ||
              singleOrder.status === "userCounter" ||
              singleOrder.status === "vendorCounter" ||
              singleOrder.status === "qoute_accepted" ||
              singleOrder.status === "user_counter_accepted" ||
              singleOrder.status === "rejected") && (
              <Box
                sx={{
                  border: "1px solid var(--borderGrey)",
                  borderRadius: "5px",
                  width: { md: "30vw", xs: "95vw" },
                  alignSelf: "center",
                  backgroundColor: "white",
                }}
              >
                <MyRequestCard // Props Change
                  myRequest={singleOrder?.data}
                  index={0}
                  statusText={statusText}
                  statusColor={statusColor}
                  findingRates={singleOrder.status === "pending" ? true : false}
                  offerReceived={
                    singleOrder.status === "offerReceived" ? true : false
                  }
                  offerCounterByVendor={
                    singleOrder.status === "vendorCounter" ||
                    singleOrder.status === "user_counter_accepted"
                      ? true
                      : false
                  }
                />
              </Box>
            )}

          {singleOrder &&
            orderNo &&
            (singleOrder.status === "accepted" ||
              singleOrder.status === "completed") && (
              <Box
                sx={{
                  border: "1px solid var(--borderGrey)",
                  borderRadius: "5px",
                  width: { md: "30vw", xs: "95vw" },
                  alignSelf: "center",
                  backgroundColor: "white",
                }}
              >
                <MyDeliveryCard // Props Change
                  myRequest={singleOrder?.data}
                  index={0}
                  statusText={statusText}
                  statusColor={statusColor}
                  orderCompleted={
                    singleOrder.status === "completed" ? true : false
                  }
                />
              </Box>
            )}

          {!searchSingleOrderFetching && singleOrder?.message && (
            <Typography sx={{ textAlign: "center", fontSize: "1.5rem" }}>
              {singleOrder?.message}
            </Typography>
          )}
        </SingleRequestContainer>

        <Stack
          spacing={0.5}
          sx={{
            border: "1px solid grey",
            // maxHeight: "80vh",
            marginBottom: "2rem",
          }}
        >
          <Stack
            sx={{
              padding: "1rem",
              backgroundColor: "white",
              borderBottom: "1px solid grey",
            }}
          >
            <Typography
              sx={{
                font: "500 1.2rem Poppins",
              }}
            >
              My Order Numbers
            </Typography>
            <Typography
              sx={{
                font: "500 0.9rem Poppins",
                backgroundColor: "white",
              }}
            >
              Total {myRequestNumbers?.length}
            </Typography>
          </Stack>

          <Stack>
            {myRequestNumbers.map((requestNumber, index, arr) => {
              let status = "";
              if (requestNumber.status === "pending") {
                status = "Pending";
              } else if (requestNumber.status === "accepted") {
                status = "Active";
              } else if (requestNumber.status === "qoute_accepted") {
                status = "Waiting for admin approval";
              } else if (requestNumber.status === "qoute_rejected") {
                status = "Qoute Rejected";
              } else if (requestNumber.status === "user_counter_rejected") {
                status = "User Counter Rejected";
              } else if (requestNumber.status === "user_counter_accepted") {
                status = "Accepted by vendor";
              } else if (requestNumber.status === "completed") {
                status = "Completed";
              } else if (requestNumber.status === "cancelled") {
                status = "Cancelled";
              }

              return (
                <Stack>
                  {index === 0 && (
                    <Typography
                      sx={{
                        padding: "0 1rem",
                        font: "500 1.2rem Roboto",
                        color: "var(--SuccessDark)",
                        // borderBottom: "1px solid grey",
                      }}
                    >
                      Start
                    </Typography>
                  )}

                  <Stack
                    direction="row"
                    sx={{
                      borderBottom: "1px solid grey",

                      cursor: "pointer",
                      ":hover": {
                        color: "var(--primary)",
                      },
                    }}
                  >
                    <Typography
                      onClick={() => {
                        setOrderNo(requestNumber.reqNo);
                        setSkipSearchSingleOrder(false);
                      }}
                      sx={{
                        letterSpacing: "0.25rem",
                        fontFamily: "Roboto",
                        padding: "0 1rem",
                      }}
                    >
                      {requestNumber.reqNo}
                    </Typography>

                    <Typography
                      onClick={() => {
                        setOrderNo(requestNumber.reqNo);
                        setSkipSearchSingleOrder(false);
                      }}
                      sx={{
                        letterSpacing: "0.25rem",
                        fontFamily: "Roboto",
                      }}
                    >
                      {status}
                    </Typography>
                  </Stack>

                  {index === arr.length - 1 && (
                    <Typography
                      sx={{
                        padding: "0 1rem",
                        font: "500 1.2rem Roboto",
                        color: "var(--SuccessDark)",
                      }}
                    >
                      End
                    </Typography>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchRequest;
