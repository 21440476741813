import { Button, Stack, styled, Typography } from "@mui/material";
import React from "react";

const CustomTypography = styled(Typography)({
  b: {
    fontWeight: "600",
  },
});

const LoadingUnloadingCard = ({
  loadingUnloading,
  setSingleBiltyOpen,
  vehicle,
  hideButton,
  sxImage,
  loadingUnloadingText,
  loadingUnloadingLength,
}) => {
  return (
    <Stack
      sx={{
        border: "1px solid var(--borderGrey)",
        padding: "1rem",
        alignItems: "center",
        margin: { xs: "0.5rem", md: "1rem" },
        borderRadius: "20px",
        height: "12rem",
        width: { md: "20rem", xs: loadingUnloadingLength > 1 ? "30%" : "100%" },
      }}
    >
      <Typography variant="h5">{loadingUnloadingText}</Typography>
      <Stack sx={sxImage}>
        <img
          src={loadingUnloading.image}
          alt="Order image"
          style={{
            objectFit: "contain",
          }}
        />
      </Stack>
      <Stack>
        <CustomTypography>
          <b>Name: </b>
          {loadingUnloading.name}
        </CustomTypography>
        <CustomTypography>
          <b>Quantity: </b>
          {loadingUnloading.quantity}
        </CustomTypography>
        {loadingUnloading.name !== "Labour" && (
          <CustomTypography>
            <b>Weight: </b>
            {loadingUnloading.weight}
          </CustomTypography>
        )}
        <CustomTypography>
          <b>Type: </b>
          {loadingUnloading.type}
        </CustomTypography>
      </Stack>
    </Stack>
  );
};

export default LoadingUnloadingCard;
