import React, { lazy, useEffect, useRef, useState } from "react";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  chatClientAction,
  deleteWalletAction,
  logoutAction,
  proApplicationDataAction,
  tokenAction,
} from "./sliceReducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "./firebase";
import { Box, CircularProgress, Container, Stack } from "@mui/material";

import ScrollToTop from "./utils/ScrollToTop";
import { useJsApiLoader } from "@react-google-maps/api";
import { loadGoogleMapAction } from "./sliceReducers/utilsSlice";
import OfferAccepted from "./components/MyRequests/OfferAccepted";
import AddressInfo from "./components/MyRequests/AddressInfo";
import PaymentMethod from "./components/MyRequests/PaymentMethod";
import AppbarDrawer from "./components//Appbar/AppbarDrawer";

import { GOOGLE_MAP_API_KEY, VAPID_KEY } from "./utils/config";
import LoginSignup from "./components/User/LoginSignup";
import PreFetch from "./utils/Prefetch";
import Notification from "./components/Notification";
import withSuspense from "./utils/withSuspense";
import { userApi } from "./services/userService";
import AllNotifications from "./components/Navbar/AllNotifications";
import SingleRequest from "./components/MyRequests/SingleRequest";
import Settings from "./components/Settings/Settings";
import MyTeam from "./components/MyTeam/MyTeam";
import UserContext from "./components/UserContext";
import InviteFriends from "./components/InviteFriends/InviteFriends";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SearchRequest from "./components/Search/SearchRequest";
import Vehicles from "./utils/Vehicles";
import { notifySuccess } from "./utils/notifyMessage";

const Home = withSuspense(lazy(() => import("./components/Home/Home")));

const CreateRequest = withSuspense(
  lazy(() => import("./components/CreateRequest/CreateRequest"))
);
const MyRequests = withSuspense(
  lazy(() => import("./components/MyRequests/MyRequests"))
);
const MyDeliveries = withSuspense(
  lazy(() => import("./components/MyDeliveries/MyDeliveries"))
);
const MyWallet = withSuspense(
  lazy(() => import("./components/Wallet/MyWallet"))
);
const MyChat = withSuspense(lazy(() => import("./components/Chat/MyChat")));
const MyProfile = withSuspense(
  lazy(() => import("./components/MyProfile/MyProfile"))
);
const UpgradeToPro = withSuspense(
  lazy(() => import("./components/UpgradeToPro/UpgradeToPro"))
);

toast.onChange(() => {});

function App() {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState();
  const locat = useLocation().pathname;
  const { token, user, proApplicationData, applicationLoaded, chatClient } =
    useSelector((state) => state.userData);
  const navigate = useNavigate();

  function refreshToken() {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      console.log("user-------", user?.stsTokenManager);

      if (user) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          // Signed in
          console.log(idToken);
          dispatch(tokenAction(idToken));

          const messaging = getMessaging();

          getToken(messaging, {
            vapidKey: VAPID_KEY,
          })
            .then(async (currentToken) => {
              if (currentToken) {
                console.log("current token for client: ", currentToken);
                // Perform any other neccessary action with the token
                let tokens = {
                  token: idToken,
                  fcm_token: currentToken,
                };
                dispatch(userApi.endpoints.FCMToken.initiate(tokens));
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });
        });
      }
    });
    return unsubscribe;
  }

  useEffect(() => {
    refreshToken();
  }, []);

  //Refresh token after 40 min
  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      refreshToken();
    }, 40 * 60 * 1000);
    return () => clearInterval(tokenRefreshInterval);
  }, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (isLoaded) {
      dispatch(loadGoogleMapAction(true));
    }
  }, [isLoaded]);

  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <UserContext.Provider value={{ scrollRef: scrollRef }}>
      <ToastContainer />
      <Notification />
      <ScrollToTop />

      {currentUser === undefined ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "rgb(240, 242, 245)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="./Blue Original.png"
            style={{ width: "20vw", position: "fixed" }}
          />
        </div>
      ) : (
        currentUser !== undefined && (
          <Box
            sx={{
              pt: locat !== "/" && "5rem",
              pb: locat !== "/" && { md: "5rem" },
              px: {
                xs: locat === "/home" ? "1rem" : "0",
                md: locat !== "/" && "1rem",
              },
              display: "flex",
              justifyContent: "center",
            }}
          >
            {currentUser && token && <AppbarDrawer />}

            {currentUser !== undefined && (
              <>
                {currentUser && token && <PreFetch />}

                <Container
                  maxWidth="xl"
                  disableGutters
                  sx={{
                    maxWidth: {
                      xs: locat === "/" ? "100vw !important" : "1536px",
                    },
                  }}
                >
                  <Box sx={{ flexGrow: "1" }}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          !currentUser ? (
                            <LoginSignup />
                          ) : (
                            <Navigate to={"/home"} />
                          )
                        }
                      />

                      {token && applicationLoaded ? (
                        <>
                          {(user &&
                            Object.keys(user).length !== 0 &&
                            user.user_type === "pro") ||
                          (user &&
                            Object.keys(user).length !== 0 &&
                            proApplicationData &&
                            Object.keys(proApplicationData).length !== 0 &&
                            proApplicationData?.proApplication?.status !==
                              "rejected") ? (
                            <>
                              <Route
                                path="/home"
                                element={
                                  currentUser ? <Home /> : <Navigate to={"/"} />
                                }
                              />

                              <Route
                                path="/createRequest"
                                element={
                                  currentUser ? (
                                    <CreateRequest />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/requests"
                                element={
                                  currentUser ? (
                                    <MyRequests />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/search"
                                element={
                                  currentUser ? (
                                    <SearchRequest />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/singleRequest/:orderNo"
                                element={
                                  currentUser ? (
                                    <SingleRequest />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/deliveries"
                                element={
                                  currentUser ? (
                                    <MyDeliveries />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/chat"
                                element={
                                  currentUser ? (
                                    <MyChat />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/notifications"
                                element={
                                  currentUser ? (
                                    <AllNotifications />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />
                              <Route
                                path="/inviteFriends"
                                element={
                                  currentUser ? (
                                    <InviteFriends />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/wallet"
                                element={
                                  currentUser ? (
                                    <MyWallet />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/team"
                                element={
                                  currentUser ? (
                                    <MyTeam
                                      gridXs={12}
                                      gridSm={6}
                                      gridMd={4}
                                      gridLg={3}
                                    />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/profile"
                                element={
                                  currentUser ? (
                                    <MyProfile />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/settings"
                                element={
                                  currentUser ? (
                                    <Settings />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/offerAccepted"
                                element={
                                  currentUser ? (
                                    <OfferAccepted />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/addressInfo"
                                element={
                                  currentUser ? (
                                    <AddressInfo />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              <Route
                                path="/paymentMethod"
                                element={
                                  currentUser ? (
                                    <PaymentMethod />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />

                              {/* <Route
                                path="/vehicles"
                                element={
                                  currentUser ? (
                                    <Vehicles />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              /> */}
                            </>
                          ) : (
                            user &&
                            Object.keys(user).length !== 0 &&
                            proApplicationData && (
                              <Route
                                path="/*"
                                element={
                                  currentUser && user ? (
                                    <UpgradeToPro />
                                  ) : (
                                    <Navigate to={"/"} />
                                  )
                                }
                              />
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <Route
                            path="/*"
                            element={
                              currentUser && user ? (
                                <Stack
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "80vh",
                                  }}
                                >
                                  <CircularProgress
                                    sx={{ alignSelf: "center" }}
                                  />
                                </Stack>
                              ) : (
                                <Navigate to={"/"} />
                              )
                            }
                          />
                        </>
                      )}
                    </Routes>
                  </Box>
                </Container>
              </>
            )}
          </Box>
        )
      )}
    </UserContext.Provider>
  );
}

export default App;
