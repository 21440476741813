import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useGetContactPersonsQuery } from "../../services/userService";
import {
  filterContactPersonsAction,
  getContactPersonsAction,
} from "../../sliceReducers/userSlice";
import { isFetchingSomethingAction } from "../../sliceReducers/utilsSlice";
import APIhandler from "../../utils/APIhandler";
import Loader from "../../utils/Loader";
import AddTeamMemberDialog from "./AddTeamMemberDialog";
import "./MyTeam.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DeleteTeamMemberDialog from "./DeleteTeamMemberDialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const MyTeam = ({
  gridXs,
  gridSm,
  gridMd,
  gridLg,
  selectedAgentFromList,
  setSelectedAgentFromList,
  fromPayment,
}) => {
  const dispatch = useDispatch();
  const {
    token,
    filteredContactPersons,
    contactPersonsNames,
    contactPersonsNumbers,
  } = useSelector((state) => state.userData);
  const { isFetchingSomething } = useSelector((state) => state.utils);
  const [openAddTeamMember, setOpenAddTeamMember] = useState(false);
  const [filteredContactPerson, setFilteredContactPerson] = useState();
  const [addOrEditTeamMember, setAddOrEditTeamMember] = useState(0);
  const [dataToBeEdited, setDataToBeEdited] = useState({});

  const [openDeleteTeamMember, setOpenDeleteTeamMember] = useState(false);

  const {
    data: getContactPersonsResponse,
    isFetching: getContactPersonsFetching,
    error: getContactPersonsError,
    refetch: refetchGetContactPersons,
  } = useGetContactPersonsQuery(token);

  useEffect(() => {
    if (filteredContactPersons.length === 0) {
      dispatch(isFetchingSomethingAction(true));
    }
  }, []);

  useEffect(() => {
    dispatch(filterContactPersonsAction(filteredContactPerson));
  }, [filteredContactPerson]);

  return (
    <>
      {/* {isFetchingSomething && <Loader />} */}
      <APIhandler
        responseData={getContactPersonsResponse}
        isFetching={getContactPersonsFetching}
        serverError={getContactPersonsError}
        onStatusTrue={() => {
          dispatch(getContactPersonsAction(getContactPersonsResponse.data));
          dispatch(isFetchingSomethingAction(false));
          setOpenAddTeamMember(false);
          setOpenDeleteTeamMember(false);
        }}
        onStatusFalse={() => {}}
        onServerError={() => {}}
      />
      <Stack
        spacing={2}
        sx={{
          alignItems: "center",
          width: { xs: "95%", md: "100%" },
          margin: { xs: "auto", md: "initial" },
          marginTop: { md: "0.5rem" },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 3, md: 0 }}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Autocomplete
            id="country-select-demo"
            options={filteredContactPersons}
            autoHighlight
            value={filteredContactPerson}
            onChange={(event, newValue) => {
              if (!newValue) {
                setFilteredContactPerson("");
              } else {
                setFilteredContactPerson(newValue);
              }
            }}
            getOptionLabel={(option) => {
              return `${option.fullname} (${option.phone})`;
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.fullname} ({option.phone})
              </Box>
            )}
            // sx={{
            //   backgroundColor: "white",
            //   padding: "1rem",
            //   borderRadius: "5px",
            // }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Team Member"
                helperText="Search team member by member name"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <Button
            variant="contained"
            // sx={{ alignSelf: "flex-end" }}
            onClick={() => {
              setAddOrEditTeamMember(0);
              setOpenAddTeamMember(true);
            }}
          >
            Add team member
          </Button>
        </Stack>

        {filteredContactPerson && (
          <Typography sx={{ fontSize: "2rem" }}>Search Result</Typography>
        )}
        <Grid container spacing={{ sm: 1 }} rowSpacing={1}>
          {filteredContactPersons.length > 0 &&
            filteredContactPersons.map((contactPerson, index) => (
              <Grid
                item
                xs={gridXs}
                sm={gridSm}
                md={gridMd}
                lg={gridLg}
                key={index}
              >
                <Stack
                  direction={fromPayment ? "column" : "row"}
                  sx={{
                    backgroundColor: "white",
                    boxShadow: "var(--boxShadow)",
                    padding: "0.25rem 1rem",
                    borderRadius: "5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // alignItems: "center",
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <AccountCircleIcon
                      sx={{ fontSize: "2.5rem", color: "var(--disableGrey)" }}
                    />

                    <Stack>
                      <Typography sx={{ fontWeight: "600" }}>
                        {contactPerson.fullname}
                      </Typography>
                      <Typography>{contactPerson.phone}</Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction={fromPayment ? "row" : "column"}
                    sx={{ alignSelf: fromPayment && "flex-end" }}
                  >
                    {fromPayment && (
                      <Checkbox
                        checked={selectedAgentFromList === contactPerson.phone}
                        onChange={() =>
                          setSelectedAgentFromList(contactPerson.phone)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    )}

                    {/* <IconButton
                      onClick={() => {
                        setDataToBeEdited(contactPerson);
                        setAddOrEditTeamMember(1);
                        setOpenAddTeamMember(true);
                      }}
                    >
                      <EditRoundedIcon sx={{ color: "var(--primary)" }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDataToBeEdited(contactPerson);
                        setOpenDeleteTeamMember(true);
                      }}
                    >
                      <DeleteRoundedIcon sx={{ color: "var(--BrightRed1)" }} />
                    </IconButton> */}
                  </Stack>
                </Stack>
              </Grid>
            ))}
        </Grid>
      </Stack>

      {openAddTeamMember && (
        <AddTeamMemberDialog
          refetchGetContactPersons={refetchGetContactPersons}
          openAddTeamMember={openAddTeamMember}
          setOpenAddTeamMember={setOpenAddTeamMember}
          addOrEditTeamMember={addOrEditTeamMember}
          dataToBeEdited={dataToBeEdited}
        />
      )}

      {openDeleteTeamMember && (
        <DeleteTeamMemberDialog
          refetchGetContactPersons={refetchGetContactPersons}
          openDeleteTeamMember={openDeleteTeamMember}
          setOpenDeleteTeamMember={setOpenDeleteTeamMember}
          dataToBeEdited={dataToBeEdited}
        />
      )}
    </>
  );
};

export default MyTeam;
