import {
  Box,
  DialogActions,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import PdfDownloader from "../PdfDownloader";
import DownloadIcon from "@mui/icons-material/Download";
import Logo from "../../images/Blue-Original.png";
import CloseIcon from "@mui/icons-material/Close";

const OrderInvoiceContainer = styled(Stack)({
  img: {
    width: "100px",
  },
});

const OrderInvoiceDialog = ({
  invoice,
  orderInvoiceFetching,
  setOpenBiltyOrder,
}) => {
  return (
    <>
      <OrderInvoiceContainer>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "0.5rem",
          }}
        >
          <img src={Logo} alt="Logo image" />
          <IconButton
            edge="start"
            onClick={() => setOpenBiltyOrder(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography>
          Order Number: {invoice?.orderInvoice?.["Order No"]}
        </Typography>

        <Typography>
          Customer ID: {invoice?.orderInvoice?.["Customer ID"]}
        </Typography>

        <Typography>
          From (Origin): {invoice?.orderInvoice?.["From (Origin)"]}
        </Typography>

        <Typography>
          To (Destination): {invoice?.orderInvoice?.["To: (Destination)"]}
        </Typography>

        <Typography>
          Sender Name: {invoice?.orderInvoice?.["Sender Name"]}
        </Typography>

        <Typography>
          Sender Address: {invoice?.orderInvoice?.["Sender Address"]}
        </Typography>

        <Typography>
          Receiver name: {invoice?.orderInvoice?.["Receiver Name"]}
        </Typography>

        <Typography>
          Receiver Address: {invoice?.orderInvoice?.["Receiver Address"]}
        </Typography>

        <Typography>
          Additional Description: {invoice?.orderInvoice?.additionalDescription}
        </Typography>

        <Typography>
          Invoice Number: {invoice?.orderInvoice?.["Invoice Number"]}
        </Typography>

        <Typography>
          Order Number: {invoice?.orderInvoice?.["Order No"]}
        </Typography>

        <Typography>
          Booking Number: {invoice?.orderInvoice?.["Booking Number"]}
        </Typography>

        <Typography>Date: {invoice?.orderInvoice?.createdAt}</Typography>

        <Typography>
          Due Date: {invoice?.orderInvoice?.due_date_text}
        </Typography>

        <Typography>
          Vehicle Type: {invoice?.orderInvoice?.["Vehicle Types"]}
        </Typography>

        <Typography>Weight (Ton): {invoice?.orderInvoice?.Weights}</Typography>

        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "0.2rem",
            backgroundColor: "var(--primary)",
            color: "white",
            marginTop: "1rem",
          }}
        >
          <Typography>Description of goods</Typography>
          <Typography>Amount PKR</Typography>
        </Stack>

        <Stack
          sx={{
            padding: "0.2rem",
            backgroundColor: "var(--lightGrey)",
            color: "white",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              color: "var(--Black)",
            }}
          >
            <Typography>Fuel and Transit Expense</Typography>
            <Typography>
              {invoice?.orderInvoice?.fuelAndOtherCharges}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              color: "var(--Black)",
            }}
          >
            <Typography>Fuel and Transit Expense</Typography>
            <Typography>
              {invoice?.orderInvoice?.fuelAndOtherCharges}
            </Typography>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              color: "var(--Black)",
            }}
          >
            <Typography>Service charges</Typography>
            <Typography>{invoice?.orderInvoice?.serviceCharges}</Typography>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              color: "var(--Black)",
            }}
          >
            <Typography>Insurance</Typography>
            <Typography>{invoice?.orderInvoice?.cargo_value}</Typography>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              color: "var(--Black)",
            }}
          >
            <Typography>Grand total</Typography>
            <Typography>{invoice?.orderInvoice?.total}</Typography>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              color: "var(--Black)",
            }}
          >
            <Typography>Amount due</Typography>
            <Typography>{invoice?.orderInvoice?.due_amount}</Typography>
          </Stack>
        </Stack>

        <Typography sx={{ marginTop: "1rem" }}>
          Amount in words: Pakistani Rupee
        </Typography>

        <Typography>{invoice?.orderInvoice?.totalInWords}</Typography>
      </OrderInvoiceContainer>
    </>
  );
};

export default OrderInvoiceDialog;
