import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import "./MyRequests.css";
import { useState } from "react";
import useDidMountEffect from "../../utils/useDidMountEffect";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaceIcon from "@mui/icons-material/Place";
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import Bilties from "../../utils/Bilties";
import { dateTimeOrder, moreDetailsData } from "../../utils/commonFunctions";
import OfferReceivedDecisionDialogBox from "./OfferReceivedDecisionDialogBox";
import MoreDetailsDialogBox from "./MoreDetailsDialogBox";
import MyRequestDeliveryCard from "./MyRequestDeliveryCard";
import {
  useGetSingleOfferReceivedRequestsQuery,
  useProUserLessCreditsQuery,
  useUserCancelOrderQuery,
} from "../../services/userService";
import APIhandler from "../../utils/APIhandler";
import { notifyError } from "../../utils/notifyMessage";
import dayjs from "dayjs";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/

const MyRequestCard = ({
  myRequest,
  statusText,
  statusColor,
  status,
  offerAcceptedByVendor,
  offerCounterByVendor,
  offerReceived,
  findingRates,
  setRefetchAll,
}) => {
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);

  const [openAdditionalInformation, setOpenAdditionalInformation] =
    useState(false);

  const [createQouteCounter, setCreateQouteCounter] = useState({
    dateTime: "",
    text: "",
  });

  const [numberOfVehicles, setNumberOfVehicles] = useState(0);

  // const pickUpTime = new Date(Date.parse(myRequest.date)).toString();
  const pickUpTime = dayjs(myRequest.date).format("DD/MM/YYYY HH:mm:ss");

  const { requestPerPage } = useSelector((state) => state.utils);
  const { token } = useSelector((state) => state.userData);

  const [myOrderDialogDetailsContext, setMyOrderDialogDetailsContext] =
    useState([]);

  const [skipProUserLessCredits, setSkipProUserLessCredits] = useState(true);

  const [offerReceivedDecisionDialog, setOfferReceivedDecisionDialog] =
    useState(false);

  const [offerReceivedDecision, setOfferReceivedDecision] = useState(0);
  const [openMyOffer, setOpenMyOffer] = useState(false);

  const [skipUserCancelOrder, setSkipUserCancelOrder] = useState(true);

  const navigate = useNavigate();

  const handleMoreDetailsOpen = () => {
    setMoreDetailsOpen(true);
  };

  useDidMountEffect(() => {
    if (moreDetailsOpen) {
      moreDetailsData(
        myRequest,
        statusText,
        createQouteCounter,
        setMyOrderDialogDetailsContext,
        setDirectionsResponse
      );
    }
  }, [moreDetailsOpen]);

  useEffect(() => {
    dateTimeOrder(myRequest, setCreateQouteCounter);
  }, [myRequest]);

  useEffect(() => {
    let numOfVehicles = 0;
    if (myRequest.bilties && myRequest.bilty) {
      for (let i = 0; i < myRequest.bilties.length; i++) {
        if (myRequest.bilties[i].type === "vehicle") {
          numOfVehicles += 1;
        }
      }
    } else if (myRequest.bilties) {
      for (let i = 0; i < myRequest.bilties.length; i++) {
        if (myRequest.bilties[i].type === "vehicle") {
          numOfVehicles += 1;
        }
      }
    } else if (myRequest.bilty) {
      for (let i = 0; i < myRequest.bilty.length; i++) {
        if (myRequest.bilty[i].type === "vehicle") {
          numOfVehicles += 1;
        }
      }
    }
    setNumberOfVehicles(numOfVehicles);
  }, [myRequest]);

  const handleOfferReceivedDecisionDialog = () => {
    setOfferReceivedDecisionDialog(true);
  };

  let proUserLessCreditsQuery = {
    token,
    orderNo: myRequest.orderNo,
    orderAmount: myRequest.amount || myRequest.qoute_amount,
  };

  const {
    data: proUserLessCreditsResponse,
    isFetching: proUserLessCreditsFetching,
    error: proUserLessCreditsError,
  } = useProUserLessCreditsQuery(proUserLessCreditsQuery, {
    skip: skipProUserLessCredits,
  });

  const {
    data: userCancelOrderResponse,
    error: userCancelOrderError,
    isFetching: userCancelOrderFetching,
  } = useUserCancelOrderQuery(
    {
      token: token,
      orderNo: myRequest.orderNo,
    },
    {
      skip: skipUserCancelOrder,
    }
  );

  useDidMountEffect(() => {
    if (
      userCancelOrderFetching === false &&
      userCancelOrderResponse &&
      !userCancelOrderError
    ) {
      if (userCancelOrderResponse.status === true) {
        setSkipUserCancelOrder(true);
        setOpenMyOffer(false);
        setRefetchAll(true);
      } else {
        setSkipUserCancelOrder(true);
        notifyError(userCancelOrderResponse?.error);
      }
    }
  }, [userCancelOrderFetching]);

  const {
    data: userGetSingleOfferReceivedResponse,
    error: userGetSingleOfferReceivedError,
    isFetching: userGetSingleOfferReceivedFetching,
  } = useGetSingleOfferReceivedRequestsQuery(
    {
      token: token,
      orderNo: myRequest.orderNo,
    },
    {
      skip: !openMyOffer,
    }
  );

  console.log("userGetSingleOfferReceivedResponse", myRequest);

  return (
    <>
      <APIhandler
        responseData={proUserLessCreditsResponse}
        isFetching={proUserLessCreditsFetching}
        serverError={proUserLessCreditsError}
        onStatusTrue={() => {
          setSkipProUserLessCredits(true);
          setOfferReceivedDecision(2);
          handleOfferReceivedDecisionDialog();
        }}
        onStatusFalse={() => {
          notifyError(proUserLessCreditsResponse.message);
          setSkipProUserLessCredits(true);
        }}
        onServerError={() => {}}
      />

      <div
        className="myOrderCard"
        onClick={() => {
          setMoreDetailsOpen(true);
        }}
        // style={{
        //   boxShadow:
        //     requestPerPage === 3 ? "var(--boxShadow)" : "var(--lightBoxShadow)",
        // }}
      >
        <MyRequestDeliveryCard
          statusColor={statusColor}
          statusText={statusText}
          createQouteCounter={createQouteCounter}
          myRequest={myRequest}
          numberOfVehicles={numberOfVehicles}
          pickUpTime={pickUpTime}
          handleMoreDetailsOpen={handleMoreDetailsOpen}
          status={status}
          findingRates={findingRates}
          openAdditionalInformation={openAdditionalInformation}
          setOpenAdditionalInformation={setOpenAdditionalInformation}
        />

        <Stack
          sx={{ alignItems: "flex-end" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {(offerReceived || findingRates) && (
            <Button
              variant="contained"
              sx={{ marginBottom: "0.5rem", marginRight: "1rem" }}
              onClick={(e) => {
                setOpenMyOffer(true);
              }}
            >
              My Offer
            </Button>
          )}

          {openMyOffer && (
            <Dialog open={openMyOffer} onClose={() => setOpenMyOffer(false)}>
              {userCancelOrderFetching && <Loader />}
              {userGetSingleOfferReceivedResponse ? (
                <DialogContent>
                  <Stack sx={{ alignItems: "center" }}>
                    <Typography>
                      Date :{" "}
                      {userGetSingleOfferReceivedResponse?.pending[0]
                        ?.qoutedAt || myRequest.createdAt}
                    </Typography>
                    <Typography>
                      Order No :{" "}
                      {userGetSingleOfferReceivedResponse?.pending[0]
                        ?.orderNo || myRequest.orderNo}
                    </Typography>
                    {userGetSingleOfferReceivedResponse?.pending[0]
                      ?.qoute_amount && (
                      <Typography>
                        Qoute Received of{" "}
                        {
                          userGetSingleOfferReceivedResponse?.pending[0]
                            ?.qoute_amount
                        }
                      </Typography>
                    )}

                    {userGetSingleOfferReceivedResponse?.minimum > 0 ||
                    userGetSingleOfferReceivedResponse?.maximum > 0 ? (
                      <Typography sx={{ textAlign: "center" }}>
                        Estimated rates:{" "}
                        {userGetSingleOfferReceivedResponse?.minimum} -{" "}
                        {userGetSingleOfferReceivedResponse?.maximum}
                      </Typography>
                    ) : (
                      <Typography sx={{ textAlign: "center" }}>
                        Estimated rates: Not available
                      </Typography>
                    )}

                    {userGetSingleOfferReceivedResponse?.pending[0]
                      ?.qoutedAt_timestamp && (
                      <Typography sx={{ textAlign: "center" }}>
                        You will get best quote till{" "}
                        {dayjs(
                          userGetSingleOfferReceivedResponse?.pending[0]
                            ?.qoutedAt_timestamp +
                            1000 * 60 * 60 * 3
                        ).format("HH:mm:ss")}
                      </Typography>
                    )}
                    {userGetSingleOfferReceivedResponse?.pending[0]
                      ?.qoutedAt ? (
                      <Typography sx={{ textAlign: "center" }}>
                        We are negotiating best rates for you.
                      </Typography>
                    ) : (
                      <Typography sx={{ textAlign: "center" }}>
                        We are finding best rates for you.
                      </Typography>
                    )}
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ alignItems: "center", marginTop: "1rem" }}
                    >
                      <Button
                        sx={{
                          color: "white",
                          backgroundColor: "var(--BrightRed2)",
                          ":hover": {
                            backgroundColor: "var(--BrightRed2)",
                          },
                        }}
                        onClick={() => {
                          setSkipUserCancelOrder(false);
                        }}
                      >
                        Cancel Order
                      </Button>
                      <Button
                        sx={{
                          color: "white",
                          backgroundColor: "var(--SuccessDark)",
                          ":hover": {
                            backgroundColor: "var(--SuccessDark)",
                          },
                        }}
                        onClick={() => {
                          setOpenMyOffer(false);
                        }}
                      >
                        Close
                      </Button>
                    </Stack>
                  </Stack>
                </DialogContent>
              ) : (
                <Loader />
              )}
            </Dialog>
          )}
        </Stack>

        {/******************  OFFER RECEIVED ACTIONS *******************/}
        {offerReceived && (
          <div
            className="myOrderStatusReceived"
            onClick={(e) => e.stopPropagation()}
            // style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          >
            <Button
              // size="small"
              // sx={{ padding: { xl: "1rem 0" } }}
              onClick={() => {
                setOfferReceivedDecision(0);
                handleOfferReceivedDecisionDialog();
              }}
            >
              {"Reject"}
            </Button>
            <Button
              // size="small"
              // sx={{ padding: { xl: "1rem 0" } }}
              onClick={() => {
                setOfferReceivedDecision(1);
                handleOfferReceivedDecisionDialog();
              }}
            >
              {"Counter Offer"}
            </Button>
            <Button
              // size="small"
              // sx={{ padding: { xl: "1rem 0" } }}
              onClick={() => {
                if (myRequest.payment_method === "Credit") {
                  setOfferReceivedDecision(2);
                  setSkipProUserLessCredits(false);
                } else {
                  setOfferReceivedDecision(2);
                  handleOfferReceivedDecisionDialog();
                }
              }}
            >
              {"Accept"}
            </Button>
          </div>
        )}

        {offerCounterByVendor && (
          <div
            className="myOrderStatusAccepted"
            onClick={(e) => e.stopPropagation()}
            // style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          >
            <Button
              // sx={{ padding: { xl: "1rem 0" } }}
              onClick={() => {
                setOfferReceivedDecision(3);
                handleOfferReceivedDecisionDialog();
              }}
            >
              {"Reject"}
            </Button>

            <Button
              // sx={{ padding: { xl: "1rem 0" } }}
              onClick={() => {
                setOfferReceivedDecision(4);
                handleOfferReceivedDecisionDialog();
              }}
            >
              {"Accept"}
            </Button>
          </div>
        )}

        {offerAcceptedByVendor && (
          <div
            className="myOrderStatusAccepted"
            onClick={(e) => e.stopPropagation()}
            // style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          >
            <Button
              onClick={() => {
                setOfferReceivedDecision(5);
                handleOfferReceivedDecisionDialog();
              }}
            >
              {"Cancel"}
            </Button>

            <Button
              onClick={() => {
                setOfferReceivedDecision(6);
                handleOfferReceivedDecisionDialog();
              }}
            >
              {"Accept"}
            </Button>
          </div>
        )}

        {/******************  OFFER RECEIVED DECISION DIALOG BOX *******************/}
      </div>

      {/******************  MORE DETAILS DIALOG BOX *******************/}
      {moreDetailsOpen && (
        <MoreDetailsDialogBox
          moreDetailsOpen={moreDetailsOpen}
          setMoreDetailsOpen={setMoreDetailsOpen}
          myRequest={myRequest}
          statusColor={statusColor}
          statusText={statusText}
          myOrderDialogDetailsContext={myOrderDialogDetailsContext}
          directionsResponse={directionsResponse}
        />
      )}

      {/******************  OFFER RECEIVED Decision *******************/}
      {offerReceivedDecisionDialog && (
        <OfferReceivedDecisionDialogBox
          offerReceivedDecisionDialog={offerReceivedDecisionDialog}
          setOfferReceivedDecisionDialog={setOfferReceivedDecisionDialog}
          offerReceivedDecision={offerReceivedDecision}
          myRequest={myRequest}
          statusColor={statusColor}
          statusText={statusText}
        />
      )}
    </>
  );
};

export default MyRequestCard;
