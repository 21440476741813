import { Button } from "@mui/material";
import React from "react";

function MyButton({
  text,
  variant,
  type,
  color,
  width,
  size,
  disabled,
  onClick,
}) {
  return (
    <Button
      type={type}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      sx={{
        color,
        backgroundColor: disabled ? "var(--disableGrey)" : "var(--primary)",
        width,
        ":hover": {
          backgroundColor: "var(--darkPrimary)",
        },
      }}
    >
      {text}
    </Button>
  );
}

export default MyButton;
