import React, { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {
  Avatar,
  Badge,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { getMessaging, deleteToken } from "firebase/messaging";
import { BsPencilSquare } from "react-icons/bs";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { TbTruckDelivery } from "react-icons/tb";
import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { TbWallet } from "react-icons/tb";
import { RiTeamLine } from "react-icons/ri";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { MdOutlineNotifications } from "react-icons/md";
import { MdOutlineInsertInvitation } from "react-icons/md";
import { MdOutlinePerson } from "react-icons/md";
import { TbSettings } from "react-icons/tb";
import { TbLogout } from "react-icons/tb";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import MobileDrawer from "./MobileDrawer";
import WebDrawer from "./WebDrawer";
import { auth } from "../../firebase";
import { notifyInfo, notifySuccess } from "../../utils/notifyMessage";
import {
  disableUnReadNotificationAction,
  drawerOpenAction,
  showNotificationAction,
} from "../../sliceReducers/utilsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../Navbar/Notification";
import useDidMountEffect from "../../utils/useDidMountEffect";

import SearchRequest from "../Search/SearchRequest";
import { useChatJoinQuery } from "../../services/userService";
import {
  chatClientAction,
  deleteWalletAction,
  logoutAction,
  myWalletAction,
  proApplicationDataAction,
} from "../../sliceReducers/userSlice";
import { StreamChat } from "stream-chat";
import { toast } from "react-toastify";

const drawerWidth = 240;
var iconSize = 25;

const sidebarItems = [
  { icon: <AiOutlineHome size={iconSize} />, name: "Home", route: "/home" },
  {
    icon: <HiOutlinePencilAlt size={iconSize} />,
    name: "Create Request",
    route: "/createRequest",
  },
  {
    icon: <HiOutlineDocumentDuplicate size={iconSize} />,
    name: "My Requests",
    route: "/requests",
  },
  {
    icon: <TbTruckDelivery size={iconSize} />,
    name: "My Deliveries",
    route: "/deliveries",
  },
  { icon: <TbWallet size={iconSize} />, name: "My Wallet", route: "/wallet" },
  { icon: <RiTeamLine size={iconSize} />, name: "My Team", route: "/team" },
  { icon: <HiOutlineChatAlt2 size={iconSize} />, name: "Chat", route: "/chat" },
  {
    icon: <MdOutlineNotifications size={iconSize} />,
    name: "Notifications",
    route: "/notifications",
  },
  {
    icon: <MdOutlineInsertInvitation size={iconSize} />,
    name: "Invite Friends",
    route: "/inviteFriends",
  },
  {
    icon: <MdOutlinePerson size={iconSize} />,
    name: "My Profile",
    route: "/profile",
  },
  {
    icon: <TbSettings size={iconSize} />,
    name: "Settings",
    route: "/settings",
  },
  { icon: <TbLogout size={iconSize} />, name: "Logout", route: "/logout" },
  {
    icon: <BsPencilSquare size={iconSize} />,
    name: "Upgrade To Pro",
    route: "/upgradeToPro",
  },
];

const actions = [
  { icon: <TbSettings size={30} />, name: "Settings" },
  { icon: <TbLogout size={30} />, name: "Logout" },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 55px)`,
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: open && "var(--primary)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppbarDrawer = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.utils);
  const [activeNavbar, setActiveNavbar] = useState();
  const navigate = useNavigate();
  const locat = useLocation().pathname;

  const {
    notificationsData,
    showNotification,
    unReadNotificationsCount,
    unReadNotification,
  } = useSelector((state) => state.utils);

  const { user, chatClient, token, proApplicationData } = useSelector(
    (state) => state.userData
  );

  useEffect(() => {
    switch (locat) {
      case "/home":
        setActiveNavbar("Home");
        break;
      case "/createRequest":
        setActiveNavbar("Create Request");
        break;
      case "/deliveries":
        setActiveNavbar("My Deliveries");
        break;
      case "/requests":
        setActiveNavbar("My Requests");
        break;
      case "/wallet":
        setActiveNavbar("My Wallet");
        break;
      case "/team":
        setActiveNavbar("My Team");
        break;
      case "/chat":
        setActiveNavbar("Chat");
        break;
      case "/notifications":
        setActiveNavbar("Notifications");
        break;
      case "/inviteFriends":
        setActiveNavbar("Invite Friends");
        break;
      case "/profile":
        setActiveNavbar("My Profile");
        break;
      case "/settings":
        setActiveNavbar("Settings");
        break;
      case "/logout":
        setActiveNavbar("Logout");
        break;
      case "/upgradeToPro":
        setActiveNavbar("Upgrade To Pro");
        break;
      case "/search":
        setActiveNavbar("Search");
        break;

      default:
        break;
    }
  }, [locat]);

  const handleDrawer = () => {
    dispatch(drawerOpenAction(!open));
  };

  const handleDrawerAction = (text) => {
    if (window.innerWidth < 1024) {
      dispatch(drawerOpenAction(false));
    }
    if (text.name !== "Logout") {
      navigate(text.route);
      setActiveNavbar(text.name);
    } else {
      dispatch(logoutAction(false));

      const messaging = getMessaging();
      deleteToken(messaging);

      if (chatClient) {
        chatClient.disconnectUser();
      }
      window.localStorage.clear();
      dispatch(deleteWalletAction({}));
      dispatch(chatClientAction(null));
      dispatch(proApplicationDataAction({}));
      auth.signOut();
      notifySuccess("Logout Successfully");
      navigate("/");
    }
  };

  const handleSpeedDialAction = (action) => {
    if (action.name === "Logout") {
      dispatch(logoutAction(false));
      const messaging = getMessaging();
      deleteToken(messaging);

      if (chatClient) {
        chatClient.disconnectUser();
      }
      window.localStorage.clear();
      auth.signOut();
      dispatch(deleteWalletAction({}));
      dispatch(chatClientAction(null));
      dispatch(proApplicationDataAction({}));
      notifySuccess("Logout Successfully");
      navigate("/");
    } else {
      navigate("/settings");
    }
  };

  const closeNotificationHandler = (event) => {
    var ignoreClickOnMeElement = document.getElementById("notification");

    if (ignoreClickOnMeElement) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        //Do something click is outside specified element
        dispatch(showNotificationAction(false));
      }
    }
  };

  useDidMountEffect(() => {
    if (showNotification === true) {
      document.addEventListener("click", closeNotificationHandler);
    } else {
      document.removeEventListener("click", closeNotificationHandler);
    }
  }, [showNotification]);

  const { data, error, isFetching, refetch } = useChatJoinQuery(token);

  const sort = { last_message_at: -1 };

  useEffect(() => {
    if (
      data &&
      data.token &&
      data?.biltyArr.length > 0 &&
      data?.orderArr.length > 0
    ) {
      const initChat = async () => {
        const client = StreamChat.getInstance("r4vjktd8gbcr");
        const meUser = await client.connectUser(
          {
            id: data.user_type + "_" + data.user_id,
            name: data.user_id,
            username: data.user_id,
          },
          data.token
        );

        dispatch(chatClientAction(client));
      };
      initChat();
      // close the WebSocket connection when component dismount
      // return () => chatClient.disconnectUser();
    }
  }, [isFetching]);

  useEffect(() => {
    if (chatClient) {
      chatClient.on(`message.new`, (event) => {
        console.log(event);

        notifyInfo(
          <Stack onClick={() => toast.done()}>
            <Typography sx={{ fontWeight: "600" }}>
              You got a new message on {event.channel_id}
            </Typography>
            <Typography>{event.message.text} </Typography>
          </Stack>
        );
      });
    }
  }, [chatClient]);

  return (
    <>
      <AppBar
        elevation={window.innerWidth > 900 ? 1 : 0}
        position="fixed"
        open={open}
      >
        <Toolbar>
          {((user && user.user_type === "pro") ||
            (user &&
              proApplicationData &&
              Object.keys(proApplicationData).length !== 0 &&
              proApplicationData?.proApplication?.status !== "rejected")) && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                sx={{
                  marginRight: 2,
                }}
              >
                {!open ? <MenuIcon /> : <ChevronLeftIcon />}
              </IconButton>

              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {activeNavbar}
              </Typography>

              <IconButton
                sx={{
                  color: "white",
                  marginRight: { xs: "3.5rem", md: "6.5rem" },
                  marginBottom: { xs: "0rem", md: "0.3rem" },
                  // color: "var(--primary)",
                  // backgroundColor: "var(--lightGrey)",
                  ":hover": {
                    color: "var(--Black)",
                    backgroundColor: "var(--lightGrey)",
                  },
                }}
                onClick={() => navigate("/search")}
              >
                <SearchRoundedIcon sx={{ fontSize: "1.6rem" }} />
              </IconButton>

              <Badge
                id="notification"
                max={9}
                badgeContent={
                  unReadNotification && unReadNotificationsCount > 0
                    ? unReadNotificationsCount
                    : null
                }
                sx={{
                  position: "fixed",
                  right: { xs: "1rem", md: "4.5rem" },
                  top: "0.5rem",
                  color: "white",
                  ".MuiBadge-badge": {
                    backgroundColor: "var(--BrightRed1)",
                    marginTop: "0.4rem",
                    marginRight: "0.2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.5rem",
                  },
                }}
              >
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    ":hover": {
                      backgroundColor: "var(--lightGrey)",
                    },
                  }}
                  onClick={() => {
                    // setSkipDisableUnReadNotifications(false);
                    dispatch(
                      showNotificationAction(showNotification ? false : true)
                    );
                    setTimeout(() => {
                      dispatch(disableUnReadNotificationAction());
                    }, [500]);
                  }}
                >
                  <MdOutlineNotifications size={25} />
                </IconButton>
              </Badge>

              {showNotification && (
                <Notification
                  position="fixed"
                  right={{ xs: "0.5rem", md: "2rem" }}
                  mobileRight="0rem"
                  top="3.5rem"
                  mobileTop="3.5rem"
                  width="30rem"
                  maxHeight="90vh"
                  overflowY="scroll"
                  seeAllButton
                  data={notificationsData}
                />
              )}
            </>
          )}

          <SpeedDial
            direction="down"
            ariaLabel="SpeedDial basic example"
            sx={{
              display: { xs: "none", md: "inherit" },
              position: "fixed",
              top: "0.5rem",
              right: "0.5rem",
              "	.MuiSpeedDial-fab": {
                width: 40,
                height: 40,
                backgroundColor: "white !important",
              },
            }}
            icon={
              <Avatar
                sx={{
                  backgroundColor: "var(--primary)",
                  width: 35,
                  height: 35,
                }}
              >
                {user?.fullname?.substring(0, 1)}
              </Avatar>
            }
          >
            {actions.map((action) => (
              <SpeedDialAction
                onClick={() => handleSpeedDialAction(action)}
                key={action.name}
                icon={action.icon}
                tooltipTitle={<Typography p={1}>{action.name}</Typography>}
              />
            ))}
          </SpeedDial>
        </Toolbar>
      </AppBar>

      {((user && user.user_type === "pro") ||
        (user &&
          proApplicationData &&
          Object.keys(proApplicationData).length !== 0 &&
          proApplicationData?.proApplication?.status !== "rejected")) && (
        <>
          <WebDrawer
            handleDrawerAction={handleDrawerAction}
            handleDrawer={handleDrawer}
            Drawer={Drawer}
            sidebarItems={sidebarItems}
            activeNavbar={activeNavbar}
            setActiveNavbar={setActiveNavbar}
            DrawerHeader={DrawerHeader}
          />

          <MobileDrawer
            handleDrawerAction={handleDrawerAction}
            DrawerHeader={DrawerHeader}
            handleDrawer={handleDrawer}
            sidebarItems={sidebarItems}
            activeNavbar={activeNavbar}
            setActiveNavbar={setActiveNavbar}
          />
        </>
      )}
    </>
  );
};

export default AppbarDrawer;
