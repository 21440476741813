import { Stack, Typography } from "@mui/material";
import { getMessaging, onMessage, deleteToken } from "firebase/messaging";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  notificationIdAction,
  updateDriverStatusAction,
} from "../sliceReducers/utilsSlice";
import { notifyInfo } from "../utils/notifyMessage";

const messaging = getMessaging();

const NotificationContainer = ({ notification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notificationDecision = () => {
    switch (notification?.data?.routes) {
      case "singleRequest":
        navigate(`/singleRequest/${notification?.data?.orderNo}`);
        break;
      case "requests":
        navigate("/requests");
        break;
      case "deliveries":
        dispatch(
          updateDriverStatusAction({
            orderNo: notification?.data?.orderNo,
            biltyNo: notification?.data?.biltyNo,
          })
        );
        navigate("/deliveries");
        break;
      default:
        break;
    }
  };

  return (
    <Stack
      onClick={() => {
        notificationDecision();
      }}
    >
      <Typography variant="h6">{notification?.title}</Typography>
      <Typography>{notification?.body}</Typography>
    </Stack>
  );
};

const Notification = () => {
  onMessage(messaging, (payload) => {
    console.log("payload", payload);
    notifyInfo(
      <NotificationContainer
        notification={{
          title: payload?.notification?.title,
          body: payload?.notification?.body,
          data: payload?.data,
        }}
      />
    );
  });

  return null;
};

export default Notification;
