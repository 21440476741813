import React from "react";
import { PDFViewer, PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import OrderInvoice from "../utils/OrderInvoice";
import BiltyInvoice from "../utils/BiltyInvoice";

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

// function PdfDownloader({ text, component, startIcon }) {
//   return (
//     <PDFViewer style={styles.viewer}>
//       <MyDocument text={"Ikram"} />
//     </PDFViewer>
//   );
// }

function PdfDownloader({
  text,
  startIcon,
  orderInvoice,
  invoiceFetching,
  biltyInvoice,
}) {
  return (
    <Box sx={{ a: { textDecoration: "none" } }}>
      <PDFDownloadLink
        document={
          biltyInvoice ? (
            <BiltyInvoice invoice={biltyInvoice} />
          ) : (
            <OrderInvoice invoice={orderInvoice} />
          )
        }
        fileName={
          biltyInvoice
            ? `MB Bilty Invoice-${biltyInvoice?.biltyInvoice?.biltyNo}`
            : `MB Order Invoice-${orderInvoice?.orderInvoice?.["Order No"]}`
        }
      >
        {({ blob, url, loading, error }) => (
          <LoadingButton
            variant="contained"
            loading={invoiceFetching}
            startIcon={startIcon}
            disabled={invoiceFetching}
            sx={{
              font: "400 0.9rem Roboto",
              textDecoration: "none",
              backgroundColor: "var(--primary)",
              color: "white",
              borderRadius: "100px",
            }}
          >
            {text}
          </LoadingButton>
        )}
      </PDFDownloadLink>
    </Box>
  );
}

export default PdfDownloader;
