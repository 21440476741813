import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  isFetchingSomethingAction,
  updateDriverStatusAction,
} from "../../sliceReducers/utilsSlice";
import { useGetSingleBiltyQuery } from "../../services/userService";
import useDidMountEffect from "../../utils/useDidMountEffect";
import DocumentPending from "./DocumentPending";
import SingleBilty from "./SingleBilty";
import ManageTrackBiltiesCard from "./ManageTrackBiltiesCard";
import Loader from "../../utils/Loader";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

/******************    DIALOG BOX TRANSITION FROM BOTTOM *******************/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageTrackBilties = ({
  statusColor,
  myRequest,
  manageTrackBiltiesOpen,
  setManageTrackBiltiesOpen,
}) => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.userData);
  const { isFetchingSomething, driverStatusBiltyNo } = useSelector(
    (state) => state.utils
  );

  const [singleBiltyNum, setSingleBiltyNum] = useState("");
  const [skipSingleBilty, setskipSingleBilty] = useState(true);

  const [documentsPendingOpen, setDocumentsPendingOpen] = useState(false);

  const [alreadyPresentDocument, setAlreadyPresentDocument] = useState([]);

  const [singleBiltyOpen, setSingleBiltyOpen] = useState(false);
  const [singleBilty, setSingleBilty] = useState();

  const [pendingDocumentsNumber, setPendingDocumentsNumber] = useState();

  useEffect(() => {
    if (myRequest.documents) {
      if (5 - myRequest?.documents?.length !== 0) {
        setPendingDocumentsNumber(
          `${5 - myRequest?.documents?.length} Documents pending`
        );
      } else {
        setPendingDocumentsNumber(`All document submitted`);
      }
    } else {
      setPendingDocumentsNumber(`5 Documents pending`);
    }
  }, []);

  useEffect(() => {
    if (driverStatusBiltyNo) {
      setSingleBiltyNum(driverStatusBiltyNo);
      setskipSingleBilty(false);
    }
  }, [driverStatusBiltyNo]);

  // /******************    SINGLE BILTY API *******************/
  const singleBiltyQuery = {
    token,
    biltyNo: singleBiltyNum,
  };

  const {
    data: singleBiltyResponse,
    error: singleBiltyError,
    isFetching: singleBiltyFetching,
    refetch: singleBiltyRefetch,
  } = useGetSingleBiltyQuery(singleBiltyQuery, {
    skip: skipSingleBilty,
  });

  useDidMountEffect(() => {
    if (
      singleBiltyFetching === false &&
      singleBiltyResponse &&
      !singleBiltyError
    ) {
      if (singleBiltyResponse.status === true) {
        setSingleBilty(singleBiltyResponse.data);
        setSingleBiltyOpen(true);
        setskipSingleBilty(true);
        dispatch(isFetchingSomethingAction(false));
        dispatch(updateDriverStatusAction({ orderNo: "", biltyNo: "" }));
      }
    }

    if (singleBiltyFetching === false && singleBiltyError) {
      setskipSingleBilty(true);
      dispatch(isFetchingSomethingAction(false));
    }
  }, [singleBiltyFetching]);

  useDidMountEffect(() => {
    let temp = [];
    if (singleBilty && singleBilty.documents) {
      for (let i = 0; i < singleBilty.documents.length; i++) {
        let name = singleBilty.documents[i].name.substring(
          singleBilty.documents[i].name.lastIndexOf("/") + 1
        );
        if (name.includes("bill_of_landing")) {
          temp[0] = { value: true, url: singleBilty.documents[i].url };
        }

        if (name.includes("invoice")) {
          temp[1] = { value: true, url: singleBilty.documents[i].url };
        }

        if (name.includes("gd")) {
          temp[2] = { value: true, url: singleBilty.documents[i].url };
        }

        if (name.includes("demand_letter")) {
          temp[3] = { value: true, url: singleBilty.documents[i].url };
        }

        if (name.includes("packaging_list")) {
          temp[4] = { value: true, url: singleBilty.documents[i].url };
        }
      }
    }
    setAlreadyPresentDocument(temp);
  }, [singleBilty, documentsPendingOpen]);

  return (
    <>
      {isFetchingSomething && singleBiltyOpen && <Loader />}
      <Dialog
        open={manageTrackBiltiesOpen}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onClose={() => setManageTrackBiltiesOpen(false)}
      >
        <AppBar
          elevation={window.innerWidth > 900 ? 2 : 0}
          sx={{
            position: "fixed",
            backgroundColor: statusColor,
            borderBottom: "1px solid var(--borderGrey)",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setManageTrackBiltiesOpen(false)}
              aria-label="close"
            >
              <ArrowBackRoundedIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{
                color: "white",
                textAlign: "center",
              }}
            >
              Order #{myRequest.orderNo} Bilties
            </Typography>
          </Toolbar>
        </AppBar>

        <List sx={{ paddingTop: "60px" }}>
          {myRequest.bilty &&
            myRequest.bilty.map((vehicle, vehicleIndex) => (
              <ManageTrackBiltiesCard
                setSingleBiltyNum={setSingleBiltyNum}
                setskipSingleBilty={setskipSingleBilty}
                vehicle={vehicle}
                setSingleBiltyOpen={setSingleBiltyOpen}
              />
            ))}
        </List>
      </Dialog>

      {/******************  DOCUMENTS PENDING *******************/}
      {documentsPendingOpen && (
        <Dialog
          style={{ zIndex: "1500" }}
          // maxWidth={"xl"}
          open={documentsPendingOpen}
          TransitionComponent={Transition}
          keepMounted
          fullScreen
          onClose={() => setDocumentsPendingOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle
            style={{
              backgroundColor: statusColor,
              color: "white",
              textAlign: "center",
            }}
          >
            Bilty #{singleBilty.biltyNo}
          </DialogTitle> */}

          <AppBar
            sx={{ position: "relative", backgroundColor: "var(--primary)" }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setDocumentsPendingOpen(false)}
                aria-label="close"
              >
                <ArrowBackRoundedIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                Documents Pending
              </Typography>

              {/* <Button
                  autoFocus
                  color="inherit"
                  onClick={() => setDocumentsPendingOpen(false)}
                >
                  save
                </Button> */}
            </Toolbar>
          </AppBar>

          <DialogContent>
            <DocumentPending
              alreadyPresentDocument={alreadyPresentDocument}
              singleBilty={singleBilty}
              singleBiltyRefetch={singleBiltyRefetch}
              setskipSingleBilty={setskipSingleBilty}
              setDocumentsPendingOpen={setDocumentsPendingOpen}
            />
          </DialogContent>
        </Dialog>
      )}

      {/******************  SINGLE BILTY *******************/}
      {singleBilty && singleBiltyOpen && (
        <SingleBilty
          singleBiltyOpen={singleBiltyOpen}
          setSingleBiltyOpen={setSingleBiltyOpen}
          statusColor={statusColor}
          singleBilty={singleBilty}
          pendingDocumentsNumber={pendingDocumentsNumber}
          setDocumentsPendingOpen={setDocumentsPendingOpen}
          setskipSingleBilty={setskipSingleBilty}
        />
      )}
    </>
  );
};

export default ManageTrackBilties;
